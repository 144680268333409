import * as user from '../actions/user'

const INITIAL_STATE = {
  mobile: '',
}
const removeNumber = state => {
  if (state.mobile.length > 0) {
    return { ...state, mobile: state.mobile.substring(0, state.mobile.length - 1) }
  }
  return state
}

const addAllTheNumbers = (state, rawNumber) => {
  if (rawNumber.length < 11) {
    return { ...state, mobile: rawNumber }
  }
  return { ...state, mobile: rawNumber.substring(rawNumber.length - 10) }
}
const addNumber = (state, number) => {
  const rawNumber = state.mobile + number
  return addAllTheNumbers(state, rawNumber)
}
export const UserInfo = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case user.REMOVE_NUMBER_FROM_PHONE :
      return removeNumber(state)
    case user.ADD_ALL_THE_NUMBERS :
      return addAllTheNumbers(state, payload)
    case user.ADD_NUMBER_TO_PHONE :
      return addNumber(state, payload)
    case user.EMAIL_ADDRESS :
      return { ...state, email: payload }
    case user.FIRST_NAME :
      return { ...state, firstName: payload }
    case user.LAST_NAME :
      return { ...state, lastName: payload }
    case user.CLEAR_USER_DATA:
      return INITIAL_STATE
    default:
      return state
  }
}
