import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import FlatButton from 'material-ui/FlatButton'
import { Padding } from '../../styles/style.format'
import { FLIGHT_SEARCH_OPTIONS } from '../../actions/flight.status'

class FlightSearchOptions extends Component {

  render() {
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'space-around', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '50%', justifyContent: 'space-around', alignItems: 'center', minWidth: '300px' }}>
          <img src={`${process.env.PUBLIC_URL}/img/icons/neutral/Search.svg`} role="presentation" height="140px" />
          <div style={{ ...this.props.styles.FlightSearchOptionsText }}>
            Search Flights Today
          </div>
          < FlatButton
            style={{ ...this.props.styles.PrimaryButton, paddingLeft: Padding.Small, paddingRight: Padding.Small }}
            label={'Select'}
            primary={true}
            onClick={() => {
              this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.MANUAL_INPUT)
            }}
          />
        </div>
        <div style={{ width: '1px', backgroundColor: this.props.styles.Color.Neutral, height: '70%' }} />
        <div style={{ display: 'flex', flexDirection: 'column', height: '50%', justifyContent: 'space-around', alignItems: 'center', minWidth: '300px' }}>
          <img src={`${process.env.PUBLIC_URL}/img/icons/neutral/Ticket.svg`} role="presentation" height="140px" />
          <div style={{ ...this.props.styles.FlightSearchOptionsText }}>
            Scan Ticket
          </div>
          <FlatButton
            style={{ ...this.props.styles.PrimaryButton, paddingLeft: Padding.Small, paddingRight: Padding.Small }}
            label={'Select'}
            primary={true}
            onClick={() => {
              this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.TICKET_SCAN)
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(FlightSearchOptions)
