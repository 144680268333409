import * as GoogleAnalytics from './google.analytics'

export const SET_SELECTED_MENU_ITEM = 'SET_SELECTED_MENU_ITEM'

export const setSelectedMenuItem = name => ({ type: SET_SELECTED_MENU_ITEM, payload: name })
export const setSelectedMenuItemByName = name => {
  return dispatch => {
    dispatch(setSelectedMenuItem(name))
    dispatch(GoogleAnalytics.logPageView(`/${name}`))
  }
}
