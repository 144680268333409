import { get } from '../util/services'

export const LOAD_JABBRRBOX_PRICING_PLANS_STARTED = 'LOAD_JABBRRBOX_PRICING_PLANS_STARTED'
export const LOAD_JABBRRBOX_PRICING_PLANS_STOPPED = 'LOAD_JABBRRBOX_PRICING_PLANS_STOPPED'
export const LOAD_JABBRRBOC_PRICING_PLANS_ERROR = 'LOAD_JABBRRBOX_PRICING_PLANS_ERROR'

export const loadJabbrrboxPricingPlansStarted = () => ({ type: LOAD_JABBRRBOX_PRICING_PLANS_STARTED })
export const loadJabbrrboxPricingPlansStopped = plans => ({ type: LOAD_JABBRRBOX_PRICING_PLANS_STOPPED, payload: plans })
export const loadJabbrrboxPricingPlansError = error => ({ type: LOAD_JABBRRBOC_PRICING_PLANS_ERROR, payload: error })
export const loadJabbrrboxPricingPlans = () => {
  return (dispatch, getState) => {
    dispatch(loadJabbrrboxPricingPlansStarted())
    return get(`/jabbrrboxPricingPlans?filter[include][pricingPlan]&filter[where][jabbrrboxId]=${getState().configState.conf.jabbrrbox.id}`, getState())
      .then(data => {
        dispatch(loadJabbrrboxPricingPlansStopped(data))
      })
      .catch(error => {
        dispatch(loadJabbrrboxPricingPlansError(error))
      })
  }
}
