import React from 'react'
import { Padding, ContainerStyle } from '../../styles/style.format'
import FlatButton from 'material-ui/FlatButton'
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right'
import moment from 'moment'

const getSponsor = (sponsor, styles) => {
  if (sponsor) {
    let sponsorView = <div style={{ ...styles.CallToActionBody, color: styles.Color.White }}>{sponsor.name}</div>
    if (sponsor.logoUrl) {
      sponsorView = <div><img src={ sponsor.logoUrl } width="200" height="200" alt="sponsor" /></div>
    }
    return (<div style={{ marginLeft: Padding.XXSmall }}>
      <div style={{ ...styles.SponsoredBy, color: styles.Color.White }}>Sponsored By</div>
      {sponsorView}
    </div>)
  }
  return ''
}
const topStatement = (styles, sponsor, jabbrrboxPricingPlan, locationPricingPlan) => {
  const hasPricingPlan = (jabbrrboxPricingPlan.plans.length > 0 || locationPricingPlan.plans.length > 0)
  if (hasPricingPlan) {
    return (
      <div>
        <div style={{ ...styles.CallToActionTitle, color: styles.Color.White }}>Your private  office, whenever you need it</div>
        <div style={{
          ...styles.CallToActionBody,
          color: styles.Color.White,
          marginBottom: Padding.Medium,
          paddingRight: Padding.XLarge,
        }}>Book now to start and enjoy your Jabbrrbox experience</div>
      </div>)
  } else if (sponsor) {
    return (
      <div>
        <div style={{ ...styles.CallToActionTitle, color: styles.Color.White }}>First 30 Minutes Free, Try it Now!</div>
        <div style={{
          ...styles.CallToActionBody,
          color: styles.Color.White,
          marginBottom: Padding.Medium,
          paddingRight: Padding.XLarge,
        }}>
          Book now to start and experience the first 30 minutes courtesy of {sponsor.name}.
        </div>
      </div>)
  } else {
    return (
      <div>
        <div style={{ ...styles.CallToActionTitle, color: styles.Color.White }}>Your private  office, whenever you need it</div>
        <div style={{
          ...styles.CallToActionBody,
          color: styles.Color.White,
          marginBottom: Padding.XXSmall,
        }}>Book now to start and enjoy your Jabbrrbox experience</div>
      </div>)
  }

}
const topStatementWhenReserved = (styles, componentStyles, sponsor, jabbrrboxPricingPlan, locationPricingPlan) => {
  const hasPricingPlan = (jabbrrboxPricingPlan.plans.length > 0 || locationPricingPlan.plans.length > 0)
  if (hasPricingPlan) {
    return (
      <div style={{ ...ContainerStyle, ...styles.CallToActionSponsorContainer }}>
        <div>
          <div style={styles.CallToActionSubtitle}>Your private  office, whenever you need it</div>
          <div style={styles.CallToActionBody}>Book now to start and enjoy your Jabbrrbox experience</div>
        </div>
      </div>
    )
  } else if (sponsor) {
    let sponsorView = <div style={{ ...styles.CallToActionBody, color: styles.Color.NeutralLight }}>{sponsor.name}</div>
    if (sponsor.logoUrl) {
      sponsorView = <div><img src={ sponsor.logoUrl } width="200" height="200" alt="sponsor" /></div>
    }
    return (
      <div style={{ ...ContainerStyle, ...styles.CallToActionSponsorContainer }}>
        <div>
          <div style={styles.CallToActionSubtitle}>30 minutes free provided by {sponsor.name}.</div>
          <div style={styles.CallToActionBody}>Reserve now at any available Jabbrrbox.</div>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
          <div style={componentStyles.sponsorDivider} />
          <div>
            <div style={{ ...styles.SponsoredBy, color: styles.Color.NeutralLight }}>Sponsored By</div>
            {sponsorView}
          </div>
        </div>
      </div>
    )
  } else {
    return ''
  }
}

const handleBookNow = (navigate, jabbrrboxPricingPlan, locationPricingPlan) => {
  if (jabbrrboxPricingPlan.plans.length > 0 || locationPricingPlan.plans.length > 0) {
    navigate('PricingOptions')
  } else {
    navigate('PageOne')
  }
}

const CallToActionComponent = ({ styles, navigate, reservation, sponsor, jabbrrboxPricingPlan, locationPricingPlan }) => {
  const componentStyles = {
    sponsorDivider: {
      ...styles.CountdownVerticalDivider,
      borderColor: styles.Color.NeutralLight,
      height: 55,
      marginRight: 20,
    },
  }
  const jabbrrboxImageUrl = `${process.env.PUBLIC_URL}/img/jabbrrbox_furniture.png`
  const accessControlIcon = `${process.env.PUBLIC_URL}/img/icons/neutral-dark/access_control.svg`
  if (reservation) {
    const nextReservationTime = reservation.datetimeEnd ? moment(reservation.datetimeEnd).format('h:mmA') : ''
    return (
      <div style={styles.CallToActionContainer}
        onClick={() => navigate('EnterPin', { mode: 'unlock' })}>
        <div style={{ ...styles.CallToActionReservationContainer }}>
          <div style={{ ...ContainerStyle, display: 'flex', flex: 1, alignItems: 'center' }}>
            <img src={accessControlIcon} role={'presentation'}
              style={{ width: 36, height: 'auto', alignSelf: 'end', color: styles.Color.NeutralDark, marginLeft: -8, marginRight: 5 }} />
            <div style={{ ...styles.WhenToUseSubtitle, color: styles.Color.NeutralDark }}>JABBRRBOX IN USE UNTIL {nextReservationTime}</div>
          </div>

          <FlatButton
            style={{ ...styles.LinkWithIconButton, color: styles.Color.NeutralDark }}
            label={'Click To Unlock'}
            labelPosition="before"
            primary={true}
            icon={<ChevronRight />}
            onClick={() => {
              navigate('EnterPin', { mode: 'unlock' })
            }}
          />
        </div>
        {topStatementWhenReserved(styles, componentStyles, sponsor, jabbrrboxPricingPlan, locationPricingPlan)}
      </div>
    )
  } else {
    return (
      <div style={styles.CallToActionContainer}>
        <div style={{ ...styles.CallToActionInnerContainer, paddingLeft: Padding.Medium, paddingRight: Padding.Medium }}>
          <div style={{ marginLeft: Padding.XSmall, marginRight: Padding.XSmall }}>
            <img src={jabbrrboxImageUrl} style={{ width: 150, height: 'auto' }} alt="Jabbrrbox" />
          </div>
          <div style={{ marginTop: Padding.Medium }}>
            {topStatement(styles, sponsor, jabbrrboxPricingPlan, locationPricingPlan)}
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
              <div>
                <FlatButton
                  style={{ ...styles.PrimaryButton, color: styles.Color.NeutralDark }}
                  label={'BOOK NOW'}
                  labelPosition="before"
                  primary={true}
                  onClick={() => {
                    handleBookNow(navigate, jabbrrboxPricingPlan, locationPricingPlan)
                  }}
                />
              </div>

              {getSponsor(sponsor, styles)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CallToActionComponent
