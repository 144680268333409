import React, { Component } from 'react'
import { Padding } from '../styles/style.format'
import jquery from 'jquery'

const loadStyles = {
  container: {
    height: '80%',
    marginRight: Padding.XXLarge,
    marginTop: Padding.XLarge,
    marginLeft: Padding.XXLarge,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  outsideDiv: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerDiv: {
    width: 900,
    height: 900,
  },
}
class InternalLocationScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      src: props.config.googleMapUrl,
      showingTerms: false,
      showMap: false,
    }
    this.termsAndConditions = this.termsAndConditions.bind(this)
    this.goToMap = this.goToMap.bind(this)
  }
  componentDidMount() {
    const that = this
    jquery('#mapFrame').on('load', () => {
      that.setState({ showMap: true })
    })
  }
  termsAndConditions() {
    this.setState({ showMap: false, src: this.props.config.googleLicenseAgreement, showingTerms: true })
  }
  goToMap() {
    this.setState({ showMap: false, src: this.props.config.googleMapUrl, showingTerms: false })
  }
  render() {

    return (
      <div style={loadStyles.container}>
        {!this.state.showMap && <div style={loadStyles.outsideDiv}>
          <div style={loadStyles.innerDiv}><img alt="Loading" src={`${process.env.PUBLIC_URL}/img/spinner.svg`} /></div>
        </div>
        }
        <div style={{ height: '100%' }}>
          <iframe
            width="100%"
            height="100%"
            id="mapFrame"
            sandbox="allow-scripts allow-top-navigation"
            src={this.state.src} />
        </div>
        {!this.state.showingTerms && <div onClick={this.termsAndConditions} style={{ ...this.props.styles.InternalText,
          width: '100%', marginBottom: Padding.Small, marginTop: Padding.Small }}>Google Map Terms and Conditions</div>}
        {this.state.showingTerms && <div onClick={this.goToMap}
          style={{ ...this.props.styles.InternalText, width: '100%', marginBottom: Padding.Small, marginTop: Padding.Small }}>Return to map</div>}
      </div>

    )
  }
}


export default InternalLocationScreen
