import * as jabbrrboxFeedbackActions from '../actions/jabbrrbox.feedback'

const INITIAL_STATE = {
  isLoading: false,
  feedbackQuestions: undefined,
  error: undefined,
  hasSubmitted: false,
}

export const jabbrrboxFeedback = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case jabbrrboxFeedbackActions.LOAD_FEEDBACK_QUESTIONS_STARTED:
      return { ...state, isLoading: true }
    case jabbrrboxFeedbackActions.LOAD_FEEDBACK_QUESTIONS_STOPPED:
      return { ...state, isLoading: false, feedbackQuestions: payload }
    case jabbrrboxFeedbackActions.LOAD_FEEDBACK_QUESTIONS_ERROR:
      return { ...state, isLoading: false, error: payload }
    case jabbrrboxFeedbackActions.STOP_ADD_JABBRRBOX_FEEDBACK:
      return { ...state, hasSubmitted: true }
    case jabbrrboxFeedbackActions.RESET_ADD_JABBRRBOX_FEEDBACK:
      return { ...state, hasSubmitted: false }
    default:
      return state
  }
}
