import { Padding, Margin } from './padding.margin'

const Color = {
  BrandPrimary: '#FFD938',
  BrandSecondary: '#d14e23',
  Neutral: '#78909C',
  NeutralLight: '#ECEFF1',
  NeutralDark: '#455A64',
  NeutralExtraDark: '#2C363C',
  White: '#FFFFFF',
  Error: '#EE6161',
  Success: '#8FCD4C',
}

const FontWeight = {
  Bold: 'bold',
  SemiBold: '500',
  Light: '200',
}
const LetterSpacing = {
  XXXWide: 24,
  XXWide: 12,
  XWide: 6,
  Wide: 4,
  Medium: 2,
  Normal: 1,
  None: 0,
  Small: 0.25,
  XSmall: -0.5,
  XXSmall: -0.75,
  XXXSmall: -1,
}
const LineHeight = {
  XTallLineHeight: 1.5,
  TallLineHeight: 1.25,
  RegularLineHeight: 1,
  ShortLineHeight: 0.75,
}
const FontSize = {
  XXXXXLFont: 172,
  XXXXLFont: 108,
  XXXLFont: 60,
  XXLFont: 38,
  XLFont: 32,
  LFont: 24,
  MFont: 18,
  SFont: 14,
  XSFont: 14,
  XXSFont: 10,
}

const FontFamily = {
  Bold: 'Poppins-Bold',
  Light: 'Poppins-Light',
  SemiBold: 'Poppins-SemiBold',
  Regular: 'Poppins-Regular',
}
const Theme = {
  Color,
  FontWeight,
  LetterSpacing,
  LineHeight,
  FontSize,
  FontFamily,
}

export const generateStyle = CustomTheme => {
  return {
    ...CustomTheme,
    InfoText: {
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.Small,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    ErrorText: {
      fontSize: CustomTheme.FontSize.XSmall,
      letterSpacing: CustomTheme.LetterSpacing.Small,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.Error,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    InfoTextBold: {
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.Small,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.SemiBold,

    },
    MenuItemSelectedText: {
      fontSize: CustomTheme.FontSize.SFont,
      letterSpacing: CustomTheme.LetterSpacing.Small,
      lineHeight: 0,
      color: CustomTheme.Color.NeutralLight,
      fontFamily: CustomTheme.FontFamily.SemiBold,

    },
    MenuItemText: {
      fontSize: CustomTheme.FontSize.SFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: 0,
      color: CustomTheme.Color.Neutral,
      fontFamily: CustomTheme.FontFamily.Light,

    },
    DetailText: {
      fontSize: CustomTheme.FontSize.MFont,
      letterSpacing: CustomTheme.LetterSpacing.XXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Light,

    },
    FindOutMoreText: {
      fontSize: CustomTheme.FontSize.XXXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.White,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    FlightSearchHeaderText: {
      fontSize: CustomTheme.FontSize.XXXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.White,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    FlightSearchOptionsText: {
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.XSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.White,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    WelcomeSubHeadingText: {
      fontFamily: CustomTheme.FontFamily.SemiBold,
      fontSize: CustomTheme.FontSize.XLFont,
      color: CustomTheme.Color.White,
      letterSpacing: CustomTheme.LetterSpacing.Medium,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
    },
    InternalInfoText: {
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.None,
      lineHeight: CustomTheme.LineHeight.XTallLineHeight,
      color: CustomTheme.Color.White,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    WelcomeScreenButtonText: {
      fontFamily: CustomTheme.FontFamily.SemiBold,
      fontSize: CustomTheme.FontSize.MFont,
      color: CustomTheme.Color.White,
      letterSpacing: CustomTheme.LetterSpacing.Medium,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
    },
    PresetColorPickerText: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.MFont,
      color: CustomTheme.Color.White,
      letterSpacing: CustomTheme.LetterSpacing.Medium,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
    },
    InteralHeaderContainer: {
      height: 120,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    InternalHeader: {
      fontSize: CustomTheme.FontSize.XXXLFont,
      letterSpacing: CustomTheme.LetterSpacing.Wide,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.White,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    InternalSubHeader: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XWide,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.White,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    InternalText: {
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.White,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    InternalMetaTitle: {
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.XWide,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.White,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    DetailTextBold: {
      fontSize: CustomTheme.FontSize.MFont,
      letterSpacing: CustomTheme.LetterSpacing.XXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    H1: {
      fontSize: CustomTheme.FontSize.XXXXXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XXWide,
      lineHeight: CustomTheme.LineHeight.ShortLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    H2: {
      fontSize: CustomTheme.FontSize.XXXXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXSmall,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    H3: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XWide,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    H4: {
      fontSize: CustomTheme.FontSize.XSFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    H5: {
      fontSize: CustomTheme.FontSize.XXSFont,
      letterSpacing: CustomTheme.LetterSpacing.Small,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    AvailabiltyHeader: {
      fontSize: CustomTheme.FontSize.MFont,
      letterSpacing: CustomTheme.LetterSpacing.XWide,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    TimeHeader: {
      fontSize: CustomTheme.FontSize.XXXXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XXWide,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    InputHeader: {
      fontSize: CustomTheme.FontSize.MFont,
      letterSpacing: CustomTheme.LetterSpacing.XWide,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    InputSubHeader: {
      fontSize: CustomTheme.FontSize.MFont,
      letterSpacing: CustomTheme.LetterSpacing.ExtraSmall,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Light,

    },
    InputText: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.None,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,

    },
    InputTextMask: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.None,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralLight,
      fontFamily: CustomTheme.FontFamily.Bold,

    },
    InputTextMobile: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.Wide,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,

    },
    InputTextMobileMask: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.Wide,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralLight,
      fontFamily: CustomTheme.FontFamily.Bold,

    },
    InputTextAccessCode: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXWide,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,
    },
    MottoText: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XSmall,
      lineHeight: CustomTheme.LineHeight.XTallLineHeight,
      color: CustomTheme.Color.Neutral,
      fontFamily: CustomTheme.FontFamily.SemiBold,

    },
    Label: {
      color: CustomTheme.Color.Neutral,
      fontSize: CustomTheme.FontSize.MediumFont,
      fontFamily: CustomTheme.FontFamily.Bold,
    },
    ExtraLargeLabel: { //A1
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,
      fontSize: CustomTheme.FontSize.ExtraLarge5Font,
      fontWeight: CustomTheme.FontWeight.SemiBold,
      letterSpacing: CustomTheme.LetterSpacing.ExtraXWide,
      //lineHeight: CustomTheme.LineHeight.RegularLineHeight,
    },
    LargerLabel: {
      fontWeight: CustomTheme.FontWeight.SemiBold,
      fontSize: CustomTheme.FontSize.ExtraLarge4Font,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,
      letterSpacing: CustomTheme.LetterSpacing.Smaller,
    },
    LargeLabelSpaced: {
      fontWeight: CustomTheme.FontWeight.SemiBold,
      fontSize: CustomTheme.FontSize.ExtraLarge2Font,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,
      letterSpacing: CustomTheme.LetterSpacing.XWide,
    },
    LargeLabel: {
      fontWeight: CustomTheme.FontWeight.SemiBold,
      fontSize: CustomTheme.FontSize.ExtraLarge2Font,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,
      letterSpacing: CustomTheme.LetterSpacing.Smaller,
    },
    MediumLabel: {
      fontWeight: CustomTheme.FontWeight.SemiBold,
      fontSize: CustomTheme.FontSize.MediumFont,
      letterSpacing: CustomTheme.LetterSpacing.XWide,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,
    },
    SmallLabel: {
      fontWeight: CustomTheme.FontWeight.Light,
      fontSize: CustomTheme.FontSize.SmallFont,
      letterSpacing: CustomTheme.LetterSpacing.Small,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    SmallLabelBold: {
      fontWeight: CustomTheme.FontWeight.Bold,
      fontSize: CustomTheme.FontSize.SmallFont,
      letterSpacing: CustomTheme.LetterSpacing.Small,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,
    },
    ExtraSmallLabel: {
      fontWeight: CustomTheme.FontWeight.SemiBold,
      fontSize: CustomTheme.FontSize.ExtraExtraSmallFont,
      letterSpacing: CustomTheme.LetterSpacing.Small,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,
    },
    ExtraSmallLabelBold: {
      fontWeight: CustomTheme.FontWeight.Bold,
      fontSize: CustomTheme.FontSize.ExtraExtraSmallFont,
      letterSpacing: CustomTheme.LetterSpacing.Small,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Bold,
    },
    InputLabel: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.Medium,
      color: CustomTheme.Color.Neutral,
    },
    PrimaryButton: {
      backgroundColor: CustomTheme.Color.BrandPrimary,
      color: CustomTheme.Color.NeutralDark,
      fontSize: CustomTheme.FontSize.LFont,
      fontFamily: CustomTheme.FontFamily.SemiBold,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      height: '48px',
    },
    PrimaryButtonTall: {
      backgroundColor: CustomTheme.Color.BrandPrimary,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.SemiBold,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      height: '110px',
    },
    PrimaryButtonTallLabel: {
      fontSize: CustomTheme.FontSize.XXLFont,
      paddingLeft: Padding.Large,
      paddingRight: Padding.Large,
      textTransform: 'capitalize',
    },
    PrimaryButtonTallDisabled: {
      backgroundColor: CustomTheme.Color.NeutralLight,
      color: CustomTheme.Color.NeutralDark,
      // fontSize: CustomTheme.FontSize.LFont,
      fontFamily: CustomTheme.FontFamily.SemiBold,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      height: '110px',
    },
    LinkButton: {
      letterSpacing: CustomTheme.LetterSpacing.Small,
      color: CustomTheme.Color.NeutralDark,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    LinkButtonLabel: {
      fontSize: CustomTheme.FontSize.LFont,
      textTransform: 'capatalize',
      textDecorationLine: 'underline',
    },
    SecondaryButton: {
      backgroundColor: CustomTheme.Color.Neutral,
      color: CustomTheme.Color.NeutralLight,
      fontSize: CustomTheme.FontSize.LFont,
      fontFamily: CustomTheme.FontFamily.Light,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      height: '48px', //TODO: Padding is also now in here and differently sized? Need to consolidate, setting this staticly for now
    },
    LinkWithIconButton: {
      backgroundColor: 'transparent',
      color: CustomTheme.Color.NeutralLight,
      fontSize: CustomTheme.FontSize.LFont,
      fontFamily: CustomTheme.FontFamily.SemiBold,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
    },
    CallToActionTitle: {
      fontFamily: CustomTheme.FontFamily.SemiBold,
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
    },
    CallToActionBody: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.White,
    },
    SponsoredBy: {
      color: CustomTheme.Color.Neutral,
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.MFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      textTransform: 'uppercase',
    },
    Sponsor: {
      color: CustomTheme.Color.Neutral,
      fontFamily: CustomTheme.FontFamily.SemiBold,
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
    },
    ValueProposition: {
      fontFamily: CustomTheme.FontFamily.SemiBold,
      fontSize: CustomTheme.FontSize.MFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
    },
    WhenToUseTitle: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.MFont,
      color: CustomTheme.Color.NeutralDark,
      textAlign: 'center',
      letterSpacing: CustomTheme.LetterSpacing.XWide,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
    },
    WhenToUseSubtitle: {
      fontFamily: CustomTheme.FontFamily.SemiBold,
      fontSize: CustomTheme.FontSize.MFont,
      color: CustomTheme.Color.Neutral,
      letterSpacing: CustomTheme.LetterSpacing.Medium,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
    },
    WhenToUseBody: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.MFont,
      color: CustomTheme.Color.NeutralDark,
      letterSpacing: CustomTheme.LetterSpacing.XXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      paddingRight: Padding.Medium,
    },
    WhatIsInJabbrrboxIconTitle: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.XSFont,
    },
    SupportFooterContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: Padding.Medium,
      paddingBottom: Padding.Small,
    },
    SupportFooterLead: {
      fontFamily: CustomTheme.FontFamily.SemiBold,
      fontSize: CustomTheme.FontSize.SFont,
      letterSpacing: CustomTheme.LetterSpacing.XXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
    },
    SupportFooter: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.SFont,
      letterSpacing: CustomTheme.LetterSpacing.XXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
    },
    PageTitle: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XWide,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralLight,
      fontFamily: CustomTheme.FontFamily.Light,
      textTransform: 'uppercase',
    },
    InternalWelcomeText: {
      color: CustomTheme.Color.Neutral,
      fontSize: CustomTheme.FontSize.XXXLFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    InternalWelcomeSubText: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    InternalHomeHeaderText: {
      color: CustomTheme.Color.White,
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    InternalHomeSubHeaderText: {
      color: CustomTheme.Color.White,
      fontSize: CustomTheme.FontSize.SFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.RegularLineHeight,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    CountdownContainer: {
      display: 'flex',
      alignItems: 'center',
      height: 120,
      width: 575,
      backgroundColor: CustomTheme.Color.White,
      borderBottomLeftRadius: 3,
      paddingLeft: Padding.Small,
      paddingRight: Padding.Small,
    },
    CountdownInnerContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: '100%',
    },
    CountdownIcon: {
      width: 48,
      height: 'auto',
      backgroundColor: 'transparent',
    },
    CountdownVerticalDivider: {
      borderTopWidth: 0,
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      height: 55,
      borderColor: CustomTheme.Color.NeutralLight,
    },
    CountdownTitle: {
      color: CustomTheme.Color.NeutralDark,
      fontSize: CustomTheme.FontSize.XSFont,
      fontFamily: CustomTheme.FontFamily.Light,
      marginBottom: -10,
      textTransform: 'uppercase',
    },
    CountdownValue: {
      color: CustomTheme.Color.NeutralDark,
      fontSize: 44,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    CountdownLabel: {
      color: CustomTheme.Color.NeutralDark,
      fontSize: CustomTheme.FontSize.XSFont,
      fontFamily: CustomTheme.FontFamily.Light,
      marginLeft: -8,
    },
    RemainingContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      paddingLeft: Padding.Small,
      paddingRight: Padding.Small,
    },
    CurrentTimeContainer: {
      paddingLeft: Padding.Small,
    },
    TellMeMoreContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    CallToActionContainer: {
      overflow: 'hidden',
      backgroundColor: CustomTheme.Color.NeutralDark,
      position: 'fixed',
      top: 0,
      width: '100%',
    },
    CallToActionInnerContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      marginTop: Padding.Medium,
      marginBottom: Padding.Medium,
    },
    PricingOptionsList: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: Padding.Large,
    },
    PricingOptionContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      paddingLeft: Padding.Small,
      paddingRight: Padding.Small,
    },
    PricingOptionDuration: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.XLFont,
    },
    PricingOptionCost: {
      textAlign: 'right',
    },
    FeedbackQuestionTitle: {
      fontSize: CustomTheme.FontSize.XLFont,
      marginBottom: Margin.Small,
    },
    FeedbackButton: {
      backgroundColor: CustomTheme.Color.NeutralDark,
      color: CustomTheme.Color.Neutral,
      fontSize: CustomTheme.FontSize.XLFont,
      fontFamily: CustomTheme.FontFamily.Light,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      height: '80px',
      border: '1px solid',
      borderColor: CustomTheme.Color.Neutral,
    },
    FeedbackActiveButton: {
      backgroundColor: CustomTheme.Color.NeutralLight,
      color: CustomTheme.Color.NeutralDark,
      fontSize: CustomTheme.FontSize.XLFont,
      fontFamily: CustomTheme.FontFamily.Light,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      height: '80px',
    },
    FeedbackSubmitButton: {
      backgroundColor: CustomTheme.Color.BrandPrimary,
      color: CustomTheme.Color.NeutralDark,
      fontSize: CustomTheme.FontSize.XLFont,
      fontFamily: CustomTheme.FontFamily.Light,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      height: '80px',
      paddingLeft: Padding.Large,
      paddingRight: Padding.Large,
    },
    FeedbackSubmitDisabledButton: {
      backgroundColor: CustomTheme.Color.BrandPrimary,
      opacity: '.5',
      color: CustomTheme.Color.NeutralDark,
      fontSize: CustomTheme.FontSize.XLFont,
      fontFamily: CustomTheme.FontFamily.Light,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      height: '80px',
      paddingLeft: Padding.Large,
      paddingRight: Padding.Large,
    },
    FeedbackButtonLabel: {
      fontSize: CustomTheme.FontSize.LFont,
      marginLeft: Margin.XSmall,
      marginRight: Margin.XSmall,
    },
    PricingOptionButton: {
      backgroundColor: CustomTheme.Color.White,
      color: CustomTheme.Color.Neutral,
      fontSize: CustomTheme.FontSize.XXLFont,
      fontFamily: CustomTheme.FontFamily.SemiBold,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '10px',
      height: '96px',
      border: '1px solid',
      borderColor: CustomTheme.Color.Neutral,
      marginBottom: Padding.Large,
    },
    PricingOptionActiveButton: {
      backgroundColor: CustomTheme.Color.Neutral,
      color: CustomTheme.Color.White,
      fontSize: CustomTheme.FontSize.XXLFont,
      fontFamily: CustomTheme.FontFamily.Light,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '10px',
      height: '96px',
      marginBottom: Padding.Large,
    },
    PurchaseButton: {
      backgroundColor: CustomTheme.Color.BrandPrimary,
      color: CustomTheme.Color.NeutralDark,
      fontSize: CustomTheme.FontSize.LFont,
      fontFamily: CustomTheme.FontFamily.SemiBold,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      width: 175,
      height: 75,
    },
    PurchaseDisabledButton: {
      backgroundColor: CustomTheme.Color.NeutralLight,
      color: CustomTheme.Color.NeutralDark,
      fontSize: CustomTheme.FontSize.LFont,
      fontFamily: CustomTheme.FontFamily.SemiBold,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '4px',
      width: 175,
      height: 75,
    },
    CountryOptionButton: {
      borderRadius: '10px',
      border: '1px solid',
      borderColor: CustomTheme.Color.Neutral,
      marginBottom: Padding.Small,
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      backgroundColor: CustomTheme.Color.White,
    },
    CountryOptionButtonLabel: {
      color: CustomTheme.Color.Neutral,
      fontSize: CustomTheme.FontSize.XXLFont,
      fontFamily: CustomTheme.FontFamily.Light,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '10px',
      height: '96px',
    },
    CountryOptionActiveButton: {
      width: '100%',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: CustomTheme.Color.Neutral,
      marginBottom: Padding.Small,
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      backgroundColor: CustomTheme.Color.Neutral,
    },
    CountryOptionActiveButtonLabel: {
      color: CustomTheme.Color.White,
      fontSize: CustomTheme.FontSize.XXLFont,
      fontFamily: CustomTheme.FontFamily.Light,
      letterSpacing: CustomTheme.LetterSpacing.ExtraExtraSmall,
      borderRadius: '10px',
      height: '96px',
    },
    CountryButtonLabel: {
      fontSize: CustomTheme.FontSize.XXLFont,
      marginLeft: Margin.XSmall,
      marginRight: Margin.XSmall,
      alignItems: 'center',
    },
    CallToActionReservationContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-around',
      paddingTop: Padding.XXSmall,
      paddingBottom: Padding.XXSmall,
      backgroundColor: CustomTheme.Color.BrandPrimary,
    },
    CallToActionSponsorContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      marginTop: Padding.XSmall,
      marginBottom: Padding.XSmall,
    },
    CallToActionSubtitle: {
      fontFamily: CustomTheme.FontFamily.SemiBold,
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.White,
      marginBottom: Padding.ExtraSmall,
    },
    PayNowScreenTitle: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.XXXLFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
      textAlign: 'center',
      marginBottom: Padding.Large,
      textTransform: 'uppercase',
    },
    PayNowOptionDivider: {
      borderTopWidth: 0,
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      height: 130,
      marginTop: Margin.XLarge,
      marginLeft: Margin.XXSmall,
      marginRight: Margin.XXSmall,
      borderColor: CustomTheme.Color.Neutral,
    },
    PaymentTypeTitle: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
      marginTop: Padding.Medium,
    },
    PaymentTypesContainer: {
      display: 'flex',
      flex: 1,
      fledDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: Padding.Large,
    },
    PayTypeContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: Padding.Large,
      paddingBottom: Padding.Large,
      paddingLeft: Padding.Small,
      paddingRight: Padding.Small,
    },
    HowToPayDirections: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
      textAlign: 'center',
      marginTop: 100,
      marginBottom: 24,
    },
    PayNowCost: {
      fontFamily: CustomTheme.FontFamily.SemiBold,
      fontSize: CustomTheme.FontSize.XXLFont,
      color: CustomTheme.Color.NeutralDark,
    },
    PayDirections: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.LFont,
      letterSpacing: CustomTheme.LetterSpacing.XXXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.NeutralDark,
      textAlign: 'center',
      marginTop: Padding.Large,
    },
    PricingOptionTitle: {
      fontFamily: CustomTheme.FontFamily.Light,
      fontSize: CustomTheme.FontSize.MFont,
      color: CustomTheme.Color.NeutralDark,
      letterSpacing: CustomTheme.LetterSpacing.XXSmall,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
    },
    FlightStatusInfoText: {
      color: CustomTheme.Color.NeutralLight,
      fontSize: CustomTheme.FontSize.XXLFont,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    FlightStatusOnTimeText: {
      color: CustomTheme.Color.Success,
      fontSize: CustomTheme.FontSize.LFont,
      fontFamily: CustomTheme.FontFamily.Bold,
    },
    FlightStatusLateText: {
      color: CustomTheme.Color.Error,
      fontSize: CustomTheme.FontSize.LFont,
      fontFamily: CustomTheme.FontFamily.Bold,
    },
    FlightStatusLabelText: {
      color: CustomTheme.Color.NeutralLight,
      fontSize: CustomTheme.FontSize.MFont,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    FlightStatusGateCodeText: {
      color: CustomTheme.Color.NeutralLight,
      fontSize: CustomTheme.FontSize.XXXLFont,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    FlightStatusAirportCodeText: {
      fontSize: CustomTheme.FontSize.XXXXLFont,
      color: CustomTheme.Color.White,
      fontFamily: CustomTheme.FontFamily.SemiBold,
    },
    MaintenanceTitle: {
      fontSize: CustomTheme.FontSize.XXLFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.Neutral,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    ComingSoonTitle: {
      fontSize: CustomTheme.FontSize.XXXLFont,
      letterSpacing: CustomTheme.LetterSpacing.Normal,
      lineHeight: CustomTheme.LineHeight.TallLineHeight,
      color: CustomTheme.Color.Neutral,
      fontFamily: CustomTheme.FontFamily.Light,
    },
    ComingSoonBackground: {
      backgroundImage: `url("${process.env.PUBLIC_URL}/img/maintenance/jabbrrbox.png")`,
      width: '100%',
      height: 550,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    DiscretePhoneCircle: {
      width: 12,
      height: 12,
      background: CustomTheme.Color.NeutralDark,
      radius: '50%',
      borderRadius: '50%',
      marginRight: 3,
    },
    PhotoBoothImageTag: `18px ${FontFamily.Light}`,
    Color: CustomTheme.Color,
    FontWeight: CustomTheme.FontWeight,
  }
}

export const overrideStyles = (defaultStyle, overrides) => {
  return { ...defaultStyle, ...overrides }
}
const Style = generateStyle(Theme)

export default Style
