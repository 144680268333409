import * as signUp from '../actions/sign.up'

export const SIGNUP_STATE = {
  firstName: 'firstName',
  lastName: 'lastName',
  mobile: 'mobile',
  email: 'email',
  button: 'button',
  isLoading: false,
  error: undefined,
}

const INITIAL_STATE = {
  loading: false,
  error: null,
  country: {
    name: 'United States',
    code: 'us',
  },
  mobile: '',
  email: '',
}

const moveSignUpForward = (state, section) => {
  if (section === state.section) {
    return state
  }
  return { ...state, section }
}

export const SignUp = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case signUp.SET_SIGNUP_COUNTRY:
      return { ...state, country: payload, mobile: state.country.code === payload.code ? state.mobile : '' }
    case signUp.SET_SIGNUP_PHONE_NUMBER:
      return { ...state, mobile: payload }
    case signUp.SET_SIGNUP_EMAIL:
      return { ...state, email: payload }
    case signUp.START_SIGNUP_REQUEST:
      return { ...state, isLoading: true }
    case signUp.STOP_SIGNUP_REQUEST:
      return { ...state, isLoading: false, registeredPerson: payload }
    case signUp.SIGNUP_REQUEST_ERROR:
      return { ...state, isLoading: false, error: payload }
    case signUp.MOVE_SIGNUP_FORWARD :
      return moveSignUpForward(state, payload)
    case signUp.CLEAR_SIGN_UP :
      return INITIAL_STATE
    case signUp.SHOW_KEYBOARD:
      return { ...state, inputNode: payload }
    default:
      return state
  }
}
