import * as style from '../actions/style'
import DefaultStyle from '../styles'

const INITIAL_STATE = {
  theme: DefaultStyle,
}

export const Style = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case style.UPDATE_THEME_STOPPED :
      return { ...state, theme: payload }
    case style.SET_THEME :
      return { ...state, theme: payload }
    default:
      return state
  }
}
