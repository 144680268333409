import React from 'react'
import Step from './shared/Step'
import { Margin } from '../styles/style.format'

const myStyles = styles => {
  return {
    titleLine: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: Margin.Large,
      marginBottom: Margin.Small,
    },
    straightLine: {
      flexGrow: 2,
      borderBottom: 'solid',
      borderWidth: 'thin',
      alignSelf: 'center',
      marginLeft: Margin.XXSmall,
      borderColor: styles.Color.White,
    },
    hightlighedInfo: {
      color: styles.Color.NeutralLight,
      marginTop: Margin.Small,
    },
  }
}
const WifiInstructions = ({ styles }) => {
  const inStyle = myStyles(styles)
  return (
    <div style={{ width: '100%' }}>
      <div style={inStyle.titleLine }>
        <div style={styles.InternalMetaTitle}>HOW DO I CONNECT?</div>
        <div style={inStyle.straightLine} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyConent: 'center' }}>
        <Step num={1} styles={styles} value={<span>Connect your device to the open <i>Jabbrrbox</i> wifi network.</span>}/>
        <Step num={2} styles={styles} value={'Open a browser, which will take you to our splash page.'}/>
        <Step num={3} styles={styles} noRightBorder={true} value={'Enter your six digit code.'}/>
      </div>
      <div style={ inStyle.hightlighedInfo }>
        Having trouble connecting?
        Make sure to clear your browser cache and/or use an alternative browser
        on your device.
      </div>
    </div>
  )
}
export default WifiInstructions
