import * as page from '../actions/page'

export const pageLoaded = (state = true, { type }) => {
  switch (type) {
    case page.PAGE_LOADING:
      return false
    case page.PAGE_LOADED:
      return true
    default:
      return state
  }
}
