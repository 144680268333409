import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../actions'
import LogoDisplay from '../shared/LogoDisplay'
import { Divider } from 'material-ui'
import { ContainerStyle, Margin } from '../../styles/style.format'

class ComingSoonScreen extends Component {

  render() {

    return (
      <div>
        <div style={ this.props.styles.ComingSoonBackground } />

        <div style={ { ...ContainerStyle, height: '100%', display: 'flex', flexDirection: 'column' } }>
          <div style={ {
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            marginTop: Margin.XLarge, marginBottom: Margin.Large,
          } }>
            <LogoDisplay viewOptions={ { width: 500, height: 'auto' } } options={ { width: 500, height: 'auto' } } />
          </div>
          <div style={ { marginBottom: Margin.Large } }>
            <Divider style={ this.props.styles.ExternalDivider } />
          </div>
          <div style={ { ...this.props.styles.ComingSoonTitle, marginBottom: Margin.Small } }>Coming soon!</div>
          <div style={ this.props.styles.MaintenanceTitle }>Check out our website at jabbrrbox.com for more info!</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    config: state.configState.conf,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoonScreen)
