import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../app/actions'
import PropTypes from 'prop-types'
import FlatButton from 'material-ui/FlatButton'
import LogoDisplay from './shared/LogoDisplay'
import { Padding, ContainerStyle } from '../styles/style.format'

const approvedIconUrl = `${process.env.PUBLIC_URL}/img/icons/payment/accepted.svg`
let navTimer
class PayApprovedScreen extends Component {

  constructor(props) {
    super(props)

    this.handleOpenDoor = this.handleOpenDoor.bind(this)
  }

  componentWillMount() {
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    clearTimeout(navTimer)
  }

  startNavigationTimer() {
    this.stopNavigationTimer()
    navTimer = setTimeout(() => {
      this.props.logEvent('Payment Workflow', 'Timeout', { evLabel: 'PayApprovedScreen', evValue: this.props.config.signUpPageTimeout || 30000 })
      this.props.navigate('RoomReserved')
    }, parseFloat(this.props.config.signUpPageTimeout || 30000))
  }

  stopNavigationTimer() {
    clearTimeout(navTimer)
  }

  handleOpenDoor() {
    this.props.unlockDoor()
    this.props.navigate('DoorOpen')
  }

  render() {

    return (
      <div style={{ ...ContainerStyle, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center',
          marginTop: Padding.Medium, marginBottom: Padding.XLarge,
        }}>
          <LogoDisplay viewOptions={{ width: 210, height: 'auto' }} options={{ width: 210, height: 'auto' }} />
        </div>
        <div style={this.props.styles.PayNowScreenTitle}>
          Approved
        </div>
        <div>
          <img alt="Payment Approved" src={approvedIconUrl} style={{ width: 500, height: 'auto' }} />
        </div>
        <div style={this.props.styles.PayDirections}>You will recieve an access code via SMS shortly.</div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
          <div>
            <FlatButton
              label="CONTINUE"
              style={this.props.styles.PurchaseButton}
              onClick={() => this.handleOpenDoor()}
            />
          </div>
        </div>
      </div>
    )
  }
}

PayApprovedScreen.propTypes = {
  styles: PropTypes.object,
  navigate: PropTypes.func,
  config: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    payment: state.payment,
    reservation: state.doorState.jabbrrboxReservation,
    config: state.configState.conf,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PayApprovedScreen)
