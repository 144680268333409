import React from 'react'
import PropTypes from 'prop-types'
import FlatButton from 'material-ui/FlatButton'
import { getDurationLabel } from '../../util/string.helpers'

const pricingPlanItem = (styles, plan) => {

  const duration = getDurationLabel(plan.duration)
  return (
    <div key={plan.id} style={styles.PricingOptionContainer}>
      <div style={styles.PricingOptionDuration}>{duration}</div>
      <div style={styles.PricingOptionCost}>${plan.cost}</div>
    </div>
  )
}

const PricingPlanListComponent = ({ styles, selectedPriceOption, pricingPlans, onSelectedPriceOption }) => {

  return pricingPlans.sort((a, b) => {
    return a.pricingPlan.cost - b.pricingPlan.cost
  }).map(plan => {
    return (
      <FlatButton
        key={plan.id}
        children={pricingPlanItem(styles, plan.pricingPlan)}
        style={selectedPriceOption === plan ? styles.PricingOptionActiveButton : styles.PricingOptionButton}
        fullWidth={true}
        onClick={() => onSelectedPriceOption(plan)}
      />
    )
  })
}

PricingPlanListComponent.propTypes = {
  styles: PropTypes.object,
  selectedPriceOption: PropTypes.object,
  pricingPlans: PropTypes.array,
  onSelectedPriceOption: PropTypes.func,
}

export default PricingPlanListComponent
