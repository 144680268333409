import React, { Component } from 'react'
import moment from 'moment'
import { Margin } from '../../styles/style.format'

const tentIconUri = `${process.env.PUBLIC_URL}/img/icons/countdown/tent.svg`

class RemainingComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      timeLeft: null,
    }

    this.setInitialCountdown = this.setInitialCountdown.bind(this)
    this.startCountdown = this.startCountdown.bind(this)
    this.getHoursLeft = this.getHoursLeft.bind(this)
    this.getMinutesLeft = this.getMinutesLeft.bind(this)
    this.getSecondsLeft = this.getSecondsLeft.bind(this)
    this.formatTime = this.formatTime.bind(this)
  }

  componentWillMount() {
    this.setInitialCountdown()
  }

  componentDidMount() {
    this.startCountdown()
  }

  setInitialCountdown() {
    const endTime = moment(this.props.reservation.datetimeEnd)
    const now = moment()
    const timeLeft = endTime - now
    const minutesLeft = this.formatTime(this.getMinutesLeft(timeLeft))
    const secondsLeft = this.formatTime(this.getSecondsLeft(timeLeft))
    this.setState({ timeLeft: `${this.getHoursLeft(timeLeft)}:${minutesLeft}:${secondsLeft}` })
  }

  startCountdown() {
    const self = this

    // Set the date we're counting down to
    const endTime = moment(this.props.reservation.datetimeEnd)

    // Update the count down every 1 second
    const x = setInterval(() => {

      // Find the timeLeft between now an the reservation end time
      const now = moment()
      const timeLeft = endTime - now
      const minutesLeft = self.formatTime(self.getMinutesLeft(timeLeft))
      const secondsLeft = self.formatTime(self.getSecondsLeft(timeLeft))
      self.setState({ timeLeft: `${self.getHoursLeft(timeLeft)}:${minutesLeft}:${secondsLeft}` })

      // If the count down is finished, set expired 
      if (timeLeft < 0) {
        clearInterval(x)
        self.setState({ timeLeft: null })
        self.props.navigate('ThankYou')
      }
    }, 1000)
  }

  getHoursLeft(timeLeft) {
    return Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  }

  getMinutesLeft(timeLeft) {
    return Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
  }

  getSecondsLeft(timeLeft) {
    return Math.floor((timeLeft % (1000 * 60)) / 1000)
  }

  formatTime(time) {
    if (time < 10) {
      return `0${time}`
    } else {
      return time
    }
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '50%', direction: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ marginLeft: Margin.Large }}>
          <img src={tentIconUri} style={this.props.styles.CountdownIcon} alt="tent icon" />
        </div>
        <div style={this.props.styles.RemainingContainer}>
          <div style={this.props.styles.CountdownTitle}>REMAINING</div>
          <div style={{ ...this.props.styles.CountdownValue, width: 170 }}>{this.state.timeLeft}</div>
        </div>
      </div>
    )
  }
}

export default RemainingComponent
