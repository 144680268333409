import React, { Component } from 'react'
import { GridList, GridTile } from 'material-ui/GridList'
import { Padding } from '../../styles/style.format'

class Presets extends Component {
  generatePreset(preset) {
    const style = preset.name === this.props.presetSelected ?
      { borderColor: this.props.styles.Color.BrandPrimary } : { borderColor: this.props.styles.Color.NeutralDark }
    return (
      <GridTile
        key={preset.name}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        value={preset.id}
        onClick={() => {
          this.props.onPresetSelected(preset)
        }}>

        <div style={{ width: '55%' }} >
          <img style={{ ...style, width: '100%', borderRadius: '50%', borderStyle: 'solid' }} src={preset.iconUrl} alt={preset.name} />
        </div>
        <div style={{ ...this.props.styles.PresetColorPickerText, color: this.props.styles.Color.NeutralLight, textTransform: 'uppercase',
          paddingTop: Padding.XSmall }}>{preset.name}</div>
      </GridTile>
    )
  }

  generatePresets() {
    if (!this.props.presets || this.props.presets.length === 0) {
      return <div />
    }

    return this.props.presets.map(preset => this.generatePreset(preset))
  }
  render() {
    return (
      <GridList
        cellHeight={210}
        cols={3}
        padding={0}
      >
        {this.generatePresets()}
      </GridList>
    )
  }
}

export default Presets
