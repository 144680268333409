import React, { Component } from 'react'
import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Margin } from '../../styles/style.format'
import { FlatButton } from 'material-ui'


class FeedbackAnswerList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedAnswer: null,
      feedbackSubmitted: false,
    }

    this.handleSelectedAnswer = this.handleSelectedAnswer.bind(this)
    this.getButtonStyle = this.getButtonStyle.bind(this)
    this.getOrderedFeedbackAnswers = this.getOrderedFeedbackAnswers.bind(this)
  }

  handleSelectedAnswer(answer) {
    const foundAnswer = _.find(this.props.selectedAnswers, selectedAnswer => {
      return answer.id === selectedAnswer.id
    })
    if (foundAnswer) {
      this.setState({ selectedAnswer: null })
      this.props.onRemoveAnswer(answer, this.props.question.isMultiSelect)
    } else {
      this.setState({ selectedAnswer: answer })
      this.props.onAddAnswer(answer, this.props.question.isMultiSelect)
    }
  }

  getButtonStyle(answer) {
    const foundAnswer = _.find(this.props.selectedAnswers, selectedAnswer => {
      return answer.id === selectedAnswer.id
    })

    if (foundAnswer) {
      return this.props.styles.FeedbackActiveButton
    } else {
      return this.props.styles.FeedbackButton
    }
  }

  getOrderedFeedbackAnswers() {
    return _.orderBy(this.props.question.feedbackAnswers, ['order'])
  }

  render() {
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        {
          this.getOrderedFeedbackAnswers().map(answer => {
            const buttonStyle = this.getButtonStyle(answer)

            return (
              <div key={answer.id} style={{ marginBottom: Margin.Small }}>
                <FlatButton
                  label={answer.value}
                  disableTouchRipple={true}
                  labelStyle={this.props.styles.FeedbackButtonLabel}
                  style={{ ...buttonStyle, marginRight: Margin.Medium }}
                  onClick={() => this.handleSelectedAnswer(answer)}
                />
                {answer.helperText && <div style={{ marginTop: Margin.XSmall }}>{answer.helperText}</div>}
              </div>
            )
          })
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackAnswerList)
