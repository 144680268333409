import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import eventlogMiddleware from './eventlog.middleware'
import { createLogger } from 'redux-logger'
import reducers from './app/reducers'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
// import AppWithNavigationState from './app/navigators/AppNavigator'
import App from './App'
import ErrorBoundary from './app/components/ErrorBoundary'
import 'es6-string-polyfills'
import ReactDOM from 'react-dom'

//TODO: Where is __DEV__ defined?
const loggerMiddleware = createLogger({ predicate: (getState, action) => "development" })// eslint-disable-line
const configureStore = initialState => {

  const middlewares = [thunkMiddleware, eventlogMiddleware]
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(loggerMiddleware)
  }

  const middleware = applyMiddleware(
    ...middlewares
  )

  let enhancer = compose(
    middleware
  )
  if (window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined) { //Redux DevTools not installed
    enhancer = compose(
      middleware,
      window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  }
  return createStore(reducers, initialState, enhancer)
}
const store = configureStore({})
ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </MuiThemeProvider>
  </Provider>, document.getElementById('root')
)
