import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../app/actions'
import PropTypes from 'prop-types'
import { Divider } from 'material-ui'
import LogoDisplay from '../shared/LogoDisplay'
import { Margin, ContainerStyle } from '../../styles/style.format'

const outOfOrderUrl = `${process.env.PUBLIC_URL}/img/maintenance/out_of_order.svg`

class OutOfOrderScreen extends Component {

  render() {

    return (
      <div style={ { ...ContainerStyle, height: '100%', display: 'flex', flexDirection: 'column', marginTop: Margin.XXXLarge } }>
        <div>
          <img alt="Out of Order" src={ outOfOrderUrl } style={ { width: 500, height: 'auto' } } />
        </div>
        <div style={ {
          display: 'flex', justifyContent: 'center', alignItems: 'center',
          marginTop: Margin.XLarge, marginBottom: Margin.Large,
        } }>
          <LogoDisplay viewOptions={ { width: 500, height: 'auto' } } options={ { width: 500, height: 'auto' } } />
        </div>
        <div style={ { marginBottom: Margin.Large } }>
          <Divider style={ this.props.styles.ExternalDivider } />
        </div>
        <div style={ { ...this.props.styles.MaintenanceTitle, marginBottom: Margin.Small } }>Out of Order</div>
        <div style={ this.props.styles.MaintenanceTitle }>We are working to fix this as quickly as possible. Check back soon!</div>
      </div>
    )
  }
}

OutOfOrderScreen.propTypes = {
  styles: PropTypes.object,
  config: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    config: state.configState.conf,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OutOfOrderScreen)
