import * as locationPricingPlanActions from '../actions/location.pricing.plan'

const INITIAL_STATE = {
  isLoading: false,
  plans: [],
  error: undefined
}

export const locationPricingPlan = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case locationPricingPlanActions.LOAD_LOCATION_PRICING_PLANS_STARTED:
      return { ...state, isLoading: true} 
    case locationPricingPlanActions.LOAD_LOCATION_PRICING_PLANS_STOPPED:
      return { ...state, isLoading: false, plans: payload }
    case locationPricingPlanActions.LOAD_LOCATION_PRICING_PLANS_ERROR:
      return { ...state, isLoading: false, error: payload }
    default:
      return state;
  }
}