import Style from './'
import hexToRgba from 'hex-rgba'
import * as PaddMargin from './padding.margin'

export const FOCUSED = 'FOCUSED'
export const VALID = 'VALID'
export const VALID_FOCUSED = 'VALID_FOCUSED'
export const INVALID = 'INVALID'
export const INVALID_FOCUSED = 'INVALID_FOCUSED'

export const Padding = PaddMargin.Padding
export const Margin = PaddMargin.Margin

export const ContainerStyle = {
  marginLeft: Padding.XLarge,
  marginRight: Padding.XLarge,
}

export const SignUpSection = {
  container: ContainerStyle,
}
export const InputSection = {
  alignItems: 'center',
  paddingBottom: Padding.XXLarge,
  width: '100%',
}
export const TextSection = {
  width: '100%',
  padding: 20,
}
export const InputTextBorder = {
  borderStyle: 'solid',
  borderRadius: '10px',
  borderWidth: 2,
  borderColor: Style.Color.NeutralLight,
  shadowColor: Style.Color.NeutralLight,
  shadowOffset: {
    width: 5,
    height: 5,
  },
  shadowRadius: 5,
  shadowOpacity: 0.5,
}
export const getTextInputBorder = type => {
  if (type.focused && type.valid && type.useColors) {
    return { ...InputTextBorder, borderColor: hexToRgba(type.styles.Color.Success, 50), shadowColor: hexToRgba(type.styles.Color.Success, 25) }
  }
  if (type.valid && type.useColors) {
    return { ...InputTextBorder, borderColor: type.styles.Color.Success, shadowColor: type.styles.Color.Success }
  }
  if (type.focused && type.focusedOnce && (type.useColors || type.email)) {
    return { ...InputTextBorder, borderColor: hexToRgba(type.styles.Color.Error, 50), shadowColor: hexToRgba(type.styles.Color.Error, 25) }
  }
  if (type.focusedOnce && (type.useColors || type.email)) {
    return { ...InputTextBorder, borderColor: type.styles.Color.Error, shadowColor: type.styles.Color.Error }
  }
  if (type.focused) {
    return { ...InputTextBorder, borderColor: type.styles.Color.BrandPrimary, shadowColor: type.styles.Color.BrandPrimary }
  }
  return InputTextBorder

}

export const PageSpinner = {
  width: '25%',
  margin: '30% 40%',
}
