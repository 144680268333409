export const PAGE_LOADING = 'PAGE_LOADING'
export const PAGE_LOADED = 'PAGE_LOADED'

export const pageLoadStart = () => {
  return { type: PAGE_LOADING }
}

export const pageLoadStop = () => {
  return { type: PAGE_LOADED }
}
