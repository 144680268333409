import React from 'react'

const InternalStyle = {
  textContainer: {
    paddingTop: 24,
    flexDirection: 'column',
    alignItems: 'center',
  },
}

const BottomComponent = ({ styles }) => {
  return (
    <div style={InternalStyle.textContainer}>
      <div style={{ ...styles.DetailText, textAlign: 'center' }}>
        <div>{`Have not received your code? Contact\n`}</div>
        <div style={InternalStyle.DetailTextBold}>{`support@jabbrbox.com`}</div>
        <div>{` for assistance.`}</div>
      </div>
    </div>

  )
}

export default BottomComponent
