import React from 'react'
import PropTypes from 'prop-types'
import FlatButton from 'material-ui/FlatButton'

const partnerItem = (styles, partner) => {
  return (
    <div key={partner.id}
      style={{ ...styles.PricingOptionContainer, justifyContent: 'center' }}>
      {partner.name}
    </div>
  )
}

const partnerWithName = (styles, partner, onSelectedPartnerOption) => {
  return (
    <FlatButton
      key={partner.id}
      children={partnerItem(styles, partner)}
      style={{ ...styles.PricingOptionButton, minWidth: '30%' }}
      fullWidth={true}
      onClick={() => onSelectedPartnerOption(partner)}
    />
  )
}

const partnerWithLogo = (styles, partner, onSelectedPartnerOption) => {
  return (
    <a href="#">
      <img src={ partner.logoUrl }
        onClick={() => onSelectedPartnerOption(partner)}
        width="200" height="200" alt="partner" />
    </a>
  )
}

const PartnersList = ({ styles, partners, onSelectedPartnerOption }) => {
  return partners.map(partner => {
    return (
      <div style={{ marginTop: '12px' }}>
        { partner.logoUrl && partnerWithLogo(styles, partner, onSelectedPartnerOption) }
        { !(partner.logoUrl) && partnerWithName(styles, partner, onSelectedPartnerOption) }
      </div>
    )
  })
}

PartnersList.propTypes = {
  styles: PropTypes.object,
  partners: PropTypes.array,
  onSelectedPartnerOption: PropTypes.func,
}

export default PartnersList
