import React from 'react'
import WifiInstructions from './WifiInstructions'
import { Margin } from '../styles/style.format'

const generateLetterCard = (letter, key, styles, inlineStyle) => {
  return <div key={key} style={{
    ...styles.H1,
    display: 'flex',
    background: `linear-gradient(#FFFFFF, #ECEFF1)`,
    width: 200,
    height: 250,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    ...inlineStyle,
  }}>
    <div>
      {letter}
    </div>
  </div>
}

const getAccessCode = (reservation, styles) => {
  if (reservation) {
    const letters = []
    reservation.accessCode.split('').forEach((letter, i) => {
      letters.push(generateLetterCard(letter, i, styles, { marginRight: i === 6 ? 0 : Margin.Medium }))
    })
    return (
      <div style={{ display: 'flex', marginTop: Margin.Large }}>
        {letters}
      </div>
    )
  }
  return (
    <div style={{ ...styles.InternalSubHeader, color: styles.Color.BrandPrimary, marginTop: Margin.Medium }}>
      Reservation is required</div>
  )
}

const InternalWifiScreen = ({ reservation, styles }) => {
  return (
    <div style={{
      marginLeft: Margin.XLarge,
      marginBottom: Margin.Large,
      marginRight: Margin.XLarge,
      marginTop: Margin.XLarge,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '65%',
    }}>
      <div>
        <div style={{ ...styles.InternalHeader }}>Your 6 digit access code.</div>
        {getAccessCode(reservation, styles)}
      </div>
      <div>
        <WifiInstructions styles={styles}/>
      </div>
    </div>
  )
}
export default InternalWifiScreen
