import React, { Component } from 'react'
import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import addEventListener from 'rc-util/lib/Dom/addEventListener'
import Presets from './presets'

const converter = require('color-convert')

const STEPS = 360

const COLOR_PICKER_SIZE_RAW = 1020
const COLOR_PICKER_RING_SIZE_RAW = 180
const COLOR_PICKER_DIMENSIONS = 700
const COLOR_PICKER_DIMENSIONS_HALF = COLOR_PICKER_DIMENSIONS / 2
const COLOR_PICKER_SCALAR = COLOR_PICKER_SIZE_RAW / COLOR_PICKER_DIMENSIONS
const COLOR_PICKER_RING_SIZE = COLOR_PICKER_RING_SIZE_RAW / COLOR_PICKER_SCALAR
const COLOR_PICKER_WHITE_START = COLOR_PICKER_DIMENSIONS_HALF - COLOR_PICKER_RING_SIZE

const SVG_WIDTH = 36
const SVG_HEIGHT = 36
const SVG_TOP_BUFFER = -SVG_HEIGHT / 2
const SVG_LEFT_BUFFER = -SVG_WIDTH / 2

class ColorPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectable: true,
      brightness: 1,
      r: 0,
      g: 0,
      b: 0,
      colorX: -SVG_WIDTH - (COLOR_PICKER_DIMENSIONS_HALF + SVG_LEFT_BUFFER),
      colorY: SVG_TOP_BUFFER,
      brightnessY: SVG_TOP_BUFFER,
    }

    this.colorMap = this.createColorMap(STEPS)
    this.onColorPickerTouchStart = this.onColorPickerTouchStart.bind(this)
    this.onPresetSelected = this.onPresetSelected.bind(this)
    this.onBrightnessTouchStart = this.onBrightnessTouchStart.bind(this)

    this.onTouchMove = this.onTouchMove.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.onBrightnessTouchMove = this.onBrightnessTouchMove.bind(this)
    this.handleBrightnessMouseMove = this.handleBrightnessMouseMove.bind(this)
    this.onEnd = this.onEnd.bind(this)
  }

  componentDidMount() {
    this.updateCanvas()
    if (this.props.conf.lights.internal.id) {
      const internalLight = this.props.lights[this.props.conf.lights.internal.id]
      if (internalLight) {
        setTimeout(() => {
          this.setColorFromPreset({
            ...internalLight.color || { r: 255, g: 255, b: 255 },
            brightness: internalLight.color.brightness * 100 || 100,
          })
        }, 50)
      }
    }
  }

  getHSLValue(degrees) {
    const h = degrees
    const s = 100
    const l = 50
    return { h, s, l }
  }

  createColorMap(len) {
    const m = {}
    for (let i = 0; i < len; ++i) {
      const hsl = this.getHSLValue((i + 180) % 360)
      m[i] = converter.hsl.hex(hsl.h, hsl.s, hsl.l)
      m[m[i]] = i
    }
    return m
  }

  startSampleTimer() {
    this.setState({
      selectable: false,
    })

    setTimeout(() => {
      this.setState({
        selectable: true,
      })
      if (this.props && this.props.onColorSelected) {
        this.props.onColorSelected({ r: this.state.r, g: this.state.g, b: this.state.b, brightness: this.state.brightness })
      }
    }, parseFloat(this.props.sampleTime) || 250)
  }

  updateCanvas() {
    const ctx = this.refs.canvas.getContext('2d')

    const image = new Image()
    image.onload = () => {
      ctx.drawImage(image, 0, 0, COLOR_PICKER_DIMENSIONS, COLOR_PICKER_DIMENSIONS) // draw the image on the canvas
    }
    const imageSrc = require('./colorwheel.svg')
    image.src = imageSrc
  }

  handleBrightnessMouseMove(event) {
    const y = Math.floor(event.pageY - this.refs.brightnessSlider.offsetTop)
    const brightness = Math.max(0, Math.min(1, 1 - (y / event.target.clientHeight)))
    const buffer = event.target.clientHeight / 2
    this.setState({
      brightness,
      brightnessY: -(brightness * event.target.clientHeight - buffer),
      presetSelected: undefined,
    })

    if (!this.state.selectable) {
      return
    }

    this.startSampleTimer()
  }

  handleMouseMove(event) {
    const ctx = this.refs.canvas.getContext('2d')
    const canvasX = Math.floor(event.pageX - this.refs.canvas.offsetLeft)
    const canvasY = Math.floor(event.pageY - this.refs.canvas.offsetTop)
    const imageData = ctx.getImageData(canvasX, canvasY, 1, 1)
    const pixel = imageData.data

    if (pixel[0] !== 0 && pixel[1] !== 0 && pixel[2] !== 0) {
      this.getRgbFromMouseEvent(event)

      this.setState({
        colorX: (event.pageX - this.refs.canvas.offsetLeft) - COLOR_PICKER_DIMENSIONS + SVG_LEFT_BUFFER,
        colorY: (event.pageY - this.refs.canvas.offsetTop) + SVG_TOP_BUFFER,
        presetSelected: undefined,
      })

      if (!this.state.selectable) {
        return
      }

      this.startSampleTimer()
    }
  }

  getRgbFromMouseEvent(event) {
    const canvasX = Math.floor(event.pageX - this.refs.canvas.offsetLeft)
    const canvasY = Math.floor(event.pageY - this.refs.canvas.offsetTop)

    //get the normalized X and Y values
    const normalX = (canvasX - COLOR_PICKER_DIMENSIONS_HALF) / COLOR_PICKER_DIMENSIONS_HALF
    const normalY = (canvasY - COLOR_PICKER_DIMENSIONS_HALF) / COLOR_PICKER_DIMENSIONS_HALF

    const angleInRadians = Math.atan2(0, 0) - Math.atan2(-normalY, normalX)
    const truncd = ((Math.floor(angleInRadians * (180 / Math.PI))) + 360) % 360

    let hex = 'FFFFFF'
    let rgb = [255, 255, 255]

    const lightness = this.getLightnessFromMouseEvent(event)
    if (lightness > 0) {
      const hsl = this.getHSLValue(truncd)
      hsl.l = Math.max(50, Math.min(100, 50 + 100 * ((1 - lightness) / 2)))

      hex = this.colorMap[truncd] || 'FFFFFF'
      rgb = converter.hsl.rgb(hsl.h, hsl.s, hsl.l)
      rgb[0] = Math.min(255, Math.max(0, rgb[0]))
      rgb[1] = Math.min(255, Math.max(0, rgb[1]))
      rgb[2] = Math.min(255, Math.max(0, rgb[2]))
    }

    this.setState({
      hex: `#${hex}`,
      r: rgb[0],
      g: rgb[1],
      b: rgb[2],
    })

    return rgb
  }

  getLightnessFromMouseEvent(event) {
    const canvasX = Math.floor(event.pageX - this.refs.canvas.offsetLeft)
    const canvasY = Math.floor(event.pageY - this.refs.canvas.offsetTop)

    const dx = Math.abs(COLOR_PICKER_DIMENSIONS_HALF - canvasX)
    const dy = Math.abs(COLOR_PICKER_DIMENSIONS_HALF - canvasY)

    const magnitude = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))

    return Math.max(0, Math.min(1, (magnitude - COLOR_PICKER_WHITE_START) / COLOR_PICKER_RING_SIZE))
  }

  setColorFromPreset(preset) {
    const hsl = converter.rgb.hsl(preset.r, preset.g, preset.b)

    const degrees = hsl[0]
    const radians = degrees * Math.PI / 180

    const x = Math.cos(radians)
    const y = Math.sin(radians)

    const magnitude = this.getMagnitudeFromSaturation(hsl[2])

    const posX = magnitude * x
    const posY = magnitude * y

    const buffer = this.refs.brightnessSlider.clientHeight / 2

    this.setState({
      colorX: COLOR_PICKER_DIMENSIONS_HALF + posX - COLOR_PICKER_DIMENSIONS + SVG_LEFT_BUFFER,
      colorY: COLOR_PICKER_DIMENSIONS_HALF + posY + SVG_TOP_BUFFER,
      brightnessY: Math.max(-buffer, Math.min(buffer, -(preset.brightness / 100 * this.refs.brightnessSlider.clientHeight - buffer))),
      ...preset,
      brightness: preset.brightness / 100,
    })

    this.startSampleTimer()
  }

  getMagnitudeFromSaturation(saturation) {
    return COLOR_PICKER_WHITE_START + COLOR_PICKER_RING_SIZE * ((100 - saturation) / 50)
  }

  onPresetSelected(preset) {
    this.setState({ presetSelected: preset.name })
    this.setColorFromPreset(preset)
  }

  //Touch craziness
  componentWillUnmount() {
    this.removeDocumentEvents()
  }
  onTouchMove(e) {
    this.handleMouseMove(this.touchEventToMouseEvent(e))
  }

  onBrightnessTouchMove(e) {
    this.handleBrightnessMouseMove(this.touchEventToMouseEvent(e))
  }

  touchEventToMouseEvent(e) {
    return {
      target: e.target,
      pageX: e.targetTouches[0].pageX,
      pageY: e.targetTouches[0].pageY,
    }
  }

  onEnd() {
  }

  onColorPickerTouchStart() {
    if (!this.colorPickerTouchEvents) {
      this.colorPickerTouchEvents = this.addTouchEvents(this.refs.colorPickerContainer, this.onTouchMove, this.onEnd)
      this.colorPickerMouseEvents = this.addMouseEvents(this.refs.colorPickerContainer,
        this.handleMouseMove,
        this.handleMouseMove)
    }
  }

  onBrightnessTouchStart() {
    if (!this.brightnessTouchEvents) {
      this.brightnessTouchEvents = this.addTouchEvents(this.refs.brightnessSlider, this.onBrightnessTouchMove, this.onEnd)
      this.colorPickerMouseEvents = this.addMouseEvents(this.refs.brightnessSlider,
        this.handleBrightnessMouseMove,
        this.handleBrightnessMouseMove)
    }
  }

  addTouchEvents(ref, touchMove, touchEnd) {
    // just work for Chrome iOS Safari and Android Browser
    return {
      onTouchMoveListener: addEventListener(ref, 'touchmove', touchMove),
      onTouchUpListener: addEventListener(ref, 'touchend', touchEnd),
    }
  }

  addMouseEvents(ref, mouseMove, mouseUp) {
    return {
      onMouseMoveListener: addEventListener(ref, 'mousemove', mouseMove),
      onMouseUpListener: addEventListener(ref, 'mouseup', mouseUp),
    }
  }

  removeDocumentEvents() {
    this.removeTouchEvents(this.colorPickerTouchEvents)
    this.removeTouchEvents(this.brightnessTouchEvents)
    this.removeMouseEvents(this.colorPickerMouseEvents)
    this.removeMouseEvents(this.brightnessMouseEvents)
  }

  removeTouchEvents(element) {
    if (element) {
      if (element.onTouchMoveListener) {
        element.onTouchMoveListener.remove()
      }
      if (element.onTouchUpListener) {
        element.onTouchUpListener.remove()
      }
    }
  }

  removeMouseEvents(element) {
    if (element) {
      if (element.onMouseMoveListener) {
        element.onMouseMoveListener.remove()
      }
      if (element.onTouchUpListener) {
        element.onTouchUpListener.remove()
      }
    }
  }

  render() {
    const bright = `rgba(${this.state.r}, ${this.state.g}, ${this.state.b}, 1)`
    const dark = `rgba(${this.state.r}, ${this.state.g}, ${this.state.b}, .15)`

    return (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div ref="colorPickerContainer" className="colorpicker"
          onMouseMove={this.onColorPickerTouchStart}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '53%',
            height: `${COLOR_PICKER_DIMENSIONS}px`,
          }}
        >
          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
            <canvas ref="canvas" width={`${COLOR_PICKER_DIMENSIONS}px`} height={`${COLOR_PICKER_DIMENSIONS}px`} />
            <div
              style={{
                position: 'relative',
                width: `${SVG_WIDTH}px`,
                height: `${SVG_HEIGHT}px`,
                left: this.state.colorX,
                top: this.state.colorY,
                background: `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`,
                borderColor: '#FFFFFF',
                borderWidth: '2px',
                borderRadius: '50%',
                borderStyle: 'solid',
              }}
              ref="canvasSelector"
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderRadius: '16px',
              width: '32px',
              height: `100%`,
              background: `rgba(100, 100, 100, .15)`,
              display: 'flex',
              flexDirection: 'column',
            }} >
            <img
              alt="b-up"
              src={require('./brightness-up.svg')}
              style={{
                marginTop: '8px',
                marginBottom: '8px',
              }}
            />
            <div ref="brightnessSlider"
              onMouseMove={this.onBrightnessTouchStart}
              style={{
                borderRadius: '8px',
                width: 16,
                flexGrow: 1,
                background: `linear-gradient(${bright}, ${dark})`,
                marginLeft: '8px',
              }} />
            <img
              alt="b-down"
              src={require('./brightness-down.svg')}
              style={{
                marginTop: '8px',
                marginBottom: '8px',
              }}
            />
          </div>
          <div
            ref="brightnessCircleSelector"
            style={{
              position: 'relative',
              width: `${SVG_WIDTH}px`,
              height: `${SVG_HEIGHT}px`,
              top: this.state.brightnessY,
              pointerEvents: 'none',
              left: '-36px',
              background: `rgba(${this.state.r}, ${this.state.g}, ${this.state.b}, ${this.state.brightness})`,
              borderColor: '#FFFFFF',
              borderWidth: '2px',
              borderRadius: '50%',
              borderStyle: 'solid',
            }}
          />
        </div>
        <div style={{ width: '47%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          <Presets presets={this.props.presets} presetSelected={this.state.presetSelected}
            onPresetSelected={this.onPresetSelected} styles={this.props.styles} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    presets: state.colorPicker.presets,
    conf: state.configState.conf,
    lights: state.lightState.lights,
    colorPickerColor: state.lightState.colorPickerColor,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ColorPicker)
