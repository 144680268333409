import * as paymentReceiptActions from '../actions/payment.receipt'

const INITIAL_STATE = {
  isLoading: false,
  error: undefined,
}

export const paymentReceipt = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case paymentReceiptActions.START_PAYMENT_RECEIPT_REQUEST:
      return { ...state, isLoding: true }
    case paymentReceiptActions.STOP_PAYMENT_RECEIPT_REQUEST:
      return { ...state, isLoading: false }
    case paymentReceiptActions.PAYMENT_RECEIPT_REQUEST_ERROR:
      return { ...state, isLoading: false, error: payload }
    default:
      return state
  }
}
