import React, { Component } from 'react'
import TextIn from './shared/TextIn'
import InputLabel from './shared/InputLabel'
import { Padding, ContainerStyle, PageSpinner } from '../styles/style.format'
import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Divider from 'material-ui/Divider'
import { validateEmail } from './shared/validation'
import LogoWithNavigation from './signupScreen/LogoWithNavigation'

class EmailScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navTimer: null,
      email: props.email,
    }

    this.isFormValid = this.isFormValid.bind(this)
  }

  componentDidMount() {
    this.props.pageLoadStop()
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    this.stopNavigationTimer()
  }

  startNavigationTimer() {
    this.stopNavigationTimer()

    this.setState({
      navTimer: setTimeout(() => {
        this.props.logEvent('Payment Workflow', 'Timeout', { evLabel: 'EmailScreen', evValue: this.props.config.signUpPageTimeout || 30000 })
        this.props.navigate('Welcome')
      }, parseFloat(this.props.config.signUpPageTimeout) || 30000),
    })
  }

  stopNavigationTimer() {
    clearTimeout(this.state.navTimer)
  }

  onSubmitNext() {
    if (this.isFormValid()) {
      this.props.pageLoadStart()
      this.props.setSignupEmail(this.state.email)
      this.props.navigate('ReservationConfirmation')
    }
  }

  isFormValid() {
    return validateEmail(this.state.email)
  }

  getTextIn(type, displayType, id, placeholder, validator) {
    return <TextIn type={type} displayType={displayType} id={id}
      selected={true} styles={this.props.styles}
      onChange={ email => {
        this.setState({ email })
        this.startNavigationTimer()
      }}
      isSubmitEnabled={ this.isFormValid.bind(this) }
      value={ this.state.email }
      placeholder={ placeholder }
      onSubmitNext={ this.onSubmitNext.bind(this) }
      submitText="Next"
      country={ this.state.country }
      validator={ validator }
      isFirstLetterUppercase={ true }
    />
  }

  render() {
    return (
      <div>
        { !this.props.pageLoaded && <div style={ PageSpinner }>
          <img alt="Loading" src={`${process.env.PUBLIC_URL}/img/spinner.svg`} />
        </div> }
        { this.props.pageLoaded && <div style={{ ...ContainerStyle, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          <LogoWithNavigation styles={this.props.styles} leftFunction={() => {
            this.stopNavigationTimer()
            this.props.navigate('PageOne')
          }} closeFunction={() => {
            this.stopNavigationTimer()
            this.props.navigate('Welcome')
          }} />
          <Divider style={{ ...this.props.styles.ExternalDivider, width: '100%' }} />
          <div style={{ paddingTop: Padding.Small }} />
          <div style={{ paddingTop: Padding.Large }} />
          <InputLabel title={'EMAIL ADDRESS'} subText={'This will be used to send receipts & updates'}
            styles={this.props.styles} />
          <div style={{ paddingTop: Padding.Small }} />
          {this.getTextIn('email', 'text', 'email', 'Email Address', () => {
            validateEmail(this.state.email)
          })}
        </div> }
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    config: state.configState.conf,
    styles: state.Style.theme,
    email: state.SignUp.email,
    pageLoaded: state.pageLoaded,
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailScreen)
