import * as Navigation from './nav'
import { create } from '../util/services'

export const GET_ACCESS_STARTED = 'GET_ACCESS_STARTED'
export const GET_ACCESS_STOPPED = 'GET_ACCESS_STOPPED'
export const GET_ACCESS_ERROR = 'GET_ACCESS_ERROR'

export const getAccessStarted = () => ({ type: GET_ACCESS_STARTED })
export const getAccessStopped = access => ({ type: GET_ACCESS_STOPPED, payload: access })
export const getAccessError = error => ({ type: GET_ACCESS_ERROR, payload: error })
export const getAccess = code => {
  return (dispatch, getState) => {
    dispatch(getAccessStarted())
    return create(`/clientAccesses/access`, getState(), { code })
            .then(data => {
              dispatch(getAccessStopped(data))
              if (data.accessType === 'CONFIG') {
                //TODO: Should this instead pop up a menu for now?
                dispatch(Navigation.navigate('Config'))
              }
            })
            .catch(error => {
              dispatch(getAccessError(error))
            })
  }
}
