import React, { Component } from 'react'
import * as ScreenKeyboard from 'react-screen-keyboard'
import NumericLayout from './NumericLayout'
import 'react-screen-keyboard/src/Keyboard.css'

class Keyboard extends Component {
  //Copied from keyboard, OK for now, but we might want to fork later instead of this
  isUppercase() {
    const { inputNode, isFirstLetterUppercase } = this.props
    return inputNode.type !== 'password' &&
      inputNode.dataset.type !== 'email' &&
      !inputNode.value.length && isFirstLetterUppercase
  }

  handleLetterButtonClick(key) {
    const { inputNode } = this.props
    const { value, selectionStart, selectionEnd } = inputNode
    const nextValue = value.substring(0, selectionStart) + key + value.substring(selectionEnd)

    inputNode.value = nextValue
    inputNode.focus()
    inputNode.setSelectionRange(selectionStart + key.length, selectionStart + key.length)
    this.setState({ uppercase: this.isUppercase() })
    inputNode.dispatchEvent(new Event('input', { bubbles: true }))
  }

  handleBackspaceClick() {
    const { inputNode } = this.props
    const { value, selectionStart, selectionEnd } = inputNode
    let nextValue = ''
    let nextSelectionPosition = -1
    if (selectionStart === selectionEnd) {
      nextValue = value.substring(0, selectionStart - 1) + value.substring(selectionEnd)
      nextSelectionPosition = selectionStart - 1
    } else {
      nextValue = value.substring(0, selectionStart) + value.substring(selectionEnd)
      nextSelectionPosition = selectionStart
    }
    nextSelectionPosition = (nextSelectionPosition > 0) ? nextSelectionPosition : 0

    inputNode.value = nextValue
    inputNode.focus()
    inputNode.setSelectionRange(nextSelectionPosition, nextSelectionPosition)
    this.setState({ uppercase: this.isUppercase() })
    inputNode.dispatchEvent(new Event('input', { bubbles: true }))
  }

  getLeftButtons(type) {
    switch (type) {
      case 'numeric':
        return [
          <ScreenKeyboard.KeyboardButton
            key={'left'}
            onClick={this.handleBackspaceClick.bind(this)}
            value={<img alt="backspace" src={require('./Backspace.svg')} />}
          />,
          <ScreenKeyboard.KeyboardButton
            key={'0'}
            onClick={this.handleLetterButtonClick.bind(this)}
            value="0"
          />,
        ]
      case 'email':
        return [
          <ScreenKeyboard.KeyboardButton
            key={'@'}
            value="@"
            onClick={this.handleLetterButtonClick.bind(this)}
            classes="keyboard-at-button"
          />,
        ]
      default:
        return []
    }
  }
  getRightButtons(type) {
    const keys = []
    switch (type) {
      case 'email':
        keys.push(
          <ScreenKeyboard.KeyboardButton
            key={'.com'}
            value=".com"
            onClick={this.handleLetterButtonClick.bind(this)}
            classes="keyboard-dotcom-button"
          />)
        break
      default:
    }
    const handleSubmitButton = this.props.handleSubmitButton || Function.prototype
    const submitText = this.props.submitText || 'Submit'
    const isDisabled = this.props.isSubmitEnabled && !this.props.isSubmitEnabled(type)
    keys.push(
      <ScreenKeyboard.KeyboardButton
        key={'submit'}
        value={submitText}
        onClick={handleSubmitButton}
        classes="keyboard-submit-button"
        isDisabled={isDisabled}
      />,
    )
    return keys
  }
  getLayout(type) {
    switch (type) {
      case 'numeric':
        return NumericLayout
      default:
        return ScreenKeyboard.LatinLayout
    }
  }
  getClassName(type) {
    return type ? `keyboard-${type}` : ''
  }
  getProperties(type) {
    return {
      leftButtons: this.getLeftButtons(type),
      rightButtons: this.getRightButtons(type),
      layout: this.getLayout(type),
      className: this.getClassName(type),
    }
  }
  render() {
    const properties = this.getProperties(this.props.type)
    //TODO: Remove inline style for Kimo changes
    return (
      <div className={properties.className} style={{ position: 'fixed', bottom: 0, right: 0, backgroundColor: 'transparent', zIndex: 10 }}>
        {this.props.inputNode &&
          <ScreenKeyboard.default
            style={{ position: 'fixed', bottom: 0, width: '100%' }}
            inputNode={this.props.inputNode}
            isFirstLetterUppercase={this.props.isFirstLetterUppercase}
            leftButtons={properties.leftButtons}
            rightButtons={properties.rightButtons}
            layouts={[properties.layout]}
          />
        }
      </div>
    )
  }
}
export default Keyboard
