import { combineReducers } from 'redux'
import * as userReducers from './user'
import * as doorReducers from './door'
import * as configReducers from './config'
import * as menuReducers from './menu'
import * as signupReducers from './sign.up'
import * as navigationReducers from './nav'
import * as pageReducers from './page'
import * as marketingCardReducers from './marketing.card'
import * as styleReducer from './style'
import * as lightsReducers from './lights'
import * as eventLogReducers from './event.log'
import * as colorPickerReducers from './color.picker'
import * as paymentReducers from './payment'
import * as locationPricingPlan from './location.pricing.plan'
import * as jabbrrboxPricingPlan from './jabbrrbox.pricing.plan'
import * as partnerPricingPlan from './partner.pricing.plan'
import * as paymentReceipt from './payment.receipt'
import * as remoteActions from './remote.actions'
import * as jabbrrboxFeedback from './jabbrrbox.feedback'
import * as flightStatus from './flight.status'
import * as weatherReducers from './weather'
import * as googleAnalytics from './google.analytics'

const rootReducer = combineReducers({
  ...userReducers,
  ...doorReducers,
  ...configReducers,
  ...menuReducers,
  ...signupReducers,
  ...navigationReducers,
  ...pageReducers,
  ...marketingCardReducers,
  ...styleReducer,
  ...lightsReducers,
  ...eventLogReducers,
  ...colorPickerReducers,
  ...paymentReducers,
  ...locationPricingPlan,
  ...jabbrrboxPricingPlan,
  ...partnerPricingPlan,
  ...paymentReceipt,
  ...remoteActions,
  ...jabbrrboxFeedback,
  ...flightStatus,
  ...weatherReducers,
  ...googleAnalytics,
})

export default rootReducer
