import { rgbToCie } from './util'

export const getLights = state => {
  return fetch(`${state.restServiceBaseURL}/${state.restServiceAPIKey}/lights`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
}

export const updateLight = (state, lightId, color, options = {}) => {
  const cie = rgbToCie(color.r, color.g, color.b)
  return fetch(`${state.restServiceBaseURL}/${state.restServiceAPIKey}/lights/${lightId}/state`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...options, xy: cie, bri: Math.floor(254 * (color.brightness || 1)), on: true }),
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
}

export const turnLightOff = (state, lightId) => {
  return fetch(`${state.restServiceBaseURL}/${state.restServiceAPIKey}/lights/${lightId}/state`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ on: false }),
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
}


export const turnLightOn = (state, lightId) => {
  return fetch(`${state.restServiceBaseURL}/${state.restServiceAPIKey}/lights/${lightId}/state`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ on: true }),
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
}
