import React from 'react'

const internalStyles = styles => {
  return { container: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    ...styles.InputSubHeader,
    textAlign: 'center',
  },
  header: {
    ...styles.InputHeader,
    textAlign: 'center',
  },
  }
}
const InputLabel = ({ title, subText, extraSubText, styles }) => {
  const iStyles = internalStyles(styles)
  return (
    <div style={iStyles.container}>
      <div style={iStyles.header}>{title}</div>
      <div style={iStyles.text}>{`${subText || ''}`}</div>
      <div style={iStyles.text}>{`${extraSubText || ''}`}</div>
    </div>
  )
}
export default InputLabel
