import Style, { generateStyle } from '../styles'
import { get } from '../util/services'

const deepmerge = require('deepmerge')

export const UPDATE_THEME_STARTED = 'UPDATE_THEME_STARTED'
export const UPDATE_THEME_STOPPED = 'UPDATE_THEME_STOPPED'
export const UPDATE_THEME_ERROR = 'UPDATE_THEME_ERROR'

export const SET_THEME = 'SET_THEME'

export const updateThemeStarted = () => ({ type: UPDATE_THEME_STARTED })
export const updateThemeStopped = payload => ({ type: UPDATE_THEME_STOPPED, payload })
export const updateThemeError = error => ({ type: UPDATE_THEME_ERROR, payload: error })
const style = (defaultTheme, customTheme) => {
  const Color = {
    ...defaultTheme.Color,
    ...customTheme.Color,
  }
  const FontFamily = {
    ...defaultTheme.FontFamily,
    ...customTheme.FontFamily,
  }
  const FontSize = {
    ...defaultTheme.FontSize,
    ...customTheme.FontSize,
  }
  const FontWeight = {
    ...defaultTheme.FontWeight,
    ...customTheme.FontWeight,
  }
  const LetterSpacing = {
    ...defaultTheme.LetterSpacing,
    ...customTheme.LetterSpacing,
  }
  const LineHeight = {
    ...defaultTheme.LineHeight,
    ...customTheme.LineHeight,
  }

  const Theme = {
    Color,
    FontWeight,
    LetterSpacing,
    LineHeight,
    FontSize,
    FontFamily,
  }
  return deepmerge(generateStyle(Theme), customTheme)
}

export const updateTheme = jabbrrboxId => {
  return (dispatch, getState) => {
    dispatch(updateThemeStarted())
    return get(`/jabbrrboxes/${jabbrrboxId}/sponsor`, getState()).then(data => {
      dispatch(updateThemeStopped(style(Style, data.theme || {})))
    })
      .catch(error => {
        dispatch(updateThemeError(error))
      }
      )
  }
}

export const setTheme = payload => ({ type: SET_THEME, payload })
