import React, { Component } from 'react'
import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import InputLabel from '../shared/InputLabel'
import { Padding, ContainerStyle } from '../../styles/style.format'
import PropTypes from 'prop-types'
import Divider from 'material-ui/Divider'
import LogoWithNavigation from '../signupScreen/LogoWithNavigation'
import FlagIcon from '../flagIcon/FlagIcon'
import FlatButton from 'material-ui/FlatButton'
import { Textfit } from 'react-textfit'
import { countries } from 'react-flag-icon-css/lib/static/enums'
import { List } from 'react-virtualized'

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

const SPECIAL_ORDER_COUNTRIES = [
  'us',
]

const sortCounties = () => {
  const metadata = phoneUtil.getSupportedRegions()

  const usableCountries = countries.filter(country => {
    return metadata.includes(country.code.toUpperCase())
  })
  const special = usableCountries.filter(country => {
    return SPECIAL_ORDER_COUNTRIES.indexOf(country.code) !== -1
  }).sort((a, b) => {
    const aIndex = SPECIAL_ORDER_COUNTRIES.indexOf(a.code)
    const bIndex = SPECIAL_ORDER_COUNTRIES.indexOf(b.code)
    return aIndex - bIndex
  })

  const sortable = usableCountries.filter(country => {
    return SPECIAL_ORDER_COUNTRIES.indexOf(country.code) === -1
  })

  const ordered = sortable.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

  return special.concat(ordered)
}
const ORDERED_COUNTRIES = sortCounties()

let flagList = []
const flagRow = ({ key, index, isScrolling, isVisible, style }) => {
  return (
    <div
      key={key}
      style={style}
    >
      {flagList[index]}
    </div>
  )
}

class CountrySelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navTimer: null,
      country: this.props.country,
    }
  }

  componentWillMount() {
    flagList = this.createFlagList(this.props.nav.options.selectedCountryCode)
  }

  componentDidMount() {
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    this.stopNavigationTimer()
  }

  startNavigationTimer() {
    this.stopNavigationTimer()

    this.setState({
      navTimer: setTimeout(() => {
        this.props.navigate('Welcome')
      }, parseFloat(this.props.config.signUpPageTimeout) || 30000),
    })
  }

  stopNavigationTimer() {
    clearTimeout(this.state.navTimer)
  }

  isSelected(id) {
    return id === this.state.selected
  }

  submitForm() {
    this.stopNavigationTimer()
  }

  isLoading() {
    return this.props.isLoadingSignUp || this.props.isLoadingMakeReservation
  }

  createFlagList(selectedCountryCode) {
    const flagList = []
    ORDERED_COUNTRIES.forEach(country => {
      const isSelected = selectedCountryCode === country.code
      flagList.push(
        <div
          key={country.code}
          style={isSelected ? this.props.styles.CountryOptionActiveButton : this.props.styles.CountryOptionButton}
          onClick={() => {
            this.props.setSignupCountry(country)
            this.props.navigate('PageOne')
          }}
        >
          <FlatButton
            key={country.code}
            style={isSelected ? this.props.styles.CountryOptionActiveButtonLabel : this.props.styles.CountryOptionButtonLabel}
            disableTouchRipple={true}
            labelStyle={this.props.styles.CountryButtonLabel}
            icon={<div style={{ ...this.props.styles.CountryButtonLabel, display: 'flex', width: '100%' }}>
              <div style={{ minWidth: '80px', width: '80px' }}><FlagIcon code={country.code} size={'lg'} /></div>
              <Textfit
                mode="single"
                forceSingleModeWidth={true}
                max={this.props.styles.CountryButtonLabel.fontSize}
                style={{ ...this.props.styles.CountryButtonLabel, textAlign: 'left', width: '80%' }}>
                {country.name}
              </Textfit>
            </div>}
          />
        </div>
      )
    })

    return flagList
  }

  render() {
    return (
      <div style={{ ...ContainerStyle, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <LogoWithNavigation styles={this.props.styles} leftFunction={() => {
          this.stopNavigationTimer()
          this.props.navigate('PageOne')
        }} closeFunction={() => {
          this.stopNavigationTimer()
          this.props.navigate('Welcome')
        }} />
        <Divider style={{ ...this.props.styles.ExternalDivider, width: '100%' }} />
        <div style={{ paddingTop: Padding.Large }} />
        <InputLabel title={'SELECT YOUR COUNTRY CODE'} subText={''} styles={this.props.styles} />
        <div style={{ paddingTop: Padding.Small }} />
        <div style={{ paddingTop: Padding.Small }} />
        <div style={{ width: '100%' }}>
          <List
            width={670}
            height={900}
            rowCount={flagList.length}
            rowHeight={120}
            rowRenderer={flagRow}
          />
        </div>
      </div>)
  }
}

CountrySelector.propTypes = {
  onClick: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    config: state.configState.conf,
    nav: state.Navigation,
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector)
