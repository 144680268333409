import React, { Component } from 'react'
import LogoDisplay from '../shared/LogoDisplay'
import { Padding } from '../../styles/style.format'
import Style from '../../styles'
import Divider from 'material-ui/Divider'

const height = 104
const viewHeight = height + Padding.XLarge + Padding.Large
class MidContainer extends Component {

  render() {
    return (
      <div>
        <LogoDisplay viewOptions={{ borderBottomWidth: 1, height: viewHeight }}
          options={{ width: 574, height, marginTop: Padding.XLarge }} />
        <Divider style={ Style.ExternalDivider } />
      </div>
    )
  }
}
export default MidContainer