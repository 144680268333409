import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Divider from 'material-ui/Divider'

import { ActionCreators } from '../../actions'
import TextIn from '../shared/TextIn'
import InputLabel from '../shared/InputLabel'
import { Padding, ContainerStyle, PageSpinner } from '../../styles/style.format'
import LogoWithNavigation from '../signupScreen/LogoWithNavigation'

class PartnerCodeScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navTimer: null,
      code: null,
    }

    this.isPromocodeValid = this.isPromocodeValid.bind(this)
  }

  componentDidMount() {
    this.props.pageLoadStop()
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    this.stopNavigationTimer()
  }

  startNavigationTimer() {
    this.stopNavigationTimer()

    this.setState({
      navTimer: setTimeout(() => {
        this.props.logEvent('Payment Workflow', 'Timeout', { evLabel: 'PartnerCodeScreen', evValue: this.props.config.signUpPageTimeout || 30000 })
        this.props.navigate('Welcome')
      }, parseFloat(this.props.config.signUpPageTimeout) || 30000),
    })
  }

  stopNavigationTimer() {
    clearTimeout(this.state.navTimer)
  }

  onSubmitNext() {
    if (this.isPromocodeValid()) {
      this.props.pageLoadStart()

      this.props.loadPartnerPricingPlans(this.props.partner.id).then(() => {
        this.props.pageLoadStop()
        this.props.navigate('PricingOptions')
      })
    }
  }

  isPromocodeValid() {
    if (!this.props.partner.promocode)
      return false
    return this.props.partner.promocode === this.state.code
  }

  getTextIn(type, displayType, id, placeholder, validator) {
    return <TextIn type={type} displayType={displayType} id={id}
      selected={true} styles={this.props.styles}
      onChange={ code => {
        this.setState({ code })
        this.startNavigationTimer()
      }}
      isSubmitEnabled={ this.props.pageLoaded && this.isPromocodeValid }
      value={ this.state.code }
      placeholder={ placeholder }
      onSubmitNext={ this.onSubmitNext.bind(this) }
      submitText="Next"
      validator={ validator }
      isFirstLetterUppercase={ false }
    />
  }

  render() {
    return (
      <div>
        { !this.props.pageLoaded && <div style={ PageSpinner }>
          <img alt="Loading" src={`${process.env.PUBLIC_URL}/img/spinner.svg`} />
        </div> }
        { this.props.pageLoaded && <div style={{ ...ContainerStyle, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          <LogoWithNavigation styles={this.props.styles} leftFunction={() => {
            this.stopNavigationTimer()
            this.props.navigate('PartnerOptions')
          }} closeFunction={() => {
            this.stopNavigationTimer()
            this.props.navigate('Welcome')
          }} />
          <Divider style={{ ...this.props.styles.ExternalDivider, width: '100%' }} />
          <div style={{ paddingTop: Padding.Small }} />
          <div style={{ paddingTop: Padding.Large }} />
          <InputLabel title={'ENTER PROMOTIONAL CODE'} subText={'Please enter promotional code here'}
            styles={this.props.styles} />
          <div style={{ paddingTop: Padding.Small }} />
          {this.getTextIn('promocode', 'text', 'promoCode', 'Promo code', () => {
            this.isPromocodeValid()
          })}
        </div> }
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    config: state.configState.conf,
    styles: state.Style.theme,
    partner: state.partnerPricingPlan.selectedPartner,
    pageLoaded: state.pageLoaded,
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PartnerCodeScreen)
