import React from 'react'

const Sponsor = ({ styles, sponsor }) => {
  if (sponsor) {
    let sponsorView = <div style={styles.Sponsor}>{sponsor.name}</div>
    if (sponsor.logoUrl) {
      sponsorView = <img src={ sponsor.logoUrl } width="200" height="200" alt="sponsor" />
    }
    return (
      <div>{ sponsorView }</div>
    )
  } else {
    return ''
  }
}
export default Sponsor
