import * as eventLog from '../actions/event.log'

const INITIAL_STATE = {
  eventQueue: [],
  pendingEvents: [],
}

export const EventLog = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case eventLog.LOG_EVENT :
      return { ...state, eventQueue: state.eventQueue.concat([payload]) }
    case eventLog.SAVE_EVENTS_STARTED :
      return { ...state, pendingEvents: state.eventQueue, eventQueue: [] }
    case eventLog.SAVE_EVENTS_SUCCESSFUL :
      return { ...state, pendingEvents: [] }
    case eventLog.SAVE_EVENTS_FAILURE :
      return { ...state,
        eventQueue: state.pendingEvents.concat(state.eventQueue),
        pendingEvents: [],
      }
    default:
      return state
  }
}
