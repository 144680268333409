import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LogoWithNavigation from './signupScreen/LogoWithNavigation'
import Divider from 'material-ui/Divider'
import PricingPlanListComponent from '../components/pricingOptionScreen/PricingPlanListComponent'
import { Padding, ContainerStyle } from '../styles/style.format'

let navTimer
class PricingOptionScreen extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedPricingOption: null,
    }

    this.handleSelectedPricingOption = this.handleSelectedPricingOption.bind(this)
  }

  componentWillMount() {
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    clearTimeout(navTimer)
  }

  startNavigationTimer() {
    this.stopNavigationTimer()
    navTimer = setTimeout(() => {
      this.props.logEvent('Payment Workflow', 'Timeout', { evLabel: 'PricingOptionsScreen', evValue: this.props.config.pricingOptionTimeout || 30000 })
      this.props.navigate('Welcome')
    }, parseFloat(this.props.config.pricingOptionTimeout || 30000))
  }

  stopNavigationTimer() {
    clearTimeout(navTimer)
  }

  handleSelectedPricingOption(plan) {
    this.props.setSelectedPricingOption(plan)
    this.props.navigate('PageOne')
  }

  render() {
    let pricingOptions = null
    if (this.props.selectedPartner && this.props.partnerPricingPlans.length > 0) {
      pricingOptions = <PricingPlanListComponent
        styles={this.props.styles}
        selectedPriceOption={this.state.selectedPricingOption}
        pricingPlans={this.props.partnerPricingPlans}
        onSelectedPriceOption={this.handleSelectedPricingOption} />
    } else if (this.props.jabbrrboxPricingPlan.plans.length > 0) {
      pricingOptions = <PricingPlanListComponent
        styles={this.props.styles}
        selectedPriceOption={this.state.selectedPricingOption}
        pricingPlans={this.props.jabbrrboxPricingPlan.plans}
        onSelectedPriceOption={this.handleSelectedPricingOption} />
    } else if (this.props.locationPricingPlan.plans.length > 0) {
      pricingOptions = <PricingPlanListComponent
        styles={this.props.styles}
        selectedPriceOption={this.state.selectedPricingOption}
        pricingPlans={this.props.locationPricingPlan.plans}
        onSelectedPriceOption={this.handleSelectedPricingOption} />
    } else {
      pricingOptions = <div>No Pricing Options</div>
    }

    return (
      <div style={ContainerStyle}>
        <div>
          <LogoWithNavigation styles={this.props.styles} hideBackButton={true} leftFunction={() => {
            this.props.navigate('TellMeMore')
          }}
          closeFunction={() => {
            this.props.navigate('Welcome')
          }} />
        </div>

        <div style={{ ...this.props.styles.PricingOptionTitle, marginBottom: Padding.Medium }}>
          Select a pricing option to continue
        </div>
        <Divider />
        <div style={this.props.styles.PricingOptionsList}>
          {pricingOptions}
        </div>
      </div>
    )
  }
}

PricingOptionScreen.propTypes = {
  styles: PropTypes.object,
  locationPricingPlan: PropTypes.object,
  jabbrrboxPricingPlan: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    person: state.SignUp.registeredPerson,
    config: state.configState.conf,
    locationPricingPlan: state.locationPricingPlan,
    jabbrrboxPricingPlan: state.jabbrrboxPricingPlan,
    payment: state.payment,
    selectedPartner: state.partnerPricingPlan.selectedPartner,
    partnerPricingPlans: state.partnerPricingPlan.plans,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PricingOptionScreen)
