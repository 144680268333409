import React, { Component } from 'react'
import { Padding } from '../../styles/style.format'
import Divider from 'material-ui/Divider'
import KeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down'

export default class WhenToUseJabbrrBoxComponent extends Component {
  render() {
    return (
      <div style={this.props.styles.WhenToUseContainer}>
        <div style={{ ...this.props.styles.WhenToUseTitle, marginTop: Padding.XSmall, marginBottom: Padding.Large }}>WHEN TO USE A JABBRRBOX</div>
        {this.props.marketingCard && this.props.marketingCard.cards &&
                    this.props.marketingCard.cards.map((card, index) =>
                      <div key={index}>
                        <div style={{ display: 'flex', flex: 1, paddingLeft: Padding.Medium, marginLeft: Padding.XSmall, marginRight: Padding.XSmall,
                          marginBottom: Padding.XSmall, marginTop: Padding.XSmall }}>
                          <div>
                            <img src={card.imageURL} alt={card.title} />
                          </div>
                          <div style={{ marginLeft: Padding.Large, marginTop: Padding.XXSmall }}>
                            <div style={{ ...this.props.styles.WhenToUseSubtitle, marginBottom: Padding.XSmall }}>
                              {card.title}
                            </div>
                            <div style={{ ...this.props.styles.WhenToUseBody }}>
                              {card.body}
                            </div>
                          </div>
                        </div>
                        {index < this.props.marketingCard.cards.length - 1 &&
                        <div style={{ display: 'flex', flex: 1 }}>
                          <Divider style={{ backgroundColor: this.props.styles.Color.NeutralLight, width: '100%',
                            marginTop: Padding.Small, marginBottom: Padding.Small }} />
                          <KeyboardArrowDown style={{ marginLeft: Padding.Small, marginRight: Padding.Small,
                            marginTop: Padding.XXSmall, width: 40, color: this.props.styles.Color.Neutral }} />
                          <Divider style={{ backgroundColor: this.props.styles.Color.NeutralLight, width: '100%',
                            marginTop: Padding.Small, marginBottom: Padding.Small }} />
                        </div>
                        }
                      </div>
                    )
        }
      </div>
    )
  }
}
