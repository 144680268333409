const convert = require('xml2js')

export const setupPayment = (state, purchase, jabbrrboxReservationId) => {
  const trans = {
    TStream: {
      Transaction: {
        ...state.Transaction,
        InvoiceNo: jabbrrboxReservationId,
        Amount: {
          Purchase: purchase,
        },
        OKAmount: 'Disallow',
      },
    },
  }
  const builder = new convert.Builder()
  const xml = builder.buildObject(trans)
  return fetch(`${state.serviceURL}/method1`, {
    method: 'POST',
    headers: {
      Accept: '*/*',
      'Content-Type': 'text/xml',
      'Invoke-Control': 'EMVX',
    },
    body: xml,
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.text().then(text => {
      return new Promise((resolve, reject) => {
        convert.parseString(text, (err, json) => {
          if (err) {
            reject(err)
          }
          resolve(json)
        })
      })

    })
  })
}
