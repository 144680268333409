import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../app/actions'
import _ from 'lodash'
import FlatButton from 'material-ui/FlatButton'

const environment = process.env.NODE_ENV || 'production'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      countdownTime: parseFloat(this.props.config.oopsPageTimeoutSeconds) || 10,
      interval: undefined,
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    }, () => {
      this.startReloadTimer()
    })
    const exception = {
      locaiontId: this.props.jabbrrbox.locationId,
      jabbrrboxId: this.props.jabbrrbox.id,
      error: error.stack,
      info: JSON.stringify(info),
      route: _.get(this.props.navigation, 'route', 'Unavailable'),
      environment,
      datetimeGenerated: new Date().toISOString(),
    }
    this.props.logExceptionToDatabase(exception)
    this.props.logException(exception) // Google Analytics
  }

  startReloadTimer() {
    const interval = setInterval(() => {
      this.setState(prevState => {
        return { countdownTime: prevState.countdownTime - 1 }
      }, () => {
        if (this.state.countdownTime === 0) {
          this.stopReloadTimer()
          window.location.reload()
        }
      })
    }, 1000)
    this.setState({ interval })
  }

  stopReloadTimer() {
    clearInterval(this.state.interval)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{
          display: 'flex', alignItems: 'center', height: '100%', width: '100%', justifyContent: 'space-between',
          flexDirection: 'column', color: this.props.styles.Color.NeutralDark,
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: '129px' }}>
              Oops...
            </div>
            <div>
              {`It's not you, it's us.  We have been alerted of the issue and are working on it.`}
            </div>
            <div>
              {`Alternatively, you can try to reload the application with the button below`}
            </div>
          </div>
          <div>
            <FlatButton
              style={this.props.styles.PrimaryButtonTall}
              labelStyle={this.props.styles.PrimaryButtonTallLabel}
              label="Reload Application" onClick={() => {
                this.stopReloadTimer()
                window.location.reload()
              }}
              primary={true} />
          </div>
          <div>
            <div style={{ textAlign: 'center' }}>{`Automatic Reload in: ${this.state.countdownTime}`}</div>
            {`Contact us: support@jabbrrbox.com`}
          </div>
        </div >
      )
    }
    return this.props.children
  }
}

const mapStateToProps = state => {
  return {
    config: state.configState.conf,
    jabbrrbox: state.doorState.jabbrrbox,
    navigation: state.Navigation,
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
