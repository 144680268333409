import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../actions'

class InternalHoldingScreen extends Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.reservation) {
      this.props.navigate('InternalWelcome')
      this.props.setSelectedMenuItemByName('Home')
    }
  }

  render() {

    return (
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch',
        alignContent: 'stretch', width: '100%', height: '100%', overflow: 'auto',
        backgroundColor: this.props.styles.Color.NeutralDark,
      }}>
        <div style={{
          display: 'flex', flex: 1, flexDirection: 'column',
          alignItems: 'stretch', alignContent: 'stetch',
        }}>

          <div className="x jabbrrbox-screensaver-container">
            <img className="y jabbrrbox-screensaver-img" src={`${process.env.PUBLIC_URL}/img/jabbrrbox_logo.svg`} alt="jabbrrbox logo" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reservation: state.doorState.jabbrrboxReservation,
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalHoldingScreen)
