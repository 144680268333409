import React, { Component } from 'react'
import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FlatButton from 'material-ui/FlatButton'
import moment from 'moment'
import { Padding, Margin } from '../styles/style.format'
import LogoWithNavigation from './signupScreen/LogoWithNavigation'
import ClickNHold from 'react-click-n-hold'

const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

const combineStyles = propStyle => {
  return {
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    textContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: Padding.XXLarge,
    },
    H2: {
      ...propStyle.H2,
      paddingBottom: Padding.XXXLarge,
    },
  }
}

class RoomReservedScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navigationTimer: null,
    }

    this.onCancelHold = this.onCancelHold.bind(this)

    if (this.props.reservation) { //No more reservation
      this.stopNavigationTimer()
    } else {
      this.props.navigate('Welcome')
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.reservation) { //No more reservation
      this.stopNavigationTimer()
      this.props.navigate('Welcome')
    }
  }

  componentDidMount() {
    this.startNavigationTimer()
  }

  startNavigationTimer() {
    this.setState({
      navigationTimer: setTimeout(() => {
        this.props.getReservations(this.props.config.jabbrrbox.id)
        this.startNavigationTimer()
      }, parseFloat(this.props.config.timeout) || 30000),
    })
  }

  stopNavigationTimer() {
    clearTimeout(this.state.navigationTimer)
  }

  onCancelHold() {
    if (this.props.reservation) {
      this.props.cancelReservation(this.props.reservation)
    }
  }

  getDiscretePhone() {
    let formattedPhone = this.props.person ? this.props.person.mobile : ''
    try {
      formattedPhone = phoneUtil
        .format(phoneUtil.parse(formattedPhone, this.props.countryCode, PNF.INTERNATIONAL), PNF.INTERNATIONAL).replace(/-/g, ' ')
    } catch (e) {
      //nothing
    }

    const splitNumber = formattedPhone.split(' ')

    const discretePhone = splitNumber.map((part, index) => {
      if (index === 0) {
        return <div key={index} style={{ marginRight: 2, marginLeft: 2 }}>{part}</div>
      } else if (index + 1 === splitNumber.length) {
        return <div key={index}>{part}</div>
      } else {
        return <div key={index}>{this.getDiscretePart(part)}</div>
      }
    })
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {discretePhone}
      </span>
    )
  }

  getDiscretePart(part) {
    const splitPart = part.split('')
    const circles = splitPart.map(index => {
      return <div key={index} style={this.props.styles.DiscretePhoneCircle} />
    })
    return (
      <div style={{ display: 'flex', flex: 1, alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
        {circles}&nbsp;-
      </div>
    )
  }

  render() {
    const styles = combineStyles(this.props.styles)
    const nextReservationTime = this.props.reservation ? moment(this.props.reservation.datetimeEnd).format('hh:mm') : '???'
    const amOrPm = this.props.reservation ? moment(this.props.reservation.datetimeEnd).format('a').toUpperCase() : '???'
    return (
      <div style={styles.container}>
        <div style={{ backgroundColor: this.props.styles.Color.White }}>
          <LogoWithNavigation styles={this.props.styles} viewOptions={{ backgroundColor: this.props.styles.Color.White }} hideButtons={true} />
        </div>
        <div style={{ backgroundColor: this.props.styles.Color.BrandPrimary, paddingTop: Padding.XLarge, alignItems: 'center', width: '100%' }}>
          <div
            style={styles.H2}>RESERVED</div>
          <div style={{ flexDirection: 'row', alignItems: 'center', paddingBottom: Padding.XXLarge }}>
            <div style={{ ...this.props.styles.AvailabiltyHeader, paddingLeft: 30, paddingRight: 30 }}>
              NEXT AVAILABILITY AT
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: Padding.Large }}>
            <div style={this.props.styles.TimeHeader}>{nextReservationTime}</div>
            <div style={{ ...this.props.styles.TimeHeader, paddingLeft: Padding.Large }}>{amOrPm}</div>
          </div>
          <div style={{ ...this.props.styles.InfoText, paddingBottom: Padding.XLarge }}>
            <div>
              New to Jabbrrbox?
            </div>
            <FlatButton
              style={ this.props.styles.LinkButton }
              label={'Tell Me More'}
              labelPosition="before"
              labelStyle={ this.props.styles.LinkButtonLabel }
              primary={true}
              onClick={() => {
                this.props.navigate('TellMeMore')
              }}
            />
          </div>
        </div>
        <div style={styles.textContainer}>
          <div style={{ ...this.props.styles.InfoText, textAlign: 'center' }}>
            <div>This Jabbrrbox is reserved for</div>
            <div style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              marginTop: Margin.Small,
            }}>{this.getDiscretePhone()}</div>
          </div>

          <div style={{ paddingTop: Padding.XXXLarge }}>
            <FlatButton
              style={this.props.styles.PrimaryButtonTall}
              label={'Enter Access Code'}
              labelPosition="before"
              labelStyle={this.props.styles.PrimaryButtonTallLabel}
              primary={true}
              onClick={() => {
                this.stopNavigationTimer()
                this.props.navigate('EnterPin', { mode: 'unlock' })
              }}
            />
          </div>
          {this.props.config && this.props.config.cancelReservation && !this.props.isLoading &&
            <div style={{ paddingTop: Padding.Medium }}>
              <ClickNHold
                time={3}
                onStart={this.start}
                onClickNHold={this.onCancelHold}
                onEnd={this.end}>
                <FlatButton
                  style={this.props.styles.PrimaryButton}
                  label={'Hold to Cancel Current Reservation'}
                  labelPosition="before"
                  primary={true}
                  disableTouchRipple={true}
                />
              </ClickNHold>

            </div>
          }
          {this.props.isLoading &&
            <div style={{ width: 50, height: 50, margin: 'auto', paddingTop: Padding.Medium }}>
              <img alt="Loading" src={`${process.env.PUBLIC_URL}/img/spinner.svg`} />
            </div>
          }
        </div>
      </div>
    )
  }

  doOpenDoor(code) {
    this.props.openDoor(code, this.props.config.jabbrrbox.id)
  }
}

const mapStateToProps = state => {
  return {
    reservation: state.doorState.jabbrrboxReservation,
    person: state.doorState.person,
    isLoading: state.doorState.isLoading,
    config: state.configState.conf,
    styles: state.Style.theme,
    countryCode: state.SignUp.country.code,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(RoomReservedScreen)
