import React from 'react'
import { Padding, ContainerStyle } from '../../styles/style.format'


const questionsIcon = `${process.env.PUBLIC_URL}/img/icons/active/questions.svg`
const locationIcon = `${process.env.PUBLIC_URL}/img/icons/neutral/Location.svg`

const SupportFooterComponent = ({ styles }) => {
  return (
    <div style={{ backgroundColor: styles.Color.NeutralLight }}>
      <div style={{ ...styles.SupportFooterContainer, ...ContainerStyle,
        paddingLeft: Padding.XLarge, paddingRight: Padding.XLarge, paddingBottom: Padding.XSmall }}>
        <div style={{ ...styles.SupportFooter, marginRight: Padding.Medium,
          display: 'flex', flex: 1 }}>
          <div>
            <img src={questionsIcon} role={'presentation'}
              style={{ marginTop: -10 }} />
          </div>
          <div>
            <span style={styles.SupportFooterLead}>Still Have Questions?</span> contact support@jabbrrbox.com and ask us your question
          </div>
        </div>
        <div style={{ ...styles.SupportFooter, display: 'flex', flex: 1, justifyContent: 'center' }}>
          <div>
            <img src={locationIcon} role={'presentation'} style={{ width: 24, marginRight: 5 }} />
          </div>
          <div>
            <span style={styles.SupportFooterLead}>Join Our Network!</span> Contact us at sales@jabbrrbox.com if you would like to join our expanding network of locations.
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportFooterComponent
