import React from 'react'
import { Padding } from '../../styles/style.format'
import FlatButton from 'material-ui/FlatButton'

const TermsAndConditions = ({ styles, toggleShowTermsAndConditions }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ flex: 1, overflow: 'hidden', padding: Padding.Small, overflowX: 'hidden' }}>
        <iframe src="/termsandconditions.html" width="100%" height="100%" />
      </div>
      <div style={{ paddingBottom: Padding.Small }}>
        <FlatButton style={{ ...styles.PrimaryButton, flex: '0 0' }} onClick={toggleShowTermsAndConditions} label="Close" secondary={true} />
      </div>
    </div>)

}
export default TermsAndConditions
