import React from 'react'
import ChevronLeft from 'material-ui/svg-icons/navigation/chevron-left'
import Close from 'material-ui/svg-icons/navigation/close'
import LogoDisplay from '../shared/LogoDisplay'
import FlatButton from 'material-ui/FlatButton'
import { Padding } from '../../styles/style.format'

const LogoWithNavigation = ({ leftFunction, closeFunction, hideButtons, styles }) => {
  const justify = hideButtons ? 'center' : 'space-between'
  return (
    <div style={{ width: '100%', height: 96, paddingTop: Padding.XLarge, paddingBottom: Padding.XLarge, justifyContent: justify, display: 'flex', alignItems: 'center' }}>
      {!hideButtons &&
      <FlatButton
        style={{ backgroundColor: styles.Color.NeutralLight, borderRadius: '10px', minWidth: 64, width: 64, minHeight: 64, height: 64 }}
        label={''}
        labelPosition="before"
        primary={true}
        icon={<ChevronLeft color={styles.Color.Neutral} style={{ width: 48, height: 48 }} />}
        onClick={() => {
          if (leftFunction) {
            leftFunction()
          }
        }}
      />
      }
      <LogoDisplay/>
      {!hideButtons &&
        <FlatButton
          style={{ backgroundColor: styles.Color.NeutralLight, borderRadius: '10px', minWidth: 64, width: 64, minHeight: 64, height: 64 }}
          label={''}
          labelPosition="before"
          primary={true}
          icon={<Close color={styles.Color.Neutral} style={{ width: 48, height: 48 }} />}
          onClick={() => {
            if (closeFunction) {
              closeFunction()
            }
          }}
        />
      }
    </div>
  )
}
export default LogoWithNavigation
