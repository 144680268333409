import React from 'react'
import { Padding, Margin } from '../../../styles/style.format'
import Divider from 'material-ui/Divider'
import { MenuItem } from './MenuItem'
import LogoDisplay from '../LogoDisplay'

const internalStyles = styles => {
  return {
    container: {
      flexDirection: 'column',
      alignItems: 'center',
      width: 248,
      paddingLeft: Padding.Small,
      paddingRight: Padding.Small,
      backgroundColor: styles.Color.NeutralExtraDark,
      textAlign: 'center',
    },
  }
}

export const MainMenu = ({ styles, menuItems, onItemSelected, selectedMenuItem }) => {
  const renderedMenuItems = []
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i]
    renderedMenuItems.push(
      <div key={i}>
        <MenuItem styles={styles} menuItem={menuItem} isSelected={menuItem === selectedMenuItem} key={i} onClick={item => {
          onItemSelected(item)
        }} />
        {i !== menuItems.length - 1 &&
          <Divider style={{ backgroundColor: styles.Color.NeutralDark }} />
        }
      </div>
    )
    if (!selectedMenuItem) {
      onItemSelected(menuItems[0])
    }
  }
  const internalStyle = internalStyles(styles)
  return (
    <div style={internalStyle.container}>
      <LogoDisplay
        viewOptions={{
          width: '250',
          borderBottomWidth: 1, borderColor: styles.Color.NeutralDark,
          display: 'flex',
          height: '120px',
        }}
        options={{ width: '194px', margin: `${Margin.XXSmall} 0` }} />
      <Divider style={{ backgroundColor: styles.Color.NeutralDark }} />
      <div style={{ flex: 1, flexDirection: 'column' }} >
        {renderedMenuItems}
      </div>
    </div>
  )
}
export default MainMenu
