export const ADD_NUMBER_TO_PHONE = 'ADD_NUMBER_TO_PHONE'
export const ADD_ALL_THE_NUMBERS = 'ADD_ALL_THE_NUMBERS'
export const EMAIL_ADDRESS = 'EMAIL'
export const FIRST_NAME = 'FIRST_NAME'
export const LAST_NAME = 'LAST_NAME'
export const REMOVE_NUMBER_FROM_PHONE = 'REMOVE_NUMBER_FROM_PHONE'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
export const addFirstName = firstName => ({ type: FIRST_NAME, payload: firstName })
export const addLastName = lastName => ({ type: LAST_NAME, payload: lastName })
export const addEmail = email => ({ type: EMAIL_ADDRESS, payload: email })
export const addNumberToPhone = mobile => ({ type: ADD_NUMBER_TO_PHONE, payload: mobile })
export const addAllTheNumbers = mobile => ({ type: ADD_ALL_THE_NUMBERS, payload: mobile })
export const removeNumberFromPhone = () => ({ type: REMOVE_NUMBER_FROM_PHONE })
export const clearUserData = () => ({ type: CLEAR_USER_DATA })
