import moment from 'moment'

import {
  NAVIGATE,
} from './app/actions/nav'

import {
  UPDATE_CONFIG_STOPPED,
  UPDATE_CONFIG_ERROR,
  LOAD_CONFIG_STOPPED,
  LOAD_CONFIG_ERROR,
  LOGGED_IN,
  LOGGED_IN_ERROR,
} from './app/actions/config'

import {
  CODE_ENTERED,
  DOOR_IS_OPENED,
  DOOR_IS_LOCKED,
  DOOR_IS_UNLOCKED,
  DOOR_REQUEST_OPEN_STOPPED,
  DOOR_REQUEST_OPEN_ERROR,
  DOOR_REQUEST_LOCK_STOPPED,
  DOOR_REQUEST_LOCK_ERROR,
} from './app/actions/door'

import {
  GET_LIGHTS_STOPPED,
  GET_LIGHTS_ERROR,
  UPDATE_COLOR_PICKER_COLOR_STOPPED,
  UPDATE_COLOR_PICKER_COLOR_ERROR,
  TURN_LIGHT_OFF_STOPPED,
  TURN_LIGHT_OFF_ERROR,
} from './app/actions/lights'

import {
  SET_SELECTED_MENU_ITEM,
} from './app/actions/menu'

import {
  SETUP_PAYMENT_STOPPED,
  SETUP_PAYMENT_ERROR,
} from './app/actions/payment'

const WHITELIST = [
  //nav actions
  NAVIGATE,
  //config actions
  UPDATE_CONFIG_STOPPED,
  UPDATE_CONFIG_ERROR,
  LOAD_CONFIG_STOPPED,
  LOAD_CONFIG_ERROR,
  LOGGED_IN,
  LOGGED_IN_ERROR,
  //door actions
  CODE_ENTERED,
  DOOR_IS_OPENED,
  DOOR_IS_LOCKED,
  DOOR_IS_UNLOCKED,
  DOOR_REQUEST_OPEN_STOPPED,
  DOOR_REQUEST_OPEN_ERROR,
  DOOR_REQUEST_LOCK_STOPPED,
  DOOR_REQUEST_LOCK_ERROR,
  //lights actions
  GET_LIGHTS_STOPPED,
  GET_LIGHTS_ERROR,
  UPDATE_COLOR_PICKER_COLOR_STOPPED,
  UPDATE_COLOR_PICKER_COLOR_ERROR,
  TURN_LIGHT_OFF_STOPPED,
  TURN_LIGHT_OFF_ERROR,
  //menu actions
  SET_SELECTED_MENU_ITEM,
  //payment actions
  SETUP_PAYMENT_STOPPED,
  SETUP_PAYMENT_ERROR,
]

const eventlogMiddleware = store => next => action => {
  if (WHITELIST.includes(action.type)) {
    let data = {}
    if (action.payload !== null && action.payload !== undefined) {
      if (Array.isArray(action.payload)) {
        data = { data: action.payload }
      } else {
        data = action.payload
      }
    }
    store.dispatch({
      type: 'LOG_EVENT', payload: {
        datetime: moment(),
        type: action.type,
        data,
      },
    })
  }
  next(action)
}

export default eventlogMiddleware
