import * as weather from '../actions/weather'

const INITIAL_STATE = {
  zoneForecast: {},
  metar: {},
  taf: {},
  error: undefined,
  isLoading: false,
}

const prepareMetar = metar => {
  metar.tempuratureFahrenheit = Math.ceil((metar.temperatureCelsius * (9 / 5)) + 32).toString()
  return metar
}

export const Weather = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case weather.GET_ZONE_FORECAST_STARTED:
      return { ...state, isLoading: true }
    case weather.GET_ZONE_FORECAST_STOPPED:
      return {
        ...state,
        zoneForecast: { ...state.zoneForecast, [payload.request.airport.requestedCode]: payload.zoneForecast },
        isLoading: false,
      }
    case weather.GET_ZONE_FORECAST_ERROR:
      return { ...state, error: payload, isLoading: false }
    case weather.GET_ALL_WEATHER_STARTED:
      return { ...state, isLoading: true }
    case weather.GET_ALL_WEATHER_STOPPED:
      return {
        ...state,
        zoneForecast: { ...state.zoneForecast, [payload.request.airport.requestedCode]: payload.zoneForecast },
        taf: { ...state.zoneForecast, [payload.request.airport.requestedCode]: payload.taf },
        metar: { ...state.metar, [payload.request.airport.requestedCode]: prepareMetar(payload.metar) },
        isLoading: false,
      }
    case weather.GET_ALL_WEATHER_ERROR:
      return { ...state, error: payload, isLoading: false }
    default:
      return state
  }
}
