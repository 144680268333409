import { get } from '../util/services'

export const LOAD_LOCATION_PRICING_PLANS_STARTED = 'LOAD_LOCATION_PRICING_PLANS_STARTED'
export const LOAD_LOCATION_PRICING_PLANS_STOPPED = 'LOAD_LOCATION_PRICING_PLANS_STOPPED'
export const LOAD_LOCATION_PRICING_PLANS_ERROR = 'LOAD_LOCATION_PRICING_PLANS_ERROR'

export const loadLocationPricingPlansStarted = () => ({ type: LOAD_LOCATION_PRICING_PLANS_STARTED })
export const loadLocationPricingPlansStopped = plans => ({ type: LOAD_LOCATION_PRICING_PLANS_STOPPED, payload: plans })
export const loadLocationPricingPlansError = error => ({ type: LOAD_LOCATION_PRICING_PLANS_ERROR, payload: error })
export const loadLocationPricingPlans = () => {
  return (dispatch, getState) => {
    dispatch(loadLocationPricingPlansStarted())
    return get(`/locationPricingPlans?filter[include][pricingPlan]&filter[where][locationId]=${getState().doorState.jabbrrbox.locationId}`, getState())
      .then(data => {
        dispatch(loadLocationPricingPlansStopped(data))
      })
      .catch(error => {
        dispatch(loadLocationPricingPlansError(error))
      })
  }
}
