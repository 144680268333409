
export const getAPIKey = () => {
  const decodedCookie = decodeURIComponent(document.cookie)
  const name = 'jabbrrbox_token'
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      const out = c.substring(name.length, c.length)
      return out === '=' ? undefined : out
    }
  }
  return undefined
}
export const saveApiKey = key => {
  const date = new Date()
  const days = 5
  //TODO add days to expire to some type of config
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
  const cookie = `jabbrrbox_token=${key}; expires=${date.toUTCString()}; path=/`
  document.cookie = cookie
}
export const removeApiKey = () => {
  saveApiKey('')
}
const api = '/api'
const getFilter = filters => {
  if (filters) {
    return `?filter${filters.join('&filter')}`
  }
  return ''
}
const getApiKey = ({ loggedInKey }) => {
  if (!loggedInKey) {
    return ''
  }
  return loggedInKey.id
}
const getUrl = (url, filter) => `${api}${url}${getFilter(filter)}`

export const create = (url, { configState }, body) => {
  return fetch(getUrl(url), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: getApiKey(configState),
    },
    body: JSON.stringify(body),
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  }, error => {
    throw Error(error)
  })
}
export const sendFiles = (url, { configState }, body) => {
  return fetch(getUrl(url), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'process-data': false,
      Authorization: getApiKey(configState),
    },
    body,
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  }, error => {
    throw Error(error)
  })
}
export const get = (url, { configState }, filter) => {
  return fetch(getUrl(url, filter), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: getApiKey(configState),
    },
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
}
export const remove = (url, { configState }) => {
  return fetch(getUrl(url), {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: getApiKey(configState),
    },
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
}
export const patch = (url, { configState }, body) => {
  return fetch(getUrl(url), {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: getApiKey(configState),
    },
    body: JSON.stringify(body),
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
}
export const update = (url, { configState }, body) => {
  return fetch(getUrl(url), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: getApiKey(configState),
    },
    body: JSON.stringify(body),
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response.json()
  })
}
