import { create } from '../util/services'

const DEFAULT_CONFIG = require('../../config.json')

export const UPDATE_CONFIG_STARTED = 'UPDATE_CONFIG_STARTED'
export const UPDATE_CONFIG_STOPPED = 'UPDATE_CONFIG_STOPPED'
export const UPDATE_CONFIG_ERROR = 'UPDATE_CONFIG_ERROR'

export const LOAD_CONFIG_STARTED = 'LOAD_CONFIG_STARTED'
export const LOAD_CONFIG_STOPPED = 'LOAD_CONFIG_STOPPED'
export const LOAD_CONFIG_ERROR = 'LOAD_CONFIG_ERROR'
export const LOGGED_IN = 'LOGGED_IN'
export const LOGGED_IN_ERROR = 'LOGGED_IN_ERROR'

export const loadConfigStarted = () => ({ type: LOAD_CONFIG_STARTED })
export const loadConfigStopped = payload => ({ type: LOAD_CONFIG_STOPPED, payload })
export const loadConfigError = error => ({ type: LOAD_CONFIG_ERROR, payload: error })

export const loadConfig = () => {
  return dispatch => {
    dispatch(loadConfigStarted())
    const readConfig = window.require ? window.require('electron').remote.getCurrentWindow().readConfig : null
    if (readConfig === null) {
      dispatch(loadConfigError('Cannot read config from file'))
      dispatch(loadConfigStopped(DEFAULT_CONFIG))
    } else {
      readConfig((err, data) => { //Call electron wrapper's readConfig method
        if (err) {
          dispatch(loadConfigStopped({ ...DEFAULT_CONFIG, error: err }))
          return
        }
        const conf = { ...DEFAULT_CONFIG, ...data }
        dispatch(loadConfigStopped({ ...DEFAULT_CONFIG, ...conf, error: err }))
      })
    }
  }
}
export const login = (email, password) => {
  return (dispatch, getState) => {
    create(`/users/login`, getState(), { email, password }).then(data => {
      dispatch({ type: LOGGED_IN, payload: data })
    })
      .catch(error => {
        dispatch({ type: LOGGED_IN_ERROR, error })
      }
      )
  }
}


export const updateConfigStarted = () => ({ type: UPDATE_CONFIG_STARTED })
export const updateConfigStopped = payload => ({ type: UPDATE_CONFIG_STOPPED, payload })
export const updateConfigError = error => ({ type: UPDATE_CONFIG_ERROR, payload: error })
export const updateConfig = config => {
  return dispatch => {
    dispatch(updateConfigStarted())
    const writeConfig = window.require ? window.require('electron').remote.getCurrentWindow().writeConfig : null
    if (writeConfig) {
      const conf = {
        jabbrrbox: {
          id: config.jabbrrbox.id,
          mode: config.jabbrrbox.mode,
          email: config.jabbrrbox.email,
          password: config.jabbrrbox.password,
        },
      }
      writeConfig(conf, () => {
        dispatch(updateConfigStopped())
        dispatch(loadConfig())
      })
    } else {
      dispatch(updateConfigError('Cannot write to config file'))
    }

  }
}
