import * as lightsService from '../services/hue.service'

export const GET_LIGHTS_STARTED = 'GET_LIGHTS_STARTED'
export const GET_LIGHTS_STOPPED = 'GET_LIGHTS_STOPPED'
export const GET_LIGHTS_ERROR = 'GET_LIGHTS_ERROR'

export const UPDATE_LIGHT_STARTED = 'UPDATE_LIGHT_STARTED'
export const UPDATE_LIGHT_STOPPED = 'UPDATE_LIGHT_STOPPED'
export const UPDATE_LIGHT_ERROR = 'UPDATE_LIGHT_ERROR'

export const UPDATE_COLOR_PICKER_COLOR_STARTED = 'UPDATE_COLOR_PICKER_COLOR_STARTED'
export const UPDATE_COLOR_PICKER_COLOR_STOPPED = 'UPDATE_COLOR_PICKER_COLOR_STOPPED'
export const UPDATE_COLOR_PICKER_COLOR_ERROR = 'UPDATE_COLOR_PICKER_COLOR_ERROR'

export const TURN_LIGHT_OFF_STARTED = 'TURN_LIGHT_OFF_STARTED'
export const TURN_LIGHT_OFF_STOPPED = 'TURN_LIGHT_OFF_STOPPED'
export const TURN_LIGHT_OFF_ERROR = 'TURN_LIGHT_OFF_ERROR'

export const SET_LIGHT_TIMER_STARTED = 'SET_LIGHT_TIMER_STARTED'
export const SET_LIGHT_TIMER_STOPPED = 'SET_LIGHT_TIMER_STOPPED'
export const SET_LIGHT_TIMER_ERROR = 'SET_LIGHT_TIMER_ERROR'

export const CLEAR_LIGHT_TIMER_STARTED = 'CLEAR_LIGHT_TIMER_STARTED'
export const CLEAR_LIGHT_TIMER_STOPPED = 'CLEAR_LIGHT_TIMER_STOPPED'
export const CLEAR_LIGHT_TIMER_ERROR = 'CLEAR_LIGHT_TIMER_ERROR'

export const SET_DEFAULT_LIGHTS = 'SET_DEFAULT_LIGHTS'

export const LIGHT_MODES = {
  SINGLE_PASS: 'SINGLE_PASS',
  LOOP: 'LOOP',
}

export const getLightsRequestStarted = () => ({ type: GET_LIGHTS_STARTED })
export const getLightsRequestError = err => ({ type: GET_LIGHTS_ERROR, payload: err })
export const getLightsRequestStopped = (payload = {}) => ({ type: GET_LIGHTS_STOPPED, payload })

export const updateLightRequestStarted = () => ({ type: UPDATE_LIGHT_STARTED })
export const updateLightRequestError = err => ({ type: UPDATE_LIGHT_ERROR, payload: err })
export const updateLightRequestStopped = (payload = {}) => ({ type: UPDATE_LIGHT_STOPPED, payload })


export const updateColorPickerLightRequestStarted = () => ({ type: UPDATE_COLOR_PICKER_COLOR_STARTED })
export const updateColorPickerLightRequestStopped = (payload = {}) => ({ type: UPDATE_COLOR_PICKER_COLOR_STOPPED, payload })
export const updateColorPickerLightRequestError = err => ({ type: UPDATE_COLOR_PICKER_COLOR_ERROR, payload: err })

export const turnLightOffRequestStarted = () => ({ type: TURN_LIGHT_OFF_STARTED })
export const turnLightOffRequestError = err => ({ type: TURN_LIGHT_OFF_ERROR, payload: err })
export const turnLightOffRequestStopped = (payload = {}) => ({ type: TURN_LIGHT_OFF_STOPPED, payload })

export const setLightTimerRequestStarted = () => ({ type: SET_LIGHT_TIMER_STARTED })
export const setLightTimerRequestStopped = (payload = {}) => ({ type: SET_LIGHT_TIMER_STOPPED, payload })
export const setLightTimerRequestError = err => ({ type: SET_LIGHT_TIMER_ERROR, payload: err })

export const clearLightTimerRequestStarted = () => ({ type: CLEAR_LIGHT_TIMER_STARTED })
export const clearLightTimerRequestStopped = (payload = {}) => ({ type: CLEAR_LIGHT_TIMER_STOPPED, payload })
export const clearLightTimerRequestError = err => ({ type: CLEAR_LIGHT_TIMER_ERROR, payload: err })

export const getLights = () => {
  return (dispatch, getState) => {
    dispatch(getLightsRequestStarted())
    lightsService.getLights(getState().configState.conf.lights).then(data => {
      dispatch(getLightsRequestStopped(data))
    }).catch(err => {
      dispatch(getLightsRequestError(err))
    })
  }
}

export const updateLight = (lightId, color, options = {}) => {
  return (dispatch, getState) => {
    dispatch(updateLightRequestStarted())
    lightsService.updateLight(getState().configState.conf.lights, lightId, color, options).then(() => {
      dispatch(updateLightRequestStopped({
        id: lightId,
        color,
        options,
      }))
    }).catch(err => {
      dispatch(updateLightRequestError(err))
    })
  }
}

export const lightRotation = (lightId, interval, colors, mode = LIGHT_MODES.LOOP, transitionTime = 500) => {
  return (dispatch, getState) => {
    dispatch(clearLightTimerRequestStopped(lightId))
    dispatch(setLightTimerRequestStarted())
    let colorIndex = 0
    const lightRotationTimer = setInterval(() => {
      switch (mode) {
        case LIGHT_MODES.LOOP:
          if (colorIndex > colors.length - 1) {
            colorIndex = 0
          }
          updateLight(lightId, colors[colorIndex], { transitiontime: transitionTime / 100 })(dispatch, getState)
          break
        case LIGHT_MODES.SINGLE_PASS:
          if (colorIndex > colors.length - 1) {
            dispatch(clearLightTimerRequestStopped(lightId))
          } else {
            updateLight(lightId, colors[colorIndex], { transitiontime: transitionTime / 100 })(dispatch, getState)
          }
          break
        default:
          dispatch(clearLightTimerRequestStopped(lightId))
      }
      ++colorIndex
    }, interval)
    dispatch(setLightTimerRequestStopped({ lightId, lightRotationId: lightRotationTimer }))
  }
}

export const setLightsToDefault = () => {
  return (dispatch, getState) => {
    const state = getState()
    updateLight(state.configState.conf.lights.internal.id, state.configState.conf.lights.internal.reservationStart.colors[0])(dispatch, getState)
    updateLight(state.configState.conf.lights.photoBooth.id, state.configState.conf.lights.photoBooth.reservationStart.colors[0])(dispatch, getState)
  }
}

export const updateColorPickerLight = (lightId, color, options = {}) => {
  return (dispatch, getState) => {
    dispatch(updateColorPickerLightRequestStarted())
    lightsService.updateLight(getState().configState.conf.lights, lightId, color, options).then(() => {
      dispatch(updateColorPickerLightRequestStopped({
        id: lightId,
        color,
        options,
      }))
    }).catch(err => {
      dispatch(updateColorPickerLightRequestError(err))
    })
  }
}

export const turnLightOff = lightId => {
  return (dispatch, getState) => {
    dispatch(turnLightOffRequestStarted())
    lightsService.turnLightOff(getState().configState.conf.lights, lightId).then(() => {
      dispatch(turnLightOffRequestStopped({
        id: lightId,
      }))
    }).catch(err => {
      dispatch(turnLightOffRequestError(err))
    })
  }
}
