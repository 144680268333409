import * as paymentActions from '../actions/payment'

const INITIAL_STATE = {
  isLoading: false,
  selectedPriceOption: {},
  paymentData: {},
  successful: false,
  error: undefined,
}

export const payment = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case paymentActions.CLEAR_PAYMENT_INFO:
      return INITIAL_STATE
    case paymentActions.SET_SELECTED_PRICING_PLAN:
      return { ...state, selectedPriceOption: payload }
    case paymentActions.CLEAR_PAYMENT_TRANSACTION_INFO:
      return { ...state, successful: false, paymentData: {}}
    case paymentActions.SETUP_PAYMENT_STARTED:
      return { ...state, isLoading: true }
    case paymentActions.SETUP_PAYMENT_STOPPED:
      return { ...state, isLoading: false, successful: true, paymentData: payload }
    case paymentActions.SETUP_PAYMENT_ERROR:
      return { ...state, isLoading: false, error: payload }
    default:
      return state
  }
}
