import moment from 'moment'

require('moment-duration-format')

export const getDiscreteEmail = email => {
  if (!email) {
    return ''
  }
  const parts = email.split('@')
  const reg = /(.{2}).+(.{2})/
  const firstPartPadded = parts[0].length < 4 ? `${parts[0][0]}***` : parts[0].replace(reg, `$1${Array(parts[0].length).join('*')}$2`)
  const secondPartPadded = parts[1].length < 4 ? `${parts[1][0]}***` : parts[1].replace(reg, `$1${Array(parts[1].length).join('*')}$2`)
  return `${firstPartPadded}@${secondPartPadded}`
}

export const getDurationLabel = duration => {
  let durationLabel = null

  if (duration === 60) {
    durationLabel = moment.duration(duration, 'minutes').format('h [Hour]')
  } else if ((duration % 60) === 0) {
    durationLabel = moment.duration(duration, 'minutes').format('h [Hours]')
  } else if (duration > 60 && duration < 120) {
    durationLabel = moment.duration(duration, 'minutes').format('h [Hour] m [Minutes]')
  } else if (duration > 120) {
    durationLabel = moment.duration(duration, 'minutes').format('h [Hours] m [Minutes]')
  } else {
    durationLabel = moment.duration(duration, 'minutes').format('m [Minutes]')
  }
  return durationLabel
}
