import React from 'react'
import { Padding, ContainerStyle } from '../../styles/style.format'

const ValuePropositionComponent = ({ styles }) => {
  return (
    <div style={ContainerStyle}>
      <div style={{ ...styles.ValueProposition, color: styles.Color.Neutral, paddingTop: Padding.XSmall,
        paddingBottom: Padding.XSmall, paddingLeft: Padding.XSmall,
        paddingRight: Padding.XSmall }}>A technology equipped workspace that is plush, and quiet.
        <div style={{ ...styles.ValueProposition, color: styles.Color.BrandPrimary }}>
                    Designed for the mobile workers of the world who need a better environment on the go.
        </div>
      </div>
    </div>
  )
}

export default ValuePropositionComponent
