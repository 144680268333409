import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Padding } from '../styles/style.format'
import FlatButton from 'material-ui/FlatButton'
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right'

class InternalHomeScreen extends Component {
  onColorSelected(color) {
    this.props.updateLight(this.props.config.lights.internal.id, color)
  }

  render() {
    const styleBase = {
      position: 'relative',
      backgroundColor: this.props.styles.Color.Neutral,
      borderRadius: '4px',
      color: this.props.styles.Color.White,
    }
    const mainSectionStyle = {
      ...styleBase,
      width: '930px',
      height: '794px',
    }
    const smallSectionStyle = {
      ...styleBase,
      width: '400px',
      height: '200px',
    }
    const smallBottomSectionStyle = {
      ...styleBase,
      width: '400px',
      height: '328px',
    }
    const sectionStyle = {
      ...this.props.styles.WelcomeSubHeadingText,
      paddingLeft: Padding.Medium,
      paddingRight: Padding.Medium,
      paddingTop: Padding.Medium,
    }
    const findOutMoreInnerContainer = {
      ...sectionStyle,
      paddingRight: Padding.XXXXXLarge,
    }
    const innerSectionStyle = {
      ...sectionStyle,
      ...this.props.styles.InternalInfoText,
      paddingRight: Padding.XXXXLarge,
      paddingTop: Padding.Small,
      float: 'right',
    }
    const innerSectionFooter = {
      ...this.props.styles.InternalHomeSubHeaderText,
      paddingBottom: Padding.XXSmall,
      position: 'absolute',
      bottom: 0,
      right: 0,
    }
    return (
      <div style={{
        display: 'flex', height: '100%', flex: 1,
        justifyContent: 'center', marginTop: Padding.XLarge, marginBottom: Padding.XLarge,
      }}>
        <div style={{ ...mainSectionStyle, marginRight: Padding.Medium }}>
          <div style={{
            height: '100%',
            position: 'relative',
            float: 'left',
            marginLeft: -Padding.Large,
            marginTop: Padding.Medium,
          }}>
            <img
              alt="b-down"
              src={`${process.env.PUBLIC_URL}/img/jabbrrbox_furniture.png`}
              style={{
                height: '100%',
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '0px', height: '100%', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '0px', paddingBottom: Padding.Large, height: '100%', justifyContent: 'center' }}>
              <div style={{ ...sectionStyle, paddingBottom: Padding.Large, ...this.props.styles.FindOutMoreText }}>Find out more about Jabbrrbox.</div>
              <div style={{ ...findOutMoreInnerContainer, ...this.props.styles.InternalInfoText }}>
                Everything from how we started to the inner workings of a Jabbrrbox.
              </div>
            </div>
            <div style={{ ...sectionStyle, paddingRight: 0, paddingTop: 0, paddingBottom: Padding.XXSmall, textAlign: 'right' }}>
              <FlatButton
                disabled={true}
                label={'Coming Soon'}
                labelPosition={'before'}
                labelStyle={{ ...this.props.styles.WelcomeScreenButtonText, color: this.props.styles.Color.NeutralDark }}
                style={{ height: '64px' }}
                primary={true}
                icon={<ChevronRight style={{ height: '64px', width: '64px' }} color={this.props.styles.Color.NeutralDark} />}
              />
            </div>
          </div>
        </div>
        <div>
          <div style={{ ...smallSectionStyle, ...this.props.styles.InternalInfoText }}
            onClick={() => {
              this.props.setSelectedMenuItemByName('WiFi')
            }}
          >
            <div style={sectionStyle}>Everything is better with wifi.</div>
            <div style={innerSectionFooter}>
              <FlatButton
                label={'WiFi'}
                labelPosition={'before'}
                labelStyle={this.props.styles.WelcomeScreenButtonText}
                style={{ height: '64px' }}
                primary={true}
                onClick={() => {
                  this.props.setSelectedMenuItemByName('WiFi')
                }}
                icon={<ChevronRight style={{ height: '64px', width: '64px' }} color={this.props.styles.Color.White} />}
              />
            </div>
          </div>
          <div style={{ ...smallSectionStyle, marginTop: Padding.Medium }}
            onClick={() => {
              this.props.setSelectedMenuItemByName('Lighting')
            }}
          >
            <div style={sectionStyle}>Set the mood.</div>
            <div style={innerSectionFooter}>
              <FlatButton
                label={'Select Now'}
                labelPosition={'before'}
                labelStyle={this.props.styles.WelcomeScreenButtonText}
                style={{ height: '64px' }}
                primary={true}
                onClick={() => {
                  this.props.setSelectedMenuItemByName('Lighting')
                }}
                icon={<ChevronRight style={{ height: '64px', width: '64px' }} color={this.props.styles.Color.White} />}
              />
            </div>
          </div>
          <div style={{ ...smallBottomSectionStyle, marginTop: Padding.Medium }}>
            <div style={sectionStyle}>Track Your Flight Now</div>
            <div style={innerSectionStyle}>Letting you know if anything changes with your flight.</div>
            <div style={innerSectionFooter}>

              <FlatButton
                label={'Get Started'}
                labelPosition={'before'}
                labelStyle={{ ...this.props.styles.WelcomeScreenButtonText, color: this.props.styles.Color.White }}
                style={{ height: '64px' }}
                primary={true}
                onClick={() => {
                  this.props.setSelectedMenuItemByName('Flight Status')
                }}
                icon={<ChevronRight style={{ height: '64px', width: '64px' }} color={this.props.styles.Color.White} />}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    config: state.configState.conf,
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(InternalHomeScreen)
