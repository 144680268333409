import React from 'react'

const internalStyles = {
  container: {
    paddingBottom: 30,
    borderBottomWidth: 1,
  },
  textContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const TopComponent = ({ styles }) => {
  return (
    <div style={internalStyles.container}>
      <div style={internalStyles.textContainer}>
        <div style={{ ...styles.InfoText, textAlign: 'center' }}>
          <div>{`Congratulations!`}</div>
          <div style={styles.InfoTextBold}>{` You should receive a\ncode shortly via SMS`}</div>
          <div>{` that you can enter\nbelow to unlock your Jabbrbox.`}</div>
        </div>
      </div>
    </div>
  )
}

export default TopComponent
