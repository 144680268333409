import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import FlatButton from 'material-ui/FlatButton'
import { Padding } from '../../styles/style.format'
import Keyboard from '../../keyboard/Keyboard'
import ButtonGroup from '../ButtonGroup'
import { Margin } from '../../styles/padding.margin'
import { FLIGHT_SEARCH_OPTIONS } from '../../actions/flight.status'

class FlightSearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      renderKeyboard: false,
      revertFlightSearchState: true,
      flightTomorrow: false,
    }
    this.renderKeyboard = this.renderKeyboard.bind(this)
  }

  componentWillUnmount() {
    this.state.revertFlightSearchState && this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.FLIGHT_SEARCH)
  }

  componentDidMount() {
    this.readySearchInput()
  }

  readySearchInput() {
    this.inputNode.focus()
    this.setState({
      renderKeyboard: true,
    })
  }

  renderKeyboard() {
    return <div style={{ display: 'block' }} className="internal-keyboard"><Keyboard
      type={'text'}
      inputNode={this.state.inputNode}
      handleSubmitButton={() => {
        this.setState({
          revertFlightSearchState: false,
        }, () => {
          //This needs to be worked on. We will make the call even if we know its not going to work
          const flightIdentifier = this.inputNode.value.replace(/\s+/g, '').match(/[a-z]+|[^a-z]+/gi) || []
          const airline = flightIdentifier[0]
          const flightNumber = flightIdentifier[1]
          this.props.getFlightStatus(airline, flightNumber, this.state.flightTomorrow).then(() => {
            if (this.props.flightStatus.error) {
              this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.RESULTS)
            } else {
              Promise.all([
                this.props.getAllWeatherByAirportCode(this.props.flightStatus[0].departureAirportCode),
                this.props.getAllWeatherByAirportCode(this.props.flightStatus[0].arrivalAirportCode),
              ]).then(() => {
                this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.RESULTS)
              })
            }
          })
        })
      }}
      isFirstLetterUppercase={this.props.isFirstLetterUppercase}
      submitText={this.props.submitText}
      isSubmitEnabled={this.props.isSubmitEnabled}
    /></div>
  }

  updateInputNode(inputNode) {
    if (inputNode && !this.state.inputNode) {
      this.setState(inputNode)
    }
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: Margin.XXLarge }}>
            <div style={{ ...this.props.styles.FlightSearchHeaderText, marginRight: Margin.XSmall }} >
              Enter Flight Number
            </div>
            <div>
              <FlatButton
                style={{ ...this.props.styles.SecondaryButton, paddingLeft: Padding.Small, paddingRight: Padding.Small }}
                label={'SCAN TICKET'}
                onClick={() => {
                  this.setState({
                    revertFlightSearchState: false,
                  }, () => {
                    this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.TICKET_SCAN)
                  })
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }} >
            <div style={{ ...this.props.styles.InternalText, marginBottom: Margin.XXSmall }}>Flight Number</div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: '20px' }}>
                <input style={{ textAlign: 'center', fontSize: '14pt', height: '42px', borderColor: '#ffd938', borderStyle: 'solid', borderWidth: '2px', borderRadius: '5px', fontFamily: 'Poppins-Bold', textTransform: 'Uppercase' }} placeholder="ex. UA 4521"
                  ref={input => {
                    if (!this.state.inputNode) {
                      this.inputNode = input
                      this.updateInputNode({ inputNode: input })
                    }
                  }} />
              </div>
              <ButtonGroup buttons={[
                {
                  label: 'Today',
                  onClick: () => this.setState({
                    flightTomorrow: false,
                  }),
                },
                {
                  label: 'Tomorrow',
                  onClick: () => this.setState({
                    flightTomorrow: true,
                  }),
                }]} />
            </div>
          </div>
          {this.state.renderKeyboard && this.renderKeyboard()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    flightStatus: state.FlightStatus.flightStatus,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(FlightSearchInput)
