import React, { Component } from 'react'
import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import FeedbackAnswerList from './feedbackScreen/FeedbackAnswerList'
import PropTypes from 'prop-types'
import { Margin, Padding } from '../styles/style.format'
import { FlatButton } from 'material-ui'

class InternalFeedbackScreen extends Component {

  constructor(props) {
    super(props)

    this.state = {
      questionsAnswered: false,
      questionAnswers: [],
    }

    this.handleAddAnswer = this.handleAddAnswer.bind(this)
    this.handleRemoveAnswer = this.handleRemoveAnswer.bind(this)
    this.handleFeedbackSubmit = this.handleFeedbackSubmit.bind(this)
    this.isSubmitDisabled = this.isSubmitDisabled.bind(this)
    this.getOrderedFeebackQueastions = this.getOrderedFeebackQueastions.bind(this)
  }

  componentWillMount() {
    this.props.loadFeedbackQuestions()
  }

  handleAddAnswer(selectedAnswer, isMultiSelect) {
    if (isMultiSelect) {
      const answers = this.state.questionAnswers.filter(answer => {
        return selectedAnswer.id !== answer.id
      })
      answers.push(selectedAnswer)
      this.setState({ questionAnswers: answers })
    } else {
      const answers = this.state.questionAnswers.filter(answer => {
        return selectedAnswer.feedbackQuestionId !== answer.feedbackQuestionId
      })
      answers.push(selectedAnswer)
      this.setState({ questionAnswers: answers })
    }
  }

  handleRemoveAnswer(selectedAnswer, isMultiSelect) {
    if (isMultiSelect) {
      const currentAnswers = this.state.questionAnswers
      const newAnswers = currentAnswers.filter(answer => {
        return selectedAnswer.id !== answer.id
      })
      this.setState({ questionAnswers: newAnswers })
    } else {
      const currentAnswers = this.state.questionAnswers
      const newAnswers = currentAnswers.filter(answer => {
        return selectedAnswer.feedbackQuestionId !== answer.feedbackQuestionId
      })
      this.setState({ questionAnswers: newAnswers })
    }
  }

  handleFeedbackSubmit() {
    this.state.questionAnswers.forEach(answer => {
      const newFeedback = {
        locationId: this.props.location.id,
        jabbrrboxReservationId: this.props.jabbrrboxReservation.id,
        feedbackQuestionId: answer.feedbackQuestionId,
        feedbackAnswerId: answer.id,
      }
      this.props.addJabbrrboxFeedback(newFeedback)
    })
  }

  isSubmitDisabled() {
    let isDisabled = true
    if (this.props.feedbackQuestions) {
      const feedbackQuestionIds = this.props.feedbackQuestions.map(feedbackQuestion => feedbackQuestion.id)
      const questionAnswerIds = this.state.questionAnswers.map(questionAnswer => questionAnswer.feedbackQuestionId)
      const nonAnsweredQuestions = _.difference(feedbackQuestionIds, questionAnswerIds)
      if (nonAnsweredQuestions.length === 0) {
        isDisabled = false
      } else {
        isDisabled = true
      }
    }
    return isDisabled
  }

  getOrderedFeebackQueastions() {
    return _.orderBy(this.props.feedbackQuestions, ['order'])
  }

  render() {
    return (
      <div style={{
        marginTop: Margin.XLarge,
        marginRight: Margin.XXLarge,
        marginBottom: Margin.XLarge,
        marginLeft: Margin.XXLarge,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
      }}>
        {!this.props.hasSubmitted &&
          <div style={{ ...this.props.styles.InternalText }}>
            {this.props.feedbackQuestions && this.getOrderedFeebackQueastions().map(question => {
              return (
                <div key={question.id} style={{ marginBottom: Margin.Medium }}>
                  <div style={{ ...this.props.styles.FeedbackQuestionTitle, marginBottom: Margin.XSmall }}>{question.name}</div>
                  {question.helperText && <div style={{ marginBottom: Margin.XSmall }}>{question.helperText}</div>}
                  <FeedbackAnswerList
                    question={question}
                    onAddAnswer={this.handleAddAnswer}
                    onRemoveAnswer={this.handleRemoveAnswer}
                    selectedAnswers={this.state.questionAnswers}
                  />
                </div>
              )
            })}
            <FlatButton
              label={'Submit'}
              labelPosition={'before'}
              labelStyle={this.props.styles.FeedbackButtonLabel}
              style={this.isSubmitDisabled() ? this.props.styles.FeedbackSubmitDisabledButton : this.props.styles.FeedbackSubmitButton}
              primary={true}
              disabled={this.isSubmitDisabled()}
              onClick={() => this.handleFeedbackSubmit()}
            />
          </div>
        }
        {this.props.hasSubmitted &&
          <div style={{
            display: 'flex', justifyContent: 'center', flexDirection: 'column', flexGrow: 1, width: '80%',
            marginLeft: Margin.XXLarge, marginRight: Margin.XXLarge, marginBottom: Margin.XXXLarge,
          }}>
            <div style={{ ...this.props.styles.InternalSubHeader, width: '100%', marginBottom: Margin.Small }}>
              Thank you for your input, you've been registered for a free gift.
            </div>
            <div>
              <FlatButton
                style={{
                  ...this.props.styles.PrimaryButton,
                  paddingLeft: Padding.Small, paddingRight: Padding.Small,
                }}
                label={'GO HOME'}
                labelPosition="before"
                primary={true}
                onClick={() => {
                  this.props.setSelectedMenuItemByName('Home')
                }}
              />
            </div>
          </div>
        }

      </div>
    )
  }
}

InternalFeedbackScreen.propTypes = {
  styles: PropTypes.object,
  feedbackQuestions: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    feedbackQuestions: state.jabbrrboxFeedback.feedbackQuestions,
    hasSubmitted: state.jabbrrboxFeedback.hasSubmitted,
    jabbrrboxReservation: state.doorState.jabbrrboxReservation,
    location: state.doorState.jabbrrbox.location,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(InternalFeedbackScreen)
