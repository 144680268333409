import React, { Component } from 'react'
import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { MainMenu } from './shared/menu/MainMenu'
import CountdownComponent from './countdownClock/CountdownComponent'
import { Padding, Margin } from '../styles/style.format'
import InternalColorScreen from './InternalColorScreen'
import InternalHomeScreen from './InternalHomeScreen'
import InternalWifiScreen from './InternalWifiScreen'
import InternalPhotoBooth from './InternalPhotoBooth'
import InternalFeedbackScreen from './InternalFeedbackScreen'
import InternalSupportScreen from './InternalSupportScreen'
import InternalLocationScreen from './InternalLocationScreen'
import InternalFlightStatusScreen from './InternalFlightStatusScreen'


class InternalScreen extends Component {
  renderChildPage(menuItem) {
    if (!menuItem) {
      return <div />
    }
    switch (menuItem.name) {
      case 'Lighting':
        return <InternalColorScreen />
      case 'WiFi':
        return <InternalWifiScreen styles={this.props.styles} reservation={this.props.reservation} />
      case 'Home':
        return <InternalHomeScreen />
      case 'Photobooth':
        return <InternalPhotoBooth styles={this.props.styles} />
      case 'Location':
        return <InternalLocationScreen styles={this.props.styles} config={this.props.config} />
      case 'Support':
        return <InternalSupportScreen styles={this.props.styles} />
      case 'Flight Status':
        return <InternalFlightStatusScreen styles={this.props.styles} />
      case 'Survey':
        return <InternalFeedbackScreen styles={this.props.styles} />
      default:
        return <div />
    }
  }

  render() {
    return (
      <div style={{
        flexDirection: 'column', flex: 1, display: 'flex',
        alignItems: 'stretch', alignContent: 'stretch',
        width: '100%', height: '100%', overflowY: 'hidden',
        backgroundColor: this.props.styles.Color.NeutralDark,
      }}>
        <div style={{ flexDirection: 'row', flex: 1, display: 'flex' }}>
          <MainMenu
            menuItems={this.props.menuItems}
            selectedMenuItem={this.props.selectedMenuItem}
            styles={this.props.styles}
            onItemSelected={item => {
              this.props.setSelectedMenuItemByName(item.name)
            }} />
          <div style={{ display: 'flex', flex: 4, flexDirection: 'column' }}>
            <div style={this.props.styles.InteralHeaderContainer}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {this.props && this.props.selectedMenuItem && this.props.selectedMenuItem.name &&
                  <div
                    style={{
                      ...this.props.styles.PageTitle,
                      marginLeft: Padding.XXLarge,
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {this.props.selectedMenuItem.header || this.props.selectedMenuItem.name}
                  </div>
                }
                {this.props && this.props.selectedMenuItem && this.props.selectedMenuItem.subheader &&
                  <div style={{ ...this.props.styles.InternalText, marginLeft: Padding.XXLarge, marginBottom: Margin.XXSmall }}>
                    {this.props.selectedMenuItem.subheader}
                  </div>
                }
              </div>
              <CountdownComponent />
            </div>
            {this.renderChildPage(this.props.selectedMenuItem)}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    menuItems: state.Menu.menuItems,
    selectedMenuItem: state.Menu.selectedMenuItem,
    reservation: state.doorState.jabbrrboxReservation,
    styles: state.Style.theme,
    config: state.configState.conf,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(InternalScreen)
