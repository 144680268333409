import { get, patch } from '../util/services'
import * as doorActions from '../actions/door'
import moment from 'moment'
import { logEvent } from './event.log'

export const EXECUTE_REMOTE_ACTIONS_STARTED = 'EXECUTE_REMOTE_ACTIONS_STARTED'
export const EXECUTE_REMOTE_ACTIONS_STOPPED = 'EXECUTE_REMOTE_ACTIONS_STOPPED'
export const EXECUTE_REMOTE_ACTIONS_ERROR = 'EXECUTE_REMOTE_ACTIONS_ERROR'

export const executeRemoteActionsStarted = () => ({ type: EXECUTE_REMOTE_ACTIONS_STARTED })
export const executeRemoteActionsStopped = remoteActions => ({ type: EXECUTE_REMOTE_ACTIONS_STOPPED, payload: remoteActions })
export const executeRemoteActionsError = error => ({ type: EXECUTE_REMOTE_ACTIONS_ERROR, payload: error })

const actionMap = {
  unlockDoor: doorActions.unlockDoor,
}

const getActionPromise = (action, dispatch, getState) => {
  const promise = new Promise((resolve, reject) => {
    if (actionMap[action.actionType]) {
      actionMap[action.actionType]()(dispatch)
      resolve(action.id)
    } else {
      reject(new Error(`${action.actionType} is not a valid action type.`))
    }
  }).then(actionId => {
    const updatedRemoteAction = { datetimeCompleted: moment().toISOString() }
    patch(`/jabbrrboxRemoteActions/${actionId}`, getState(), updatedRemoteAction)
  }).catch(error => {
    const now = moment().toISOString()
    const updatedRemoteAction = { datetimeCompleted: now }
    patch(`/jabbrrboxRemoteActions/${action.id}`, getState(), updatedRemoteAction)
    const event = {
      type: EXECUTE_REMOTE_ACTIONS_ERROR,
      data: `Action Type: ${action.actionType} | ID: ${action.id} failed. ${error.message}`,
      datetime: now,
    }
    logEvent(event)(dispatch)
  })
  return promise
}

export const executeRemoteActions = jabbrrboxId => {
  return (dispatch, getState) => {
    dispatch(executeRemoteActionsStarted())
    const time = new Date().toISOString()
    const filter = {
      where: {
        jabbrrboxId,
        datetimeCompleted: null,
        datetimeCancelled: null,
        datetimeIssued: { lt: time },
        and: [{
          or: [{
            datetimeExpiration: { gt: time },
          }, {
            datetimeExpiration: null,
          },
          ],
        }],
      },
    }
    return get(`/jabbrrboxRemoteActions?filter=${JSON.stringify(filter)}`, getState())
      .then(data => {
        const promises = []
        data.forEach(action => {
          promises.push(getActionPromise(action, dispatch, getState))
        })

        Promise.all(promises)
          .then(() => {
            dispatch(executeRemoteActionsStopped(data))
          })
          .catch(error => {
            dispatch(executeRemoteActionsError(error))
          })
      })
      .catch(error => {
        dispatch(executeRemoteActionsError(error))
      })
  }
}
