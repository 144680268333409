import React, { Component } from 'react'
import moment from 'moment'

const clockIconUrl = `${process.env.PUBLIC_URL}/img/icons/countdown/clock.svg`

class CurrentTimeComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentTime: null,
      currentAmPm: null,
    }

    this.setInitialCountdown = this.setInitialCountdown.bind(this)
    this.startCountdown = this.startCountdown.bind(this)
  }

  componentWillMount() {
    this.setInitialCountdown()
  }

  componentDidMount() {
    this.startCountdown()
  }

  setInitialCountdown() {
    const currentTime = moment().format('h:mm')
    const currentAmPm = moment().format('A')
    this.setState({ currentTime })
    this.setState({ currentAmPm })
  }

  startCountdown() {
    setInterval(() => {
      const currentTime = moment().format('h:mm')
      const currentAmPm = moment().format('A')
      this.setState({ currentTime, currentAmPm })
    }, 1000)
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '50%', direction: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div>
          <img
            src={clockIconUrl}
            alt="Clock Icon"
            style={this.props.styles.CountdownIcon}
          />
        </div>
        <div style={this.props.styles.CurrentTimeContainer}>
          <div style={this.props.styles.CountdownTitle}>Current Time</div>
          <div
            style={this.props.styles.CountdownValue}>
            {this.state.currentTime}
            <span style={{ ...this.props.styles.CountdownLabel, marginLeft: 8 }}>{this.state.currentAmPm}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default CurrentTimeComponent
