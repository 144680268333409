import * as colorPickerActions from '../actions/color.picker'

const INITIAL_STATE = {
  isLoading: false,
  presets: [],
  error: undefined,
}

export const colorPicker = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case colorPickerActions.LOAD_COLOR_PICKER_PRESETS_STARTED:
      return { ...state, isLoading: true }
    case colorPickerActions.LOAD_COLOR_PICKER_PRESETS_STOPPED:
      return { ...state, isLoading: false, presets: payload }
    case colorPickerActions.LOAD_COLOR_PICKER_PRESETS_ERROR:
      return { ...state, isLoading: false, error: payload }
    default:
      return state
  }
}
