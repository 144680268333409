import { create } from '../util/services'
import * as GoogleAnalytics from './google.analytics'
import moment from 'moment'

export const START_PAYMENT_RECEIPT_REQUEST = 'START_PAYMENT_RECEIPT_REQUEST'
export const STOP_PAYMENT_RECEIPT_REQUEST = 'STOP_PAYMENT_RECEIPT_REQUEST'
export const PAYMENT_RECEIPT_REQUEST_ERROR = 'PAYMENT_RECEIPT_REQUEST_ERROR'

export const startPaymentReceiptRequest = () => ({ type: START_PAYMENT_RECEIPT_REQUEST })
export const stopPaymentReceiptRequest = paymentReceipt => ({ type: STOP_PAYMENT_RECEIPT_REQUEST, payload: paymentReceipt })
export const paymentReceiptRequestError = error => ({ type: PAYMENT_RECEIPT_REQUEST_ERROR, payload: error })
export const createPaymentReceipt = (jabbrrboxReservationId, receipt) => {
  return (dispatch, getState) => {

    const invoice = receipt.TranResponse[0].InvoiceNo[0]
    const subtotal = receipt.TranResponse[0].Amount[0].Purchase[0]
    const tax = ''
    const datetimePayed = moment(`${receipt.TranResponse[0].Date[0]} ${receipt.TranResponse[0].Time[0]}`).toISOString()
    const cardType = receipt.TranResponse[0].CardType[0]
    const paymentType = receipt.TranResponse[0].EntryMethod[0]

    const paymentReceipt = {
      jabbrrboxReservationId,
      invoice,
      subtotal,
      tax,
      datetimePayed,
      cardType,
      paymentType,
    }
    dispatch(GoogleAnalytics.logPurchase(invoice, subtotal, 0, 0, 0))
    dispatch(startPaymentReceiptRequest())
    create(`/paymentReceipts`, getState(), paymentReceipt)
      .then(() => {
        dispatch(stopPaymentReceiptRequest())
      })
      .catch(error => {
        dispatch(paymentReceiptRequestError(error))
      })
  }
}
