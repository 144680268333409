import * as Navigation from './nav'
import * as Door from './door'
import * as Payment from './payment'
import { create } from '../util/services'

export const MOVE_SIGNUP_FORWARD = 'MOVE_SIGNUP_FORWARD'
export const CLEAR_SIGN_UP = 'CLEAR_SIGN_UP'

export const RESERVATION_REQUEST_ERROR = 'RESERVATION_REQUEST_ERROR'
export const STOP_RESERVATION_REQUEST = 'STOP_RESERVATION_REQUEST'
export const START_RESERVATION_REQUEST = 'START_RESERVATION_REQUEST'

export const SIGNUP_REQUEST_ERROR = 'SIGNUP_REQUEST_ERROR'
export const STOP_SIGNUP_REQUEST = 'STOP_SIGNUP_REQUEST'
export const START_SIGNUP_REQUEST = 'START_SIGNUP_REQUEST'
export const goToNextSection = section => ({ type: MOVE_SIGNUP_FORWARD, payload: section })

export const SET_SIGNUP_COUNTRY = 'SET_SIGNUP_COUNTRY'
export const setSignupCountry = country => ({ type: SET_SIGNUP_COUNTRY, payload: country })
export const SET_SIGNUP_PHONE_NUMBER = 'SET_SIGNUP_PHONE_NUMBER'
export const setSignupPhoneNumber = phoneNumber => ({ type: SET_SIGNUP_PHONE_NUMBER, payload: phoneNumber })
export const SET_SIGNUP_EMAIL = 'SET_SIGNUP_EMAIL'
export const setSignupEmail = email => ({ type: SET_SIGNUP_EMAIL, payload: email })

export const startReservationRequest = () => ({ type: START_RESERVATION_REQUEST })
export const stopReservationRequest = payload => ({ type: STOP_RESERVATION_REQUEST, payload })
export const reservationRequestError = error => ({ type: RESERVATION_REQUEST_ERROR, payload: error })
export const makeReservation = jabbrrboxReservation => {
  return (dispatch, getState) => {
    dispatch(startReservationRequest())
    return create(`/jabbrrboxReservations`, getState(), jabbrrboxReservation).then(data => {
      if (data.accessCode) {
        dispatch(Door.unlockDoor())
        dispatch(Navigation.navigate('DoorOpen'))
      }
      return Door.getReservations(data.jabbrrboxId)(dispatch, getState).then(() => {
        dispatch(stopReservationRequest(data))
        return data
      })
    }).catch(err => {
      return dispatch(reservationRequestError(err))
    })
  }
}

export const clearSignUp = () => ({ type: CLEAR_SIGN_UP })
export const startSignUpRequest = () => ({ type: START_SIGNUP_REQUEST })
export const stopSignUpRequest = payload => ({ type: STOP_SIGNUP_REQUEST, payload })
export const signUpRequestError = error => ({ type: SIGNUP_REQUEST_ERROR, payload: error })
export const signUp = userInfo => {
  return (dispatch, getState) => {
    dispatch(startSignUpRequest())
    return create(`/people`, getState(), userInfo).then(data => {
      dispatch(stopSignUpRequest(data))
      return data
    }).catch(err => {
      dispatch(signUpRequestError(err))
    })
  }
}

export const signUpAndMakeReservation = (userInfo, jabbrrboxId, selectedPriceOption, partnerId) => {
  return (dispatch, getState) => {
    return dispatch(signUp(userInfo)).then(person => {
      Payment.setupReservationAndPayment(
        jabbrrboxId, person.id,
        selectedPriceOption || { pricingPlan: {} },
        partnerId
      )(dispatch, getState)
    }).catch(err => {
      return dispatch(signUpRequestError(err))
    })
  }
}

//TODO: Make an App actions?
export const SHOW_KEYBOARD = 'SHOW_KEYBOARD'
export const showKeyboard = inputRef => {
  return { type: SHOW_KEYBOARD, payload: inputRef }
}

export const hideKeyboard = () => {
  return showKeyboard(null)
}
