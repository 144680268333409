import { get, create } from '../util/services'

export const START_ADD_JABBRRBOX_FEEDBACK = 'START_ADD_JABBRRBOX_FEEDBACK'
export const STOP_ADD_JABBRRBOX_FEEDBACK = 'STOP_ADD_JABBRRBOX_FEEDBACK'
export const ERROR_ADD_JABBRRBOX_FEEDBACK = 'ERROR_ADD_JABBRRBOX_FEEDBACK'
export const startAddJabbrrboxFeedback = () => ({ type: START_ADD_JABBRRBOX_FEEDBACK })
export const stopAddJabbrrboxFeedback = payload => ({ type: STOP_ADD_JABBRRBOX_FEEDBACK, payload })
export const errorAddJabbrrboxFeedback = error => ({ type: ERROR_ADD_JABBRRBOX_FEEDBACK, payload: error })
export const addJabbrrboxFeedback = feedback => {
  return (dispatch, getState) => {
    dispatch(startAddJabbrrboxFeedback())
    return create(`/jabbrrboxFeedback`, getState(), feedback)
      .then(() => {
        dispatch(stopAddJabbrrboxFeedback())
      })
      .catch(error => {
        dispatch(errorAddJabbrrboxFeedback(error))
      })
  }
}

export const RESET_ADD_JABBRRBOX_FEEDBACK = 'RESET_ADD_JABBRRBOX_FEEDBACK'
export const resetAddJabbrrboxFeedback = () => ({ type: RESET_ADD_JABBRRBOX_FEEDBACK })

export const LOAD_FEEDBACK_QUESTIONS_STARTED = 'LOAD_FEEDBACK_QUESTIONS_STARTED'
export const LOAD_FEEDBACK_QUESTIONS_STOPPED = 'LOAD_FEEDBACK_QUESTIONS_STOPPED'
export const LOAD_FEEDBACK_QUESTIONS_ERROR = 'LOAD_FEEDBACK_QUESTIONS_ERROR'
export const loadFeedbackQuestionsStarted = () => ({ type: LOAD_FEEDBACK_QUESTIONS_STARTED })
export const loadFeedbackQuestionsStopped = feedbackQuestions => ({ type: LOAD_FEEDBACK_QUESTIONS_STOPPED, payload: feedbackQuestions })
export const loadFeedbackQuestionsError = error => ({ type: LOAD_FEEDBACK_QUESTIONS_ERROR, payload: error })
export const loadFeedbackQuestions = (isActive = true) => {
  return (dispatch, getState) => {
    dispatch(loadFeedbackQuestionsStarted())
    return get(`/feedbackQuestions/?filter[where][isActive]=${isActive}&filter[include]=feedbackAnswers`, getState())
      .then(data => {
        dispatch(loadFeedbackQuestionsStopped(data))
      })
      .catch(error => {
        dispatch(loadFeedbackQuestionsError(error))
      })
  }
}
