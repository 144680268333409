import * as Navigation from './nav'
import { create, get, update } from '../util/services'
import { loadColorPickerPresets } from './color.picker'
import { loadJabbrrboxPricingPlans } from './jabbrrbox.pricing.plan'
import { loadLocationPricingPlans } from './location.pricing.plan'

export const CODE_ENTERED = 'CODE_ENTERED'
export const DOOR_IS_OPENED = 'DOOR_OPENED'
export const DOOR_IS_LOCKED = 'DOOR_LOCKED'
export const DOOR_IS_UNLOCKED = 'DOOR_UNLOCKED'
export const DOOR_REQUEST_OPEN_STARTED = 'DOOR_REQUEST_OPEN_STARTED'
export const DOOR_REQUEST_OPEN_STOPPED = 'DOOR_REQUEST_OPEN_STOPPED'
export const DOOR_REQUEST_OPEN_ERROR = 'DOOR_REQUEST_OPEN_ERROR'

export const DOOR_REQUEST_LOCK_STARTED = 'DOOR_REQUEST_LOCK_STARTED'
export const DOOR_REQUEST_LOCK_STOPPED = 'DOOR_REQUEST_LOCK_STOPPED'
export const DOOR_REQUEST_LOCK_ERROR = 'DOOR_REQUEST_LOCK_ERROR'

export const CLEAR_DOOR_REQUEST_OPEN_ERROR = 'CLEAR_DOOR_REQUEST_OPEN_ERROR'
export const RESERVATION_REQUEST_STARTED = 'RESERVATION_REQUEST_STARTED'
export const RESERVATION_REQUEST_STOPPED = 'RESERVATION_REQUEST_STOPPED'
export const RESERVATION_REQUEST_ERROR = 'RESERVATION_REQUEST_ERROR'
export const LOAD_JABBRRBOX_REQUEST_STARTED = 'LOAD_JABBRRBOX_REQUEST_STARTED'
export const LOAD_JABBRRBOX_REQUEST_STOPPED = 'LOAD_JABBRRBOX_REQUEST_STOPPED'
export const LOAD_JABBRRBOX_REQUEST_ERROR = 'LOAD_JABBRRBOX_REQUEST_ERROR'

export const addCode = code => ({ type: CODE_ENTERED, payload: code })
export const openDoorRequestStarted = () => ({ type: DOOR_REQUEST_OPEN_STARTED })
export const openDoorRequestStopped = () => ({ type: DOOR_REQUEST_OPEN_STOPPED })
export const doorIsOpenned = data => ({ type: DOOR_IS_OPENED, payload: data })
export const doorIsLocked = () => ({ type: DOOR_IS_LOCKED })
export const doorIsUnlocked = () => ({ type: DOOR_IS_UNLOCKED })

export const lockDoorRequestStarted = () => ({ type: DOOR_REQUEST_LOCK_STARTED })
export const lockDoorRequestStopped = () => ({ type: DOOR_REQUEST_LOCK_STOPPED })
export const lockDoorError = error => ({ type: DOOR_REQUEST_LOCK_ERROR, payload: error })
export const lockDoor = () => {
  return dispatch => {
    dispatch(lockDoorRequestStarted())
    const doLockDoor = window.require ? window.require('electron').remote.getCurrentWindow().lockDoor : null
    if (doLockDoor) {
      doLockDoor(err => {
        dispatch(lockDoorRequestStopped())
        if (err) {
          dispatch(lockDoorError(err))
        } else {
          dispatch(doorIsLocked())
        }
        dispatch(Navigation.navigate('RoomReserved'))
      })
    } else {
      dispatch(lockDoorRequestStopped())
      dispatch(doorIsLocked())
      dispatch(Navigation.navigate('RoomReserved'))
    }
  }
}

export const unlockDoor = () => {
  return dispatch => {
    const doUnlockDoor = window.require ? window.require('electron').remote.getCurrentWindow().unlockDoor : null
    if (doUnlockDoor) {
      doUnlockDoor(err => {
        if (err) {
          dispatch(doorIsLocked())
          throw new Error(err)
        } else {
          dispatch(doorIsUnlocked())
        }
      })
    }
    setTimeout(() => {
      lockDoor()(dispatch)
    }, 5000)
  }
}

export const clearDoorRequestOpenError = () => ({ type: CLEAR_DOOR_REQUEST_OPEN_ERROR })
export const openDoorError = error => ({ type: DOOR_REQUEST_OPEN_ERROR, payload: error })
export const openDoor = (code, jabbrrboxId) => {
  return (dispatch, getState) => {
    dispatch(openDoorRequestStarted())
    dispatch(clearDoorRequestOpenError())
    create(`/jabbrrboxes/${jabbrrboxId}/unlock`, getState(), { code }).then(data => {
      dispatch(openDoorRequestStopped())
      dispatch(doorIsOpenned(data))
      dispatch(Navigation.navigate('DoorOpen'))
      dispatch(unlockDoor())
    }).catch(error => {
      dispatch(openDoorRequestStopped())
      dispatch(openDoorError(error))
    })
  }
}

const setConfigInElectron = () => {
  return (dispatch, getState) => {
    const setServerConfig = window.require ? window.require('electron').remote.getCurrentWindow().setServerConfig : null
    if (setServerConfig) {
      setServerConfig(getState().configState.conf)
    }
  }
}

export const loadJabbrrboxRequestStarted = () => ({ type: LOAD_JABBRRBOX_REQUEST_STARTED })
export const loadJabbrrboxRequestStopped = jabbrrbox => ({ type: LOAD_JABBRRBOX_REQUEST_STOPPED, payload: jabbrrbox })
export const loadJabbrrboxError = error => ({ type: LOAD_JABBRRBOX_REQUEST_ERROR, paylod: error })
export const loadJabbrrboxInfo = jabbrrboxId => {
  return (dispatch, getState) => {
    dispatch(loadJabbrrboxRequestStarted())
    return get(`/jabbrrboxes/${jabbrrboxId}?filter[include]=sponsor&filter[include]=location&filter[include]=jabbrrboxConfig`,
      getState()).then(data => {
      dispatch(loadJabbrrboxRequestStopped(data))
      loadLocationPricingPlans()(dispatch, getState)
      loadJabbrrboxPricingPlans()(dispatch, getState)
      loadColorPickerPresets()(dispatch, getState)
      setConfigInElectron()(dispatch, getState)
    })
      .catch(error => {
        dispatch(loadJabbrrboxError(error))
      }
      )
  }
}


export const reservationRequestStarted = () => ({ type: RESERVATION_REQUEST_STARTED })
export const reservationRequestStopped = reservations => ({ type: RESERVATION_REQUEST_STOPPED, payload: reservations })
export const reservationError = error => ({ type: RESERVATION_REQUEST_ERROR, paylod: error })
export const getReservations = jabbrrboxId => {
  return (dispatch, getState) => {
    dispatch(reservationRequestStarted())
    return get(`/jabbrrboxes/${jabbrrboxId}/jabbrrboxReservations/active`, getState())
      .then(data => {
        dispatch(reservationRequestStopped(data))
      })
      .catch(error => {
        dispatch(reservationError(error))
      })
  }
}

export const CONFIRM_RESERVATION_REQUEST_STARTED = 'CONFIRM_RESERVATION_REQUEST_START'
export const CONFIRM_RESERVATION_REQUEST_STOPPED = 'CONFIRM_RESERVATION_REQUEST_STOPPED'
export const CONFIRM_RESERVATION_REQUEST_ERROR = 'CONFIRM_RESERVATION_REQUEST_ERROR'

export const confirmReservationStarted = () => ({ type: CONFIRM_RESERVATION_REQUEST_STARTED })
export const confirmReservationStopped = confirmation => ({ type: CONFIRM_RESERVATION_REQUEST_STOPPED, payload: confirmation })
export const confirmReservationError = error => ({ type: CONFIRM_RESERVATION_REQUEST_ERROR, payload: error })
export const confirmReservation = (id, confirmationCode) => {
  return (dispatch, getState) => {
    dispatch(confirmReservationStarted())
    return update(`/jabbrrboxReservations/${id}/confirmReservation/${confirmationCode}`, getState())
      .then(data => {
        dispatch(confirmReservationStopped(data))
      })
      .catch(error => {
        dispatch(confirmReservationError(error))
      })
  }
}

export const CANCEL_RESERVATION_STARTED = 'CANCEL_RESERVATION_STARTED'
export const CANCEL_RESERVATION_STOPPED = 'CANCEL_RESERVATION_STOPPED'
export const CANCEL_RESERVATION_ERROR = 'CANCEL_RESERVATION_ERROR'

export const cancelReservationStarted = () => ({ type: CANCEL_RESERVATION_STARTED })
export const cancelReservationStopped = jabbrrboxReservation => ({ type: CANCEL_RESERVATION_STOPPED, payload: jabbrrboxReservation })
export const cancelReservationError = error => ({ type: CANCEL_RESERVATION_ERROR, payload: error })
export const cancelReservation = reservation => {

  return (dispatch, getState) => {
    dispatch(cancelReservationStarted())
    return update(`/jabbrrboxReservations/${reservation.id}/cancel`, getState())
      .then(data => {
        dispatch(cancelReservationStopped(data))
        loadJabbrrboxInfo(reservation.jabbrrboxId)
        dispatch(Navigation.navigate('Welcome'))
      })
      .catch(error => {
        dispatch(cancelReservationError(error))
      })
  }
}

export const RESEND_ACCESS_CODE_STARTED = 'RESEND_ACCESS_CODE_STARTED'
export const RESEND_ACCESS_CODE_STOPPPED = 'RESEND_ACCESS_CODE_STOPPPED'
export const RESEND_ACCESS_CODE_ERROR = 'RESEND_ACCESS_CODE_ERROR'
export const resendAccessCodeStarted = () => ({ type: RESEND_ACCESS_CODE_STARTED })
export const resendAccessCodeStopped = response => ({ type: RESEND_ACCESS_CODE_STARTED, payload: response })
export const resendAccessCodeError = error => ({ type: RESEND_ACCESS_CODE_STARTED, payload: error })
export const resendCode = jabbrrboxReservationId => {
  return (dispatch, getState) => {
    dispatch(resendAccessCodeStarted())
    create(`/jabbrrboxReservations/${jabbrrboxReservationId}/resendCode`, getState())
      .then(data => {
        dispatch(resendAccessCodeStopped(data))
      })
      .catch(error => {
        dispatch(resendAccessCodeError(error))
      })
  }
}
