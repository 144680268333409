import { get } from '../util/services'

export const LOAD_MARKETING_CARD_STARTED = 'LOAD_MARKETING_CARD_STARTED'
export const LOAD_MARKETING_CARD_STOPPED = 'LOAD_MARKETING_CARD_STOPPED'
export const LOAD_MARKETING_CARD_ERROR = 'LOAD_MARKETING_CARD_ERROR'

export const loadMarketingCardStarted = () => ({ type: LOAD_MARKETING_CARD_STARTED })
export const loadMarketingCardStopped = marketingCards => ({ type: LOAD_MARKETING_CARD_STOPPED, payload: marketingCards })
export const loadMarketingCardError = error => ({ type: LOAD_MARKETING_CARD_ERROR, payload: error })
export const loadMarketingCards = () => {
  return (dispatch, getState) => {
    dispatch(loadMarketingCardStarted())
    return get(`/marketingCards`, getState())
            .then(data => {
              dispatch(loadMarketingCardStopped(data))
            })
            .catch(error => {
              dispatch(loadMarketingCardError(error))
            })
  }
}
