import React, { Component } from 'react'
import PhotoImageList from './photoBooth/PhotoImageList'
import CameraAlt from 'material-ui/svg-icons/image/camera-alt'
import IconButton from 'material-ui/IconButton'
import FlatButton from 'material-ui/FlatButton'
import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Padding, Margin } from '../styles/style.format'
import hexToRgba from 'hex-rgba'
import jquery from 'jquery'

const TRANSITION_TIME = 1000
const iStyles = {
  barStyle: {
    marginTop: Margin.XSmall,
    marginBottom: Margin.XSmall,
  },
  button: {
    borderRadius: '50%',
    backgroundColor: 'white',
    marginTop: Margin.XSmall,
    marginBottom: Margin.XSmall,
  },
  countdown: {
    image: {
      borderRadius: '50%',
      width: 24,
      height: 24,
      color: 'black',
      padding: 12,
    },
  },
}

const defaultColorPickerColor = {
  r: 255,
  g: 255,
  b: 255,
  brightness: 1,
}

let watermarkImage
const createWatermark = () => {
  watermarkImage = new Image()
  watermarkImage.src = `${process.env.PUBLIC_URL}/img/jabbrrbox_logo.svg`
}
const canvas = document.createElement('canvas')
const context = canvas.getContext('2d')
canvas.width = 300
canvas.height = 860
const photoHeight = 140
const photoWidth = 250
let pheight = 20
context.fillStyle = 'white'
context.fillRect(0, 0, canvas.width, canvas.height)

const gifcanvas = document.createElement('canvas')
gifcanvas.width = 1385
gifcanvas.height = 978
const gifcontext = gifcanvas.getContext('2d')
gifcontext.fillStyle = 'white'
gifcontext.fillRect(0, 0, gifcanvas.width, gifcanvas.height)
class InternalPhotoBooth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photos: [],
      countdown: undefined,
      showTimer: false,
      processedPhoto: undefined,
      shot: 0,
      gifPhotos: [],
      gifDataUrl: undefined,
    }
    createWatermark()
    this.props.updateLight(this.props.config.lights.photoBooth.id, { ...this.props.config.lights.photoBooth.takePhotoColor })
  }

  sendPhotoStripAndGif() {
    this.props.sendPhotoStripAndGif(this.props.reservation, this.state.processedPhoto, this.state.gifPhotos, this.props.tag)
  }

  getBarDisplay() {
    const countdown = this.state.countdown
    const mainStyle = { ...this.props.styles.InternalSubHeader, ...iStyles.barStyle }
    let display

    if (countdown === undefined) {
      const shotDisplay = this.state.shot < 6 ? this.state.shot : '...Sending'
      display = this.state.shot > 0 ? <div style={mainStyle}>{shotDisplay}</div> : <IconButton style={iStyles.button}
        onClick={this.pauseAndTakePicture.bind(this)}><CameraAlt /></IconButton>

    } else if (countdown > 1) {
      const num = countdown - 1
      display = <div style={{ ...mainStyle, color: this.props.styles.Color.BrandPrimary }}>{num}</div>
    } else {
      display = <CameraAlt style={{ ...mainStyle, backgroundColor: this.props.styles.Color.BrandPrimary, ...iStyles.countdown.image }} />
    }
    return <div style={{ display: 'flex', alignItems: 'center' }}>
      {display}
    </div>
  }
  photosSentDisplay() {
    const photoSent = this.props.person ? this.props.person.email : 'No Email'
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', flexDirection: 'column', flexGrow: 1, width: '80%',
        marginLeft: Margin.XXLarge, marginRight: Margin.XXLarge, marginBottom: Margin.XXXLarge,
      }}>
        <div style={{
          ...this.props.styles.InternalSubHeader,
          width: '100%', marginBottom: Margin.Small,
        }}>
          Your photos have been sent to {photoSent}.
        </div>
        <div>
          <FlatButton
            style={{
              ...this.props.styles.PrimaryButton,
              paddingLeft: Padding.Small, paddingRight: Padding.Small,
            }}
            label={'GO HOME'}
            labelPosition="before"
            primary={true}
            onClick={() => {
              this.props.setSelectedMenuItemByName('Home')
            }}
          />
        </div>
      </div>
    )
  }

  render() {
    if (this.state.processedPhoto) {
      this.cleanup()
      return this.photosSentDisplay()
    }
    const email = this.props.person ? this.props.person.email : 'NO EMAIL AVAILABLE'
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: Margin.XLarge, marginLeft: Margin.XXLarge }}>
        <div style={{ height: '768px', width: 1365, backgroundColor: this.props.styles.Color.Neutral }}>
          <div style={{ position: 'relative', height: '100%' }}>
            <video
              ref={ref => {
                this.video = ref
              }}
              style={{ height: '100%' }}

            >
              Video Stream Not Available</video>
            <div className="flash" />
            <div className="countdown-bar" style={{ backgroundColor: hexToRgba(this.props.styles.Color.NeutralExtraDark, 75), height: 90 }}>
              {this.getBarDisplay()}
            </div>

          </div>
        </div>
        <PhotoImageList photos={this.state.photos} />
        <div style={{ ...this.props.styles.InternalText, marginTop: Margin.Small, width: '75%' }}>
          Results will be sent to {email}!</div>
      </div>)
  }
  pauseAndTakePicture() {
    this.setState({ showTimer: true })
    setTimeout(() => {
      this.setState({ countdown: 4, shot: 1 })
      this.startCountdown()
    }, TRANSITION_TIME)

  }
  startCountdown() {
    setTimeout(() => {
      if (this.state.countdown > 0) {
        this.setState(prevState => {
          return { countdown: prevState.countdown - 1 }
        }, () => {
          this.startCountdown()
        })
      } else {

        this.setState({ countdown: undefined })
        this.setState({ showTimer: false })
        setTimeout(() => this.flashTakePicture(), 500)
      }
    }, 1000)
  }
  flashTakePicture() {
    setTimeout(() => {
      this.takePicture()
    }, 500)
  }

  processImages() {
    context.font = this.props.styles.PhotoBoothImageTag
    context.fillStyle = 'black'
    if (this.props.tag) {
      context.fillText(this.props.tag, 25, pheight + 16)
    }
    context.drawImage(watermarkImage, 175, pheight, 100, 20)
    const data = canvas.toDataURL('image/png')
    pheight = 20
    context.fillStyle = 'white'
    context.fillRect(0, 0, canvas.width, canvas.height)
    this.setState({ shot: 0 })
    this.setState(() => ({ processedPhoto: data }), () => {
      this.sendPhotoStripAndGif()
    })
  }
  takePicture() {
    jquery('.flash').show() //show the hidden div
      .animate({ opacity: 0.5 }, 300)
      .fadeOut(300)
      .css({ opacity: 1 })
    context.drawImage(this.video, 25, pheight, photoWidth, photoHeight)
    gifcontext.drawImage(this.video, 10, 90, 1365, 768)
    gifcontext.drawImage(watermarkImage, 536.5, 10, 292, 69)
    pheight += photoHeight + 20
    this.setState(prevState => ({
      shot: prevState.shot + 1,
      gifPhotos: prevState.gifPhotos.concat(gifcanvas.toDataURL('image/png')),
    }), () => {
      if (this.state.shot < 6) {
        setTimeout(() => this.flashTakePicture(), 1000)
      } else {
        setTimeout(() => this.processImages(), 1000)
      }
    })
  }
  cleanup() {
    if (this.video) {
      this.video.pause()
    }
    if (this.stream && this.stream.getTracks().length > 0) {
      this.stream.getTracks()[0].stop()
    }
    let colorPickerColor
    if (this.props.colorPickerLight.color) {
      colorPickerColor = { ...this.props.colorPickerLight.color }
    } else {
      colorPickerColor = { ...defaultColorPickerColor }
    }
    colorPickerColor.brightness *= 0.7
    this.props.updateLight(this.props.config.lights.photoBooth.id, colorPickerColor)

  }
  componentWillUnmount() {
    this.cleanup()
  }
  componentDidMount() {
    jquery('.flash').hide()
    navigator.getMedia = (navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia)
    navigator.getMedia({
      video: {
        width: { ideal: 1365 },
        height: { ideal: 768 },
        aspectRatio: { ideal: 1.7777777778 },
      }, audio: false,
    },
    stream => {
      this.stream = stream
      if (navigator.mozGetUserMedia) {
        this.video.mozSrcObject = stream
      } else {
        // const vendorURL = window.URL || window.webkitURL
        this.video.srcObject = stream;
        // this.video.src = vendorURL.createObjectURL(stream)
      }
      this.video.onloadedmetadata = (e) => {
        this.video.play()
      }
    }, error => {
      console.log("Attach Video Error: ", error)
    }
    )

  }
}
const mapStateToProps = state => {
  return {
    reservation: state.doorState.jabbrrboxReservation,
    person: state.doorState.person,
    styles: state.Style.theme,
    tag: state.doorState.jabbrrbox.photoTag,
    config: state.configState.conf,
    colorPickerLight: state.lightState.colorPickerLight,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalPhotoBooth)
