import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../app/actions'
import PropTypes from 'prop-types'
import LogoWithNavigation from './signupScreen/LogoWithNavigation'
import { ContainerStyle } from '../styles/style.format'
import { getDurationLabel } from '../util/string.helpers'

const swipeIconUrl = `${process.env.PUBLIC_URL}/img/icons/payment/swipe.svg`
const dipIconUrl = `${process.env.PUBLIC_URL}/img/icons/payment/dip.svg`
const nfcIconUrl = `${process.env.PUBLIC_URL}/img/icons/payment/nfc.svg`

class PayNowScreen extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
    }
  }

  componentWillMount() {
    this.props.clearPaymentTransactionInfo()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.payment.paymentData.RStream) {
      const isApproved = nextProps.payment.paymentData.RStream.CmdResponse[0].CmdStatus[0] === 'Approved'
      if (nextProps.payment.successful && isApproved) {
        this.props.confirmReservation(this.props.reservationId, this.props.confirmationCode)
        this.props.createPaymentReceipt(this.props.reservationId, nextProps.payment.paymentData.RStream)
        this.props.navigate('PayApproved')
      } else {
        this.props.navigate('PayDenied', this.props.navigation.options)
      }
    }
  }

  render() {
    const cost = parseFloat(this.props.payment.selectedPriceOption.pricingPlan.cost || 0).toFixed(2)
    const duration = getDurationLabel(this.props.payment.selectedPriceOption.pricingPlan.duration)
    return (
      <div style={ContainerStyle}>
        <LogoWithNavigation styles={this.props.styles} hideButtons={true} />
        <div style={this.props.styles.PayNowScreenTitle}>Pay Now</div>
        <div style={this.props.styles.PaymentTypesContainer}>
          <div>
            <div style={this.props.styles.PaymentTypeTitle}>Swipe</div>
            <div style={this.props.styles.PayTypeContainer}>
              <img alt="Swipe" src={swipeIconUrl} style={{ width: 125, height: 'auto' }} />
            </div>
          </div>

          <div style={this.props.styles.PayNowOptionDivider} />

          <div>
            <div style={this.props.styles.PaymentTypeTitle}>Chip</div>
            <div style={this.props.styles.PayTypeContainer}>
              <img alt="Chip" src={dipIconUrl} style={{ width: 125, height: 'auto' }} />
            </div>
          </div>

          <div style={this.props.styles.PayNowOptionDivider} />

          <div>
            <div style={this.props.styles.PaymentTypeTitle}>NFC</div>
            <div style={this.props.styles.PayTypeContainer}>
              <img alt="NFC" src={nfcIconUrl} style={{ width: 125, height: 'auto' }} />
            </div>
          </div>
        </div>
        <div style={this.props.styles.HowToPayDirections}>Complete your purchase using the card reader below.</div>
        <div style={this.props.styles.PayNowCost}>
          for ${cost} / {duration}
        </div>
      </div>
    )
  }
}

PayNowScreen.propTypes = {
  styles: PropTypes.object,
  navigate: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    navigation: state.Navigation,
    payment: state.payment,
    reservationId: state.doorState.reservationId,
    confirmationCode: state.doorState.confirmationCode,
    jabbrrbox: state.doorState.jabbrrbox,
    person: state.doorState.person,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PayNowScreen)
