import React, { Component } from 'react'
import { ActionCreators } from './app/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
// import QrReader from 'react-qr-reader'
import WelcomeScreen from './app/components/WelcomeScreen'
import ThankYouScreen from './app/components/ThankYouScreen'
import TellMeMoreScreen from './app/components/TellMeMoreScreen'
import ConfigScreen from './app/components/ConfigScreen'
import EnterPinScreen from './app/components/EnterPinScreen'
import DoorOpenScreen from './app/components/DoorOpenScreen'
import RoomReservedScreen from './app/components/RoomReservedScreen'
import ReservationConfirmationScreen from './app/components/ReservationConfirmationScreen'
import PayNowScreen from './app/components/PayNowScreen'
import PayApprovedScreen from './app/components/PayApprovedScreen'
import PayDeniedScreen from './app/components/PayDeniedScreen'
import PricingOptionScreen from './app/components/PricingOptionScreen'
import PartnerCodeScreen from './app/components/partners/PartnerCodeScreen'
import PartnerOptionScreen from './app/components/PartnerOptionScreen'
import InternalHomeScreen from './app/components/InternalScreen'
import InternalWelcomeScreen from './app/components/InternalWelcomeScreen'
import InternalHoldingScreen from './app/components/InternalHoldingScreen'
import LoginScreen from './app/components/LoginScreen'
import OutOfOrderScreen from './app/components/maintenance/OutOfOrderScreent'
import ComingSoonScreen from './app/components/maintenance/ComingSoonScreen'
import CleanScreen from './app/components/maintenance/CleanScreen'
import PhoneNumberScreen from './app/components/PhoneNumberScreen'
import EmailScreen from './app/components/EmailScreen'
import CountrySelector from './app/components/countrySelector/CountrySelector'
import './App.css'

const WELCOME = 'Welcome'
const CONFIG = 'Config'
const PAGE_ONE = 'PageOne'
const COUNTRY_SELECTOR = 'CountrySelector'
const EMAIL = 'Email'
const ENTER_PIN = 'EnterPin'
const DOOR_OPEN = 'DoorOpen'
const ROOM_RESERVED = 'RoomReserved'
const RESERVATION_CONFIRMATION = 'ReservationConfirmation'
const PAY_NOW = 'PayNow'
const PAY_APPROVED = 'PayApproved'
const PAY_DENIED = 'PayDenied'
const PRICING_OPTIONS = 'PricingOptions'
const PARTNER_OPTIONS = 'PartnerOptions'
const PARTNER_CODE = 'PartnerCode'
const INTERNAL_HOME = 'InternalHome'
const INTERNAL_WELCOME = 'InternalWelcome'
const INTERNAL_HOLDING_SCREEN = 'InternalHoldingScreen'
const TELL_ME_MORE = 'TellMeMore'
const THANK_YOU = 'ThankYou'
const OUT_OF_ORDER = 'OutOfOrder'
const COMING_SOON = 'ComingSoon'
const CLEAN = 'Clean'
const DEFAULT_ROUTE = WELCOME

const DEFAULT_GLOBAL_INTERVAL = 3600000
const DEFAULT_SHORT_TIMER = 20000

const previewStyle = {
  height: 0,
  width: 0,
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      result: '',
    }
    props.loadConfig()
    this.handleKeyPress = this.handleKeyPress.bind(this)
    document.addEventListener('keydown', this.handleKeyPress, false)

    this.state = {
      timer: null,
      shortTimer: null,
    }
  }

  handleKeyPress(event) {
    // 32 is Spacebar
    if (event.ctrlKey && event.shiftKey && event.keyCode === 32) {
      event.preventDefault()
      this.props.navigate('Config')
    }
  }

  startShortTimer(interval) {
    this.stopShortTimer()
    this.setState({
      shortTimer: setTimeout(() => {
        if (this.props.loaded) {
          this.props.saveEvents(this.props.config.jabbrrbox.id)
          this.props.getReservations(this.props.config.jabbrrbox.id)
          this.props.executeRemoteActions(this.props.config.jabbrrbox.id)
        }
        this.startShortTimer(this.props.config.shortPollInterval)
      }, interval || DEFAULT_SHORT_TIMER),
    })
  }

  stopShortTimer() {
    clearTimeout(this.state.shortTimer)
  }

  startTimer(interval) {
    this.stopTimer()
    this.setState({
      timer: setTimeout(() => {
        this.loadJabbrrboxInfo(this.props)
        this.startTimer(this.props.config.globalPollInterval)
      }, interval || DEFAULT_GLOBAL_INTERVAL),
    })
  }

  stopTimer() {
    clearTimeout(this.state.timer)
  }

  setLightsToDefault() {
    setTimeout(() => {
      if (this.props.loaded) {
        this.props.setLightsToDefault()
      }
    }, 100)
  }

  onLocalConfigLoaded(nextProps) {
    //config was loaded
    if (nextProps.config.jabbrrbox.email && nextProps.config.jabbrrbox.password && !nextProps.loggedIn) {
      this.props.login(nextProps.config.jabbrrbox.email, nextProps.config.jabbrrbox.password)
    }
  }

  onConfigLoaded(nextProps) {
    this.startTimer(nextProps.config.globalPollInterval)
    this.startShortTimer(nextProps.config.shortPollInterval)

    if (nextProps.config.jabbrrbox.mode === 'EXTERNAL' && _.get(nextProps, 'config.lights.external.id')) {
      const externalLight = nextProps.config.lights.external
      this.props.lightRotation(externalLight.id,
        externalLight.reservationEnd.interval,
        externalLight.reservationEnd.colors,
        externalLight.reservationEnd.mode,
        externalLight.reservationEnd.transitionTime)
    }

    if (nextProps.config.jabbrrbox.mode === 'INTERNAL') {
      if (_.get(nextProps, 'config.lights.photoBooth.id')) {
        const photoBoothLight = nextProps.config.lights.photoBooth
        this.props.lightRotation(photoBoothLight.id,
          photoBoothLight.reservationEnd.interval,
          photoBoothLight.reservationEnd.colors,
          photoBoothLight.reservationEnd.mode,
          photoBoothLight.reservationEnd.transitionTime)
      }
      if (_.get(nextProps, 'config.lights.internal.id')) {
        const internalLight = nextProps.config.lights.internal
        this.props.lightRotation(internalLight.id,
          internalLight.reservationEnd.interval,
          internalLight.reservationEnd.colors,
          internalLight.reservationEnd.mode,
          internalLight.reservationEnd.transitionTime)
      }
    }

    const mode = nextProps.config.jabbrrbox.mode
    if (mode === null || mode === 'config') {
      nextProps.navigate('Config')
    } else if (mode === 'INTERNAL') {
      nextProps.navigate(INTERNAL_HOLDING_SCREEN)
    } else {
      nextProps.navigate(WELCOME)
    }
  }

  loadJabbrrboxInfo(props) {
    if (props.localConfigLoaded) {
      props.updateTheme(props.config.jabbrrbox.id)
      props.loadJabbrrboxInfo(props.config.jabbrrbox.id)
    }
  }

  onLoggedIn(nextProps) {
    this.loadJabbrrboxInfo(nextProps)
  }

  onReservationStart(nextProps) {
    if (nextProps.config.jabbrrbox.mode === 'EXTERNAL' && _.get(nextProps, 'config.lights.external.id')) {
      const externalLight = nextProps.config.lights.external
      this.props.lightRotation(externalLight.id,
        externalLight.reservationStart.interval,
        externalLight.reservationStart.colors,
        externalLight.reservationStart.mode,
        externalLight.reservationStart.transitionTime)
    }

    if (nextProps.config.jabbrrbox.mode === 'INTERNAL') {
      if (_.get(nextProps, 'config.lights.photoBooth.id')) {
        const photoBoothLight = nextProps.config.lights.photoBooth
        this.props.lightRotation(photoBoothLight.id,
          photoBoothLight.reservationStart.interval,
          photoBoothLight.reservationStart.colors,
          photoBoothLight.reservationStart.mode,
          photoBoothLight.reservationEnd.transitionTime)
      }
      if (_.get(nextProps, 'config.lights.internal.id')) {
        const internalLight = nextProps.config.lights.internal
        this.props.lightRotation(internalLight.id,
          internalLight.reservationStart.interval,
          internalLight.reservationStart.colors,
          internalLight.reservationStart.mode,
          internalLight.reservationStart.transitionTime)
      }
    }
    this.props.resetAddJabbrrboxFeedback()
  }

  onReservationEnd(nextProps) {
    if (nextProps.config.jabbrrbox.mode === 'EXTERNAL' && _.get(nextProps, 'config.lights.external.id')) {
      const externalLight = nextProps.config.lights.external
      this.props.lightRotation(externalLight.id,
        externalLight.reservationEnd.interval,
        externalLight.reservationEnd.colors,
        externalLight.reservationEnd.mode,
        externalLight.reservationEnd.transitionTime)
    }

    if (nextProps.config.jabbrrbox.mode === 'INTERNAL') {
      if (_.get(nextProps, 'config.lights.photoBooth.id')) {
        const photoBoothLight = nextProps.config.lights.photoBooth
        this.props.lightRotation(photoBoothLight.id,
          photoBoothLight.reservationEnd.interval,
          photoBoothLight.reservationEnd.colors,
          photoBoothLight.reservationEnd.mode,
          photoBoothLight.reservationEnd.transitionTime)
      }

      if (_.get(nextProps, 'config.lights.internal.id')) {
        const internalLight = nextProps.config.lights.internal
        this.props.lightRotation(internalLight.id,
          internalLight.reservationEnd.interval,
          internalLight.reservationEnd.colors,
          internalLight.reservationEnd.mode,
          internalLight.reservationEnd.transitionTime)
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    //localConfig is loaded
    if (!this.props.localConfigLoaded && nextProps.localConfigLoaded) {
      this.onLocalConfigLoaded(nextProps)
    }

    if (!this.props.loaded && nextProps.loaded) {
      this.onConfigLoaded(nextProps)
    }

    //User logged in
    if (!this.props.loggedIn && nextProps.loggedIn) {
      this.onLoggedIn(nextProps)
    }

    if (nextProps.reservation && !this.props.reservation) {
      this.onReservationStart(nextProps)
    } else if (!nextProps.reservation && this.props.reservation) {
      this.onReservationEnd(nextProps)
    }

    if (nextProps.door.locked === false && this.props.door.locked === true) {
      setTimeout(() => {
        this.props.lockDoor()
      }, 5000)
    }
  }

  /* eslint complexity: ["off"] */ /*Complexity reflects number pages and can't be easily reduced*/
  getPageComponent(nav) {

    if (!this.props.loggedIn) {
      return <LoginScreen login={this.props.login} config={this.props.config} autoLogin={false} routeOptions={nav.options} />
    }
    const route = nav && nav.route ? nav.route : DEFAULT_ROUTE

    switch (route) {
      case WELCOME:
        return <div className="App-external"><WelcomeScreen routeOptions={nav.options} /></div>
      case CONFIG:
        return <div className="App-external"><ConfigScreen routeOptions={nav.options} /></div>
      case TELL_ME_MORE:
        return <TellMeMoreScreen routeOptions={nav.options} />
      case THANK_YOU:
        return <ThankYouScreen routeOptions={nav.options} />
      case INTERNAL_HOLDING_SCREEN:
        return <InternalHoldingScreen routeOptions={nav.options} />
      case PAGE_ONE:
        return <div className="App-external"><PhoneNumberScreen routeOptions={nav.options} /></div>
      case EMAIL:
        return <div className="App-external"><EmailScreen routeOptions={nav.options} /></div>
      case COUNTRY_SELECTOR:
        return <div className="App-external"><CountrySelector routeOptions={nav.options} /></div>
      case ENTER_PIN:
        return <div className="App-external"><EnterPinScreen routeOptions={nav.options} /></div>
      case DOOR_OPEN:
        return <div className="App-external"><DoorOpenScreen routeOptions={nav.options} /></div>
      case ROOM_RESERVED:
        return <div className="App-external"><RoomReservedScreen routeOptions={nav.options} /></div>
      case RESERVATION_CONFIRMATION:
        return <div className="App-external"><ReservationConfirmationScreen routeOptions={nav.options} /></div>
      case PAY_NOW:
        return <div className="App-external"><PayNowScreen routeOptions={nav.options} /></div>
      case PAY_APPROVED:
        return <div className="App-external"><PayApprovedScreen routeOptions={nav.options} /></div>
      case PAY_DENIED:
        return <div className="App-external"><PayDeniedScreen routeOptions={nav.options} /></div>
      case PARTNER_OPTIONS:
        return <div className="App-external"><PartnerOptionScreen routeOptions={nav.options} /></div>
      case PARTNER_CODE:
        return <div className="App-external"><PartnerCodeScreen routeOptions={nav.options} /></div>
      case PRICING_OPTIONS:
        return <div className="App-external"><PricingOptionScreen routeOptions={nav.options} /></div>
      case OUT_OF_ORDER:
        return <div className="App-external"><OutOfOrderScreen routeOptions={nav.options} /></div>
      case COMING_SOON:
        return <div className="App-external"><ComingSoonScreen routeOptions={nav.options} /></div>
      case CLEAN:
        return <div className="App-external"><CleanScreen routeOptions={nav.options} /></div>
      case INTERNAL_HOME:
        return <InternalHomeScreen routeOptions={nav.options} />
      case INTERNAL_WELCOME:
        this.setLightsToDefault()
        return <InternalWelcomeScreen routeOptions={nav.options} />
      default:
        return <div className="App-external"><WelcomeScreen routeOptions={nav.options} /></div>
    }
  }

  handleScan(data) {
    if (!data) {
      return
    }
    //What to do with the data
    //Assume it's JSON data
    try {
      this.props.getAccess(data)
    } catch (err) {
      this.handleScanError(err)
    }
    this.setState({
      result: data,
    })
  }

  handleScanError(err) {
    this.setState({
      scanError: err,
    })
  }

  render() {
    if (this.props.config && this.props.config.jabbrrbox && this.props.config.jabbrrbox.mode && this.props.config.jabbrrbox.mode === 'EXTERNAL') {
      if (this.props.config.currentMaintenanceScreen === 'outOfOrder') {
        return <div className="App-external"><OutOfOrderScreen /></div>
      } else if (this.props.config.currentMaintenanceScreen === 'comingSoon') {
        return <div className="App-external"><ComingSoonScreen /></div>
      } else if (this.props.config.currentMaintenanceScreen === 'clean') {
        return <div className="App-external"><CleanScreen /></div>
      } else {
        const pageComponent = this.getPageComponent(this.props.nav)
        return (
          <div className="App">
            {/* <QrReader
              delay={1000}
              style={previewStyle}
              onError={this.handleScanError.bind(this)}
              onScan={this.handleScan.bind(this)}
            /> */}
            {pageComponent}
          </div>
        )
      }
    } else {
      const pageComponent = this.getPageComponent(this.props.nav)
      return (
        <div className="App">
          {/* <QrReader
            delay={1000}
            style={previewStyle}
            onError={this.handleScanError.bind(this)}
            onScan={this.handleScan.bind(this)}
          /> */}
          {pageComponent}
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    config: state.configState.conf,
    localConfigLoaded: state.configState.localConfigLoaded,
    loaded: state.configState.loaded,
    loggedIn: state.configState.loggedInKey,
    nav: state.Navigation,
    inputNode: state.SignUp.inputNode,
    jabbrrbox: state.doorState.jabbrrbox,
    door: state.doorState,
    locationPricingPlan: state.locationPricingPlan,
    jabbrrboxPricingPlan: state.jabbrrboxPricingPlan,
    reservation: state.doorState.jabbrrboxReservation,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
