import React, { Component } from 'react'
import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Padding } from '../../styles/style.format'
import Motto from './Motto'
import Sponsor from '../shared/SponsoredBy'

class BottomContainer extends Component {

  render() {
    const jabbrrbox = this.props.jabbrrbox
    const style = { ...{ paddingTop: 6 }, ...this.props.viewStyle,
      display: 'flex', flexDirection: 'column', flexGrow: 2 }
    if (jabbrrbox && !jabbrrbox.sponsorId) {
      style.paddingTop = Padding.XLarge
    }
    return <div style={ style }>
      <Motto navigation={this.props.navigation} styles={this.props.styles} />
      <Sponsor styles={this.props.styles} options={{ paddingBottom: 20 }}
        sponsor={this.props.jabbrrbox ? this.props.jabbrrbox.sponsor : undefined}/>
    </div>
  }
}

const mapStateToProps = state => {
  return {
    jabbrrbox: state.doorState.jabbrrbox,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(BottomContainer)
