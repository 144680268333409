import React from 'react'
import PropTypes from 'prop-types'
import { Padding, Margin } from '../../styles/style.format'
import { Textfit } from 'react-textfit'

const adaCompliantIconSrc = `${process.env.PUBLIC_URL}/img/wheelchair.svg`
const internalStyles = styles => {
  return {
    container: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: Padding.XXLarge,
      paddingBottom: Padding.Medium,
      borderBottomWidth: 1,
      backgroundColor: styles.Color.NeutralLight,
    },
    H1: {
      ...styles.H1,
      paddingBottom: Padding.XLarge,
      textTransform: 'uppercase',
    },
    H3: {
      ...styles.H3,
      paddingLeft: 24,
      paddingRight: 24,
    },
    H5: {
      ...styles.H5,
    },
  }
}
const A1 = ({ jabbrrbox, styles }) => {
  const inStyles = internalStyles(styles)
  return (

    <div style={inStyles.container}>
      <Textfit
        mode="single"
        forceSingleModeWidth={true}
        max={inStyles.H1.fontSize}
        style={{ ...inStyles.H1, paddingLeft: Padding.Small, paddingRight: Padding.Small }}>
        {jabbrrbox ? jabbrrbox.name : ''}
      </Textfit>
      <div style={{ paddingBottom: Padding.Small }}>
        <div style={{ width: '100%', marginLeft: Margin.XLarge }} />
        {jabbrrbox && jabbrrbox.location &&
          <Textfit
            mode="single"
            forceSingleModeWidth={true}
            max={inStyles.H3.fontSize}
            style={ inStyles.H3 }>
            {jabbrrbox.location.name || ''}
          </Textfit>
        }
        <div style={{ width: '100%', marginRight: Margin.XLarge }} />
      </div>
      {jabbrrbox && jabbrrbox.isAdaCompliant &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={adaCompliantIconSrc} alt="ada compliant icon" style={{ paddingRight: Padding.XXSmall }} />
          <div style={inStyles.H5}>
            ADA COMPLIANT
          </div>
        </div>
      }
    </div>
  )
}

A1.propTypes = {
  jabbrrbox: PropTypes.object,
  styles: PropTypes.object,
}

export default A1
