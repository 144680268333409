const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const validateMobile = (mobile, cc) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(mobile, cc))
  } catch (e) {
    return false
  }
}
export const validateName = name => {
  return !!name
}
