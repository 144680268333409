import moment from 'moment'
import * as flightStatus from '../actions/flight.status'
import * as door from '../actions/door'
import _ from 'lodash'

const statusToName = {
  A: 'Active',
  C: 'Canceled',
  D: 'Diverted',
  DN: 'Data source needed',
  L: 'Landed',
  NO: 'Not Operational',
  R: 'Redirected',
  S: 'On Time', //'Scheduled',
  U: 'Unknown',
}

const INITIAL_STATE = {
  flightStatus: {
    error: null,
  },
  error: null,
  isLoading: false,
  flightSearchState: 'flightSearchOptions',
}

const getAirportByAirportCode = (airports, airportCode) => {
  const filteredAirports = _.filter(airports, airport => {
    return airport.faa === airportCode
  })

  if (filteredAirports.length === 1) {
    return filteredAirports[0]
  } else {
    return {}
  }
}

const reduceFlightStatus = (data, airportCode) => {
  const reduced = {
    flightStatus: null,
    error: null,
  }
  data.flightStatuses = _.sortBy(data.flightStatuses, [flight => {
    return flight.departureAirportFsCode === airportCode ? 0 : 1
  }])

  if (data.flightStatuses.length > 0) {
    const reducedFlights = data.flightStatuses.map(currentflightStatus => {
      const format = 'h:MM A'

      const departureAirport = getAirportByAirportCode(data.appendix.airports, currentflightStatus.departureAirportFsCode)
      const departureCity = departureAirport.city
      const departureStateCode = departureAirport.stateCode
      const departureAirportCode = currentflightStatus.departureAirportFsCode
      const departureDateScheduled = moment(currentflightStatus.operationalTimes.scheduledGateDeparture.dateUtc).format(format)
      const departureDateEstimated = moment(currentflightStatus.operationalTimes.estimatedGateDeparture.dateUtc).format(format)
      const departureDate = moment(currentflightStatus.departureDate.dateUtc).format(format)
      const departureGate = currentflightStatus.airportResources.departureGate

      const arrivalAirport = getAirportByAirportCode(data.appendix.airports, currentflightStatus.arrivalAirportFsCode)
      const arrivalCity = arrivalAirport.city
      const arrivalStateCode = arrivalAirport.stateCode
      const arrivalAirportCode = currentflightStatus.arrivalAirportFsCode
      const arrivalDateScheduled = moment(currentflightStatus.operationalTimes.scheduledGateArrival.dateUtc).format(format)
      const arrivalDateEstimated = moment(currentflightStatus.operationalTimes.estimatedGateArrival.dateUtc).format(format)
      const arrivalDate = moment(currentflightStatus.arrivalDate.dateUtc).format(format)
      const arrivalGate = currentflightStatus.airportResources.arrivalGate

      const status = currentflightStatus.status
      const statusName = statusToName[status]
      const flightId = currentflightStatus.flightId

      return {
        departureCity,
        departureStateCode,
        departureDateScheduled,
        departureDateEstimated,
        departureDate,
        departureGate,
        departureAirportCode,

        arrivalCity,
        arrivalStateCode,
        arrivalDateScheduled,
        arrivalDateEstimated,
        arrivalDate,
        arrivalGate,
        arrivalAirportCode,

        status,
        statusName,
        flightId,
      }
    })
    return { flightStatus: reducedFlights }
  } else {
    reduced.flightStatus = {
      error: 'Flight not found',
    }
  }


  return reduced
}

const getAirportCode = jabbrrbox => {
  return jabbrrbox.location.airportCode
}

export const FlightStatus = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case flightStatus.GET_FLIGHT_STATUS_STOPPED:
      return { ...state, ...reduceFlightStatus(payload, state.airportCode) }
    case flightStatus.CLEAR_FLIGHT_STATUS:
      return { ...INITIAL_STATE }
    case flightStatus.UPDATE_FLIGHT_SEARCH_STATE_STOPPED:
      return { ...state, flightSearchState: payload }
    case door.LOAD_JABBRRBOX_REQUEST_STOPPED:
      return { ...state, airportCode: getAirportCode(payload) }
    default:
      return state
  }
}
