import moment from 'moment'
import fetchJsonp from 'fetch-jsonp'

export const GET_FLIGHT_STATUS_STARTED = 'GET_FLIGHT_STATUS_STARTED'
export const GET_FLIGHT_STATUS_STOPPED = 'GET_FLIGHT_STATUS_STOPPED'
export const GET_FLIGHT_STATUS_ERROR = 'GET_FLIGHT_STATUS_ERROR'
export const CLEAR_FLIGHT_STATUS = 'CLEAR_FLIGHT_STATUS'
export const UPDATE_FLIGHT_SEARCH_STATE_STOPPED = 'UPDATE_FLIGHT_SEARCH_STATE_STOPPED'

export const FLIGHT_SEARCH_OPTIONS = {
  FLIGHT_SEARCH: 'flightSearchOptions',
  TICKET_SCAN: 'flightSearchTicketScan',
  MANUAL_INPUT: 'flightSearchInput',
  RESULTS: 'flightSearchResults',
}

const get = url => {
  return fetchJsonp(url)
    .then(response => {
      return response.json()
    }).catch(error => {
      throw Error(error)
    })
}

const getFlightStatusRequestStarted = () => ({ type: GET_FLIGHT_STATUS_STARTED })
const getFlightStatusRequestError = err => ({ type: GET_FLIGHT_STATUS_ERROR, payload: err })
const getFlightStatusRequestStopped = (payload = {}) => ({ type: GET_FLIGHT_STATUS_STOPPED, payload })

const updateFlightSearchStateRequestStopped = flightSearchState => ({ type: UPDATE_FLIGHT_SEARCH_STATE_STOPPED, payload: flightSearchState })

export const updateFlightSearchState = flightSearchState => {
  return dispatch => {
    dispatch(updateFlightSearchStateRequestStopped(flightSearchState))
  }
}

export const getFlightStatus = (airline, flightId, tomorrow) => {
  return (dispatch, getState) => {
    dispatch(getFlightStatusRequestStarted())
    const flightstats = getState().configState.conf.flightStats
    const m = moment()
    if (tomorrow) {
      m.add(1, 'days')
    }
    const year = m.format('YYYY')
    const month = m.format('MM')
    const day = m.format('DD')
    const url = `${flightstats.flightStatus.restServiceBaseURL}/${airline}/${flightId}/dep/\
${year}/${month}/${day}?appId=${flightstats.appId}&appKey=${flightstats.appKey}&utc=true`
    return get(url, getState())
      .then(data => {
        dispatch(getFlightStatusRequestStopped(data))
        return data
      })
      .catch(error => {
        dispatch(getFlightStatusRequestError(error))
      })
  }
}

export const clearFlightStatus = (payload = {}) => ({ type: CLEAR_FLIGHT_STATUS, payload })
