import React from 'react'

const renderList = ({ photos }) => {
  const list = photos.map(photo => {
    return <img style={{ width: '65%', margin: 10 }} src={photo} role="presentation"/>
  })
  return (
    <div style={{ minWidth: '25%', display: 'flex', flexDirection: 'column' }}>{list}</div>
  )
}
export default renderList
