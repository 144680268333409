import React from 'react'
import Sponsor from './Sponsor'
import { Margin } from '../../styles/style.format'
import Divider from 'material-ui/Divider'

const InternalStyle = {
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 10,
  },
}
const SponsoredBy = ({ sponsor, styles }) => {
  if (sponsor) {
    return (
      <div style={InternalStyle.container}>
        <Divider style={{ marginBottom: Margin.XSmall }} />
        <div style={{ ...styles.SponsoredBy, marginBottom: Margin.XSmall }}>SPONSORED BY</div>
        <Sponsor styles={styles} sponsor={sponsor}/>
      </div>
    )
  } else {
    return ''
  }

}
export default SponsoredBy
