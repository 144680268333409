import React, { Component } from 'react'
import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import EnterAccessCodeComponent from './enterPinScreen/EnterAccessCodeComponent'
import LogoWithNavigation from './signupScreen/LogoWithNavigation'
import BottomComponent from './enterPinScreen/BottomComponent'
import TopComponent from './enterPinScreen/TopComponent'
import { ContainerStyle } from '../styles/style.format'

class EnterPinScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navigationTimer: null,
    }
    this.startNavigationTimer = this.startNavigationTimer.bind(this)
  }

  componentWillMount() {
    this.props.clearDoorRequestOpenError()
  }

  componentDidMount() {
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    this.stopNavigationTimer()
  }

  startNavigationTimer() {
    this.stopNavigationTimer()
    this.setState({
      navigationTimer: setTimeout(() => {
        this.stopNavigationTimer()
        this.props.logEvent('Payment Workflow', 'Timeout', { evLabel: 'EnterPinScreen', evValue: this.props.config.timeout || 30000 })
        this.props.navigate('RoomReserved')
      }, parseFloat(this.props.config.timeout) || 30000),
    })
  }

  stopNavigationTimer() {
    clearTimeout(this.state.navigationTimer)
  }

  render() {
    const unlockMode = this.props.nav.options && this.props.nav.options.mode === 'unlock'
    return (
      <div style={{ ...ContainerStyle, backgroundColor: this.props.styles.Color.White }} >

        <LogoWithNavigation styles={this.props.styles} hideBackButton={true} leftFunction={() => {
          this.stopNavigationTimer()
          this.props.navigate('RoomReserved')
        }}
        closeFunction={() => {
          this.stopNavigationTimer()
          this.props.navigate('RoomReserved')
        }}/>

        <div>
          {!unlockMode && <TopComponent styles={this.props.styles} /> }
          <EnterAccessCodeComponent
            labelText={'ENTER YOUR ACCESS CODE'}
            onChange={this.startNavigationTimer}
          />
          {!unlockMode && <BottomComponent styles={this.props.styles}/>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    person: state.doorState.person,
    config: state.configState.conf,
    nav: state.Navigation,
    styles: state.Style.theme,
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EnterPinScreen)
