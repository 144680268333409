import React from 'react'
import { Margin } from '../../styles/style.format'

const myStyles = (styles, noRightBorder) => {
  if (noRightBorder) {
    return {
      width: '100%',
    }
  }
  return {
    borderRight: 'solid',
    borderWidth: 'thin',
    width: '100%',
    borderColor: styles.Color.White,
    marginRight: Margin.XXSmall,
    paddingRight: Margin.XLarge,
  }
}
const Step = ({ num, value, styles, noRightBorder }) => {
  const inStyle = myStyles(styles, noRightBorder)
  return (
    <div style={inStyle}>
      <div style={{ ...styles.InternalText, color: styles.Color.BrandPrimary, marginBottom: Margin.XXSmall }}>STEP {num}</div>
      <div style={styles.InternalInfoText}>{value}</div>
    </div>
  )
}
export default Step
