import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import FlatButton from 'material-ui/FlatButton'
import { Padding, Margin } from '../../styles/style.format'
import { FLIGHT_SEARCH_OPTIONS } from '../../actions/flight.status'


const DEFAULT_CAMERA_TIMER = 1000
const VIDEO_WIDTH = 1024
const VIDEO_HEIGHT = 768

const canvas = document.createElement('canvas')
const context = canvas.getContext('2d')
canvas.width = VIDEO_WIDTH
canvas.height = VIDEO_HEIGHT

class FlightSearchTicketScan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cameraTimer: null,
      flightTomorrow: false,
      revertFlightSearchState: true,
    }
  }


  componentWillUnmount() {
    this.state.revertFlightSearchState && this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.FLIGHT_SEARCH)
    this.stopCameraTimer()
  }

  componentDidMount() {
    navigator.getMedia = (navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia)
    navigator.getMedia({
      video: {
        width: { ideal: 1365 },
        height: { ideal: 768 },
        aspectRatio: { ideal: 1.7777777778 },
      }, audio: false,
    },
    stream => {
      this.stream = stream
      if (navigator.mozGetUserMedia) {
        this.video.mozSrcObject = stream
      } else {
        const vendorURL = window.URL || window.webkitURL
        this.video.src = vendorURL.createObjectURL(stream)
      }
      this.video.play()
      this.startCameraTimer()
    }, error => {
      console.log(error)
    }
    )
  }

  startCameraTimer(interval) {
    const app = this
    this.stopCameraTimer()
    if (!this.video) {
      return
    }
    this.setState({
      cameraTimer: setTimeout(() => {
        const doDecodeQR = window.require ? window.require('electron').remote.getCurrentWindow().decodeQR : null
        if (doDecodeQR && app.video) {
          context.drawImage(app.video, 0, 0, VIDEO_WIDTH, VIDEO_HEIGHT)
          const data = atob(canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, ''))
          const ia = new Uint8Array(data.length)
          for (let i = 0; i < data.length; i++) {
            ia[i] = data.charCodeAt(i)
          }
          doDecodeQR(ia, (err, qrInfo) => {
            if (!err && qrInfo) {
              const parts = qrInfo.split(/\s+/)
              //M1SOLLE/JOSUHUA,EQHSLJX,ATLMEMDL,0254,006Y28C,10C3JIJI7O4M28C
              const airportStuff = parts[2]
              const flightNumber = parts[3]

              const airline = airportStuff.slice(-2)

              this.props.getFlightStatus(airline, flightNumber, this.state.flightTomorrow).then(() => {
                this.setState({
                  revertFlightSearchState: false,
                })
                this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.RESULTS)
              })
              this.stopCameraTimer()
            } else {
              this.startCameraTimer(interval)
            }
          })
        }
      }, 1000 || DEFAULT_CAMERA_TIMER),
    })
  }

  stopCameraTimer() {
    clearTimeout(this.state.cameraTimer)
  }

  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1, flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ ...this.props.styles.FlightSearchHeaderText, marginRight: Margin.XSmall }} >
            Scan Ticket
          </div>
          <FlatButton
            style={{ ...this.props.styles.SecondaryButton, paddingLeft: Padding.Small, paddingRight: Padding.Small }}
            label={'ENTER FLIGHT NUMBER'}
            primary={true}
            onClick={() => {
              this.setState({
                revertFlightSearchState: false,
              }, () => {
                this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.MANUAL_INPUT)
              })
            }}
          />
        </div>
        <div style={{ height: '648px', width: '1149px', backgroundColor: this.props.styles.Color.Neutral }}>
          <video
            ref={ref => {
              this.video = ref
            }}
            style={{ height: '100%' }}
          >
            Video Stream Not Available</video>
        </div>
        <p style={{ color: 'white', fontSize: '24px' }}>Place ticket barcode in front of camera and wait for the scan.</p>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(FlightSearchTicketScan)
