import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../app/actions'
import PropTypes from 'prop-types'
import { Margin, ContainerStyle } from '../styles/style.format'

const doorOpenUrl = `${process.env.PUBLIC_URL}/img/door_open.svg`
const doorArrowLeftUrl = `${process.env.PUBLIC_URL}/img/door_arrow_left.svg`
const doorArrowRightUrl = `${process.env.PUBLIC_URL}/img/door_arrow_right.svg`

class DoorOpenScreen extends Component {
  
  render() {
    const arrowUrl = this.props.config.jabbrrbox.doorFacesLeft ? doorArrowLeftUrl : doorArrowRightUrl
    const arrowStyle = this.props.config.jabbrrbox.doorFacesLeft ? 'bounce' : 'bounce2'
    
    return (
      <div style={{ ...ContainerStyle, height: '100%' }}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div style={{ marginBottom: Margin.XXXLarge }}>
            <img alt="Door Open" src={doorOpenUrl} style={{ width: 500, height: 'auto' }} />
          </div>
          <div>
            <img alt="Door Unlocked" src={arrowUrl}
              className={arrowStyle}
              style={{ width: 300, height: 'auto' }} />
          </div>
        </div>
      </div>
    )
  }
}

DoorOpenScreen.propTypes = {
  styles: PropTypes.object,
  config: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    config: state.configState.conf,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DoorOpenScreen)
