import { get } from '../util/services'

export const LOAD_COLOR_PICKER_PRESETS_STARTED = 'LOAD_COLOR_PICKER_PRESETS_STARTED'
export const LOAD_COLOR_PICKER_PRESETS_STOPPED = 'LOAD_COLOR_PICKER_PRESETS_STOPPED'
export const LOAD_COLOR_PICKER_PRESETS_ERROR = 'LOAD_COLOR_PICKER_PRESETS_ERROR'

export const loadColorPickerPresetsStarted = () => ({ type: LOAD_COLOR_PICKER_PRESETS_STARTED })
export const loadColorPickerPresetsStopped = presets => ({ type: LOAD_COLOR_PICKER_PRESETS_STOPPED, payload: presets })
export const loadColorPickerPresetsError = error => ({ type: LOAD_COLOR_PICKER_PRESETS_ERROR, payload: error })
export const loadColorPickerPresets = () => {
  return (dispatch, getState) => {
    dispatch(loadColorPickerPresetsStarted())
    return get(`/colorpickerPresets`, getState())
            .then(data => {
              dispatch(loadColorPickerPresetsStopped(data))
            })
            .catch(error => {
              dispatch(loadColorPickerPresetsError(error))
            })
  }
}
