import React from 'react'
import FlatButton from 'material-ui/FlatButton'
import PropTypes from 'prop-types'
import { Padding } from '../styles/padding.margin'
import { connect } from 'react-redux'


class ButtonGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: 0,
    }
  }

  render() {
    const styles = {
      default: {
        height: '48px',
        fontSize: this.props.styles.FontSize.MFont,
        fontFamily: this.props.styles.FontFamily.Regular,
        backgroundColor: this.props.styles.Color.NeutralLight,
        color: this.props.styles.Color.NeutralDark,
        borderColor: this.props.styles.Color.NeutralLight,
        border: 'solid',
      },
      active: {
        backgroundColor: this.props.styles.Color.NeutralDark,
        color: this.props.styles.Color.NeutralLight,
      },
    }
    return (
      <div>
        {
          this.props.buttons.map((button, index) => {
            let borderRadius
            const currentStyle = { ...styles.default }
            if (index === 0) {
              borderRadius = '4px 0px 0px 4px'
            }
            if (index === this.props.buttons.length - 1) {
              borderRadius = '0px 4px 4px 0px'
            }
            if (index === this.state.selectedIndex) {
              Object.assign(currentStyle, styles.active)
            }
            return (
              <FlatButton
                key={index}
                style={{ width: '177px', paddingLeft: Padding.XXSmall, paddingRight: Padding.XXSmall, borderRadius, ...currentStyle }}
                label={button.label}
                labelStyle={{ fontSize: this.props.styles.FontSize.MFont }}
                onClick={() => {
                  this.setState({
                    selectedIndex: index,
                  },
                  button.onClick)
                }
                }
              />
            )
          })
        }
      </div>
    )
  }




}

ButtonGroup.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
  }
}

export default connect(mapStateToProps)(ButtonGroup)
