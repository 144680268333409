import moment from 'moment'

import { create } from '../util/services'

export const LOG_EVENT = 'LOG_EVENT'
export const SAVE_EVENTS = 'SAVE_EVENTS'
export const SAVE_EVENTS_STARTED = 'SAVE_EVENTS_STARTED'
export const SAVE_EVENTS_FAILURE = 'SAVE_EVENTS_FAILURE'
export const SAVE_EVENTS_SUCCESSFUL = 'SAVE_EVENTS_SUCCESSFUL'

const logEventAction = event => {
  return ({ type: LOG_EVENT, payload: event })
}

export const logEvent = event => {
  return dispatch => {
    dispatch(logEventAction({ datetime: moment(), ...event }))
  }
}

const saveEventsStartedAction = () => {
  return ({ type: SAVE_EVENTS_STARTED })
}

const saveEventsFailureAction = error => {
  return ({ type: SAVE_EVENTS_FAILURE, payload: error })
}

const saveEventsSuccessfulAction = savedEvents => {
  return ({ type: SAVE_EVENTS_SUCCESSFUL, payload: savedEvents })
}

export const saveEvents = jabbrrboxId => {
  return (dispatch, getState) => {
    const events = getState().EventLog.eventQueue
    dispatch(saveEventsStartedAction())
    if (!events || events.length === 0) {
      dispatch(saveEventsSuccessfulAction([]))
    } else {
      create(`/jabbrrboxes/${jabbrrboxId}/jabbrrboxEvents`, getState(), events).then(data => {
        dispatch(saveEventsSuccessfulAction(data))
      }).catch(err => {
        dispatch(saveEventsFailureAction(err))
      })
    }
  }
}
