import React, { Component } from 'react'
import PhoneNumberInput from './shared/PhoneNumberInput'
import InputLabel from './shared/InputLabel'
import { Padding, ContainerStyle, PageSpinner } from '../styles/style.format'
import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Divider from 'material-ui/Divider'
import { validateMobile } from './shared/validation'
import LogoWithNavigation from './signupScreen/LogoWithNavigation'
import Keyboard from '../keyboard/Keyboard'

const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

class PhoneNumberScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: props.mobile,
      navTimer: null,
    }
    this.submitForm = this.submitForm.bind(this)
    this.isFormValid = this.isFormValid.bind(this)
  }

  componentDidMount() {
    this.props.pageLoadStop()
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    this.stopNavigationTimer()
  }

  startNavigationTimer() {
    this.stopNavigationTimer()

    this.setState({
      navTimer: setTimeout(() => {
        this.props.logEvent('Payment Workflow', 'Timeout', { evLabel: 'PhoneNumberScreen', evValue: this.props.config.signUpPageTimeout || 30000 })
        this.props.navigate('Welcome')
      }, parseFloat(this.props.config.signUpPageTimeout) || 30000),
    })
  }

  stopNavigationTimer() {
    clearTimeout(this.state.navTimer)
  }

  selectInput(selected) {
    this.setState({ selected })
  }

  onSubmitNext() {
    if (this.isFormValid()) {
      this.props.pageLoadStart()
      const countryCode = this.props.country.code.toUpperCase()
      this.props.logEvent('Payment Workflow', 'Country Selected', { evLabel: countryCode })
      this.props.setSignupPhoneNumber(this.state.mobile)
      this.props.navigate('Email')
    }
  }

  submitForm() {
    this.stopNavigationTimer()
  }

  isFormValid() {
    return validateMobile(this.state.mobile, this.props.country.code.toUpperCase())
  }

  getTextIn(validator) {
    const examplePhoneNumber = phoneUtil.getExampleNumber(this.props.country.code.toUpperCase())
    const example = (`${phoneUtil.getExampleNumber(this.props.country.code.toUpperCase()).values_[2]}`)
    const fmt = phoneUtil.format(examplePhoneNumber, PNF.INTERNATIONAL)
    const maxLength = (`${example}`).length
    return (
      <PhoneNumberInput id={'mobile'} onSelect={this.selectInput.bind(this)}
        selected={true} styles={this.props.styles}
        onChange={mobile => {
          this.setState({ mobile })
          this.startNavigationTimer()
        }}
        isSubmitEnabled={this.isFormValid.bind(this)}
        value={this.state.mobile}
        placeholder={fmt}
        maxLength={maxLength}
        onSubmitNext={this.onSubmitNext.bind(this)}
        submitText="Next"
        country={this.props.country}
        onCountryClick={() => {
          this.props.setSignupPhoneNumber(this.state.mobile)
          this.props.navigate('CountrySelector', { selectedCountryCode: this.props.country.code.toLowerCase() })
        }}
        validator={validator}
      />
    )
  }

  renderKeyboard() {
    return (
      <div style={{ display: 'block', position: 'absolute', left: 0, bottom: 0, width: '100%' }}><Keyboard
        type={'numeric'}
        inputNode={this.state.inputNode}
        handleSubmitButton={() => {
          this.props.onSubmitNext(this.props.id)
        }}
        submitText={'SUBMIT'}
        isSubmitEnabled={this.isFormValid.bind(this)}
      /></div>)
  }

  render() {
    return (
      <div>
        { !this.props.pageLoaded && <div style={ PageSpinner }>
          <img alt="Loading" src={`${process.env.PUBLIC_URL}/img/spinner.svg`} />
        </div> }
        { this.props.pageLoaded &&
          <div style={{ ...ContainerStyle, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
            <LogoWithNavigation styles={this.props.styles} leftFunction={() => {
              this.stopNavigationTimer()
              this.props.navigate('PricingOptions')
            }} closeFunction={() => {
              this.stopNavigationTimer()
              this.props.navigate('Welcome')
            }} />
            <Divider style={{ ...this.props.styles.ExternalDivider, width: '100%' }} />
            <div style={{ paddingTop: Padding.Large }} />
            <InputLabel title={'MOBILE NUMBER'} subText={'This will be used to send a text with your unique access code'}
              styles={this.props.styles} />
            <div style={{ paddingTop: Padding.Large }} />
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              {this.getTextIn(() => {
                this.isFormValid()
              })}
              {this.state.inputNode && this.renderKeyboard()}
            </div>
          </div> }
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    country: state.SignUp.country,
    mobile: state.SignUp.mobile,
    userInfo: state.UserInfo,
    config: state.configState.conf,
    styles: state.Style.theme,
    pageLoaded: state.pageLoaded,
    sponsor: state.doorState.jabbrrbox ? state.doorState.jabbrrbox.sponsor : undefined,
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberScreen)
