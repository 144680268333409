import React from 'react'
import { Padding } from '../../styles/style.format'
import { GridList, GridTile } from 'material-ui/GridList'
import Divider from 'material-ui/Divider'

const getIconURL = iconName => {
  return {
    iconURL: `${process.env.PUBLIC_URL}/img/icons/active/${iconName}`,
  }
}

const whatIsInIcons = [
  {
    iconTitle: 'ACCESS CONTROL',
    ...getIconURL('access_control.svg'),
  },
  {
    iconTitle: 'BLUETOOTH',
    ...getIconURL('Bluetooth.svg'),
  },
  {
    iconTitle: 'MOTION SENSOR',
    ...getIconURL('motion_sensor.svg'),
  },
  {
    iconTitle: 'SUPPORT',
    ...getIconURL('Support.svg'),
  },
  {
    iconTitle: 'SPEAKERS',
    ...getIconURL('speakers.svg'),
  },
  {
    iconTitle: 'USB CHARGING',
    ...getIconURL('usb_charging.svg'),
  },
  {
    iconTitle: 'POWERED',
    ...getIconURL('powered.svg'),
  },
  {
    iconTitle: 'COMFORT',
    ...getIconURL('comfort.svg'),
  },
  {
    iconTitle: 'WiFi',
    ...getIconURL('Wifi.svg'),
  },
  {
    iconTitle: 'SMOKE DETECTOR',
    ...getIconURL('smoke.svg'),
  },
  {
    iconTitle: 'VIDEO CALLING',
    ...getIconURL('Video.svg'),
  },
]

const WhatIsInJabbrrBoxComponent = ({ styles }) => {

  const jabbrrboxIconTitles = whatIsInIcons.map(jabbrrbox =>
    <GridTile key={jabbrrbox.iconTitle} cols={1} rows={1}>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <img src={jabbrrbox.iconURL} role={'presentation'}
            style={{ backgroundColor: 'transparent', width: 36, height: 36 }} />
        </div>
        <Divider style={{ width: 28, marginTop: 5, marginBottom: 10 }} />
        <div style={styles.WhatIsInJabbrrboxIconTitle}>{jabbrrbox.iconTitle}</div>
      </div>
    </GridTile>
  )

  return (
    <div>
      <div style={{ ...styles.WhenToUseTitle, marginTop: Padding.Large, marginBottom: Padding.Large }}>
                WHAT IS IN A JABBRRBOX
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: Padding.Large }}>
        <GridList
          cols={3}
          cellHeight={100}
          padding={0}
          style={styles.WhatIsInJabbrrboxIconGrid}
        >
          {jabbrrboxIconTitles}
        </GridList>
      </div>
    </div>
  )
}

export default WhatIsInJabbrrBoxComponent
