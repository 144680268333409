import * as UserActions from './user'
import * as DoorActions from './door'
import * as ConfigActions from './config'
import * as MenuActions from './menu'
import * as NavigationActions from './nav'
import * as PageActions from './page'
import * as EventLogActions from './event.log'
import * as SignupActions from './sign.up'
import * as MarketingCardActions from './marketing.card'
import * as StyleActions from './style'
import * as LightsActions from './lights'
import * as ColorPickerActions from './color.picker'
import * as PhotoActions from './photos'
import * as PaymentActions from './payment'
import * as LocationPricingPlan from './location.pricing.plan'
import * as JabbrrboxPricingPlan from './jabbrrbox.pricing.plan'
import * as PartnerPricingPlan from './partner.pricing.plan'
import * as QRCodeActions from './qrcode'
import * as PaymentReceipt from './payment.receipt'
import * as GoogleAnalyticActions from './google.analytics'
import * as RemoteActions from './remote.actions'
import * as JabbrrboxFeedbackActions from './jabbrrbox.feedback'
import * as FlightStatus from './flight.status'
import * as WeatherActions from './weather'
import * as ExceptionActions from './exception'

export const PAGE_ONE = 'PageOne'
export const REMOVE_ACTIVE_KEYBOARD = 'REMOVE_ACTIVE_KEYBOARD'
const start = () => ({ type: PAGE_ONE })
export const ActionCreators = {
  ...UserActions,
  ...ConfigActions,
  ...DoorActions,
  ...MenuActions,
  ...NavigationActions,
  ...PageActions,
  ...EventLogActions,
  ...SignupActions,
  ...MarketingCardActions,
  ...StyleActions,
  ...LightsActions,
  ...ColorPickerActions,
  ...PhotoActions,
  ...PaymentActions,
  ...LocationPricingPlan,
  ...JabbrrboxPricingPlan,
  ...PartnerPricingPlan,
  ...QRCodeActions,
  ...PaymentReceipt,
  ...GoogleAnalyticActions,
  ...JabbrrboxFeedbackActions,
  ...FlightStatus,
  ...start,
  ...RemoteActions,
  ...WeatherActions,
  ...ExceptionActions,
}
