import * as nav from '../actions/nav'

const INITIAL_STATE = {
}

export const Navigation = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case nav.NAVIGATE :
      return { ...state, route: payload.route, options: payload.options }
    default:
      return state
  }
}
