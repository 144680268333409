import React from 'react'

const internalStyles = styles => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      width: '100%',
      height: 120,
      justifyContent: 'center',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: styles.Color.Neutral,
      textTransform: 'uppercase',
    },
  }
}
export const MenuItem = ({ menuItem, isSelected, onClick, styles }) => {
  const internalStyle = internalStyles(styles)
  return (
    <div style={internalStyle.container} onClick={() => {
      onClick(menuItem)
    }}
    >
      <div>
        <img src={isSelected ? menuItem.iconHighlighted : menuItem.icon } role={'presentation'}
          style={{ backgroundColor: 'transparent', width: 48, height: 48, paddingBottom: 12 }} />
        <div style={isSelected ? styles.MenuItemSelectedText : styles.MenuItemText}>{menuItem.name}</div>
      </div>
    </div>
  )
}
export default MenuItem
