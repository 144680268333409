export const Padding = {
  XXXXXLarge: 132,
  XXXXLarge: 128,
  XXXLarge: 96,
  XXLarge: 72,
  XLarge: 64,
  Large: 48,
  Medium: 32,
  Small: 24,
  XSmall: 20,
  XXSmall: 16,
}
export const Margin = {
  XXXLarge: 96,
  XXLarge: 72,
  XLarge: 64,
  Large: 48,
  Medium: 32,
  Small: 24,
  XSmall: 20,
  XXSmall: 16,
}
