import React from 'react'

const LogoDisplay = ({ viewOptions, options }) => {
  const svgStyles = { ...{ width: 210, height: 38, backgroundColor: 'transparent' }, ...options }
  const viewStyles = { ...{ justifyContent: 'center', alignItems: 'center' }, ...viewOptions }

  return (
    <div style={viewStyles}>
      <img style={svgStyles}
        alt="jabbrrbox_logo"
        src={`${process.env.PUBLIC_URL}/img/jabbrrbox_logo.svg`} />
    </div>
  )
}
export default LogoDisplay
