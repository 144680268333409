import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../actions'
import LogoDisplay from './shared/LogoDisplay'
import Divider from 'material-ui/Divider'
import { ContainerStyle, Padding } from '../styles/style.format'


class ThankYouScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      holdingTimeout: null,
    }
  }

  componentDidMount() {
    this.setState({
      holdingTimeout: setTimeout(() => {
        this.props.navigate('InternalHoldingScreen')
      },
      this.props.config.thankyouTimeout || 120000),
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reservation) {
      clearTimeout(this.state.holdingTimeout)
      this.props.navigate('InternalWelcome')
    }
  }

  render() {
    return (
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch',
        alignContent: 'stretch', width: '100%', height: '100%',
        backgroundColor: this.props.styles.Color.NeutralDark,
      }}>
        <div style={{
          display: 'flex', flex: 1, flexDirection: 'column', ...ContainerStyle,
          justifyContent: 'center', alignItems: 'stretch', alignContent: 'stetch',
        }}>
          <LogoDisplay viewOptions={{ width: 475, height: 175 }} options={{ width: 475, height: 105 }} />
          <Divider style={{ backgroundColor: this.props.styles.Color.Neutral, width: 475 }} />
          <div style={{ ...this.props.styles.InternalWelcomeText, marginTop: Padding.XLarge }}>
            <div>
              Thank you for using jabbrrbox.
            </div>
            <div>
              Your session has expired.
            </div>
            <br />
            <div style={this.props.styles.InternalWelcomeSubText}>
              Please exit and reserve again if you need more time.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reservation: state.doorState.jabbrrboxReservation,
    styles: state.Style.theme,
    config: state.configState.conf,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouScreen)
