import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LogoWithNavigation from './signupScreen/LogoWithNavigation'
import TermsAndConditions from './signupScreen/TermsAndConditions'
import { Textfit } from 'react-textfit'
import { Margin, Padding, ContainerStyle, PageSpinner } from '../styles/style.format'
import Divider from 'material-ui/Divider'
import FlatButton from 'material-ui/FlatButton'
import Checkbox from 'material-ui/Checkbox'

const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

class ReservationConfirmationScreen extends Component {

  constructor(props) {
    super(props)
    this.isLoading = false

    this.state = {
      showTermsAndConditions: false,
      termsAndConditionsAccepted: false,
      navTimer: null,
    }

    this.toggleShowTermsAndConditions = this.toggleShowTermsAndConditions.bind(this)
    this.toggleTermsAndConditionsAccept = this.toggleTermsAndConditionsAccept.bind(this)
    this.handlePayNow = this.handlePayNow.bind(this)
  }

  componentDidMount() {
    this.props.pageLoadStop()
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    this.stopNavigationTimer()
  }

  startNavigationTimer() {
    this.stopNavigationTimer()

    this.setState({
      navTimer: setTimeout(() => {
        this.props.logEvent('Payment Workflow', 'Timeout', { evLabel: 'ReservationConfirmation', evValue: this.props.config.signUpPageTimeout || 30000 })
        this.props.navigate('Welcome')
      }, parseFloat(this.props.config.signUpPageTimeout) || 30000),
    })
  }

  stopNavigationTimer() {
    clearTimeout(this.state.navTimer)
  }

  toggleShowTermsAndConditions() {
    if (!this.isLoading) {
      this.setState({
        showTermsAndConditions: !this.state.showTermsAndConditions,
      })
      this.startNavigationTimer()
    }
  }

  toggleTermsAndConditionsAccept() {
    this.setState({
      termsAndConditionsAccepted: !this.state.termsAndConditionsAccepted,
      selected: 'accept',
    })
  }

  getAcceptButtonStyles() {
    if (this.state.termsAndConditionsAccepted) {
      return this.props.styles.PrimaryButton
    } else {
      return { ...this.props.styles.PrimaryButton, opacity: '.5' }
    }
  }

  getAcceptCheckboxStyles() {
    const defaultStyle = {
      width: 40,
      height: 40,
      marginRight: 30,
    }

    return {
      ...defaultStyle,
      fill: this.props.styles.Color.BrandPrimary,
    }
  }

  getEmailSection() {
    return (
      <div style={{ marginBottom: Margin.XXLarge }}>
        <div style={{ ...this.props.styles.InfoText }}>After payment your receipt will be sent to</div>
        <div style={{ marginTop: Margin.Small, marginBottom: Margin.XSmall }}>
          <Textfit
            mode="single"
            forceSingleModeWidth={true}
            max={this.props.styles.H3.fontSize}
            style={this.props.styles.H3}>
            {this.props.email}
          </Textfit>
        </div>
        <div>
          <FlatButton
            style={this.props.styles.LinkButton}
            label={'Re-enter Email Address'}
            labelPosition="before"
            labelStyle={this.props.styles.LinkButtonLabel}
            onClick={() => {
              this.props.navigate('Email')
            }}
            disabled={this.isLoading}
          />
        </div>
      </div>
    )
  }

  getPhoneSection() {
    let phone = this.props.mobile
    try {
      phone = phoneUtil.format(phoneUtil.parse(this.props.mobile, this.props.country.code.toUpperCase()), PNF.INTERNATIONAL)
    } catch (e) {
      //nothing
    }

    return (
      <div style={{ marginBottom: Margin.XXXLarge }}>
        <div style={{ ...this.props.styles.InfoText }}>Your code for re-entry & wifi code will be sent to</div>
        <div style={{ marginTop: Margin.Small, marginBottom: Margin.XSmall }}>
          <Textfit
            mode="single"
            forceSingleModeWidth={true}
            max={this.props.styles.H3.fontSize}
            style={this.props.styles.H3}>
            {phone}
          </Textfit>
        </div>
        <div>
          <FlatButton
            style={this.props.styles.LinkButton}
            label={'Re-enter Phone Number'}
            labelPosition="before"
            labelStyle={this.props.styles.LinkButtonLabel}
            onClick={() => {
              this.props.navigate('PageOne')
            }}
            disabled={this.isLoading}
          />
        </div>
      </div>
    )
  }

  getTermsAndConditionsSection() {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: Padding.XSmall,
        marginBottom: Margin.Large,
      }}>
        <div>
          <Checkbox
            iconStyle={this.getAcceptCheckboxStyles()}
            checked={this.state.termsAndConditionsAccepted}
            onCheck={this.toggleTermsAndConditionsAccept}
            disabled={this.isLoading}
          />
        </div>
        <span style={this.props.styles.InfoText}>
          I accept the&nbsp;
          <span
            style={this.props.styles.LinkButtonLabel}
            onClick={this.toggleShowTermsAndConditions}>
            Terms and Conditions
          </span>
        </span>
      </div>
    )
  }

  handlePayNow() {
    try {
      const mobile = phoneUtil.format(phoneUtil.parse(this.props.mobile, this.props.country.code), PNF.E164)
      this.isLoading = true
      this.forceUpdate()
      this.props.signUpAndMakeReservation(
        {
          email: this.props.email,
          mobile,
        },
        this.props.config.jabbrrbox.id,
        this.props.payment.selectedPriceOption,
        this.props.partner ? this.props.partner.id : null
      )
      this.stopNavigationTimer()
    } catch (e) {
      this.stopNavigationTimer()
      this.setState(() => {
        throw new Error(e)
      })
    }
  }

  render() {
    if (this.state.showTermsAndConditions) {
      return (
        <TermsAndConditions toggleShowTermsAndConditions={this.toggleShowTermsAndConditions} styles={this.props.styles} />
      )
    } else {
      const isFree = parseFloat(this.props.payment.selectedPriceOption.pricingPlan.cost || 0) === 0
      return (
        <div>
          { !this.props.pageLoaded && <div style={ PageSpinner }>
            <img alt="Loading" src={`${process.env.PUBLIC_URL}/img/spinner.svg`} />
          </div> }
          { this.props.pageLoaded && <div style={ContainerStyle}>
            <div>
              <LogoWithNavigation styles={this.props.styles} hideButtons={this.isLoading}
                leftFunction={() => {
                  this.stopNavigationTimer()
                  this.props.navigate('Email')
                }}
                closeFunction={() => {
                  this.stopNavigationTimer()
                  this.props.navigate('Welcome')
                }}
              />
            </div>

            <Divider />

            <div style={{
              ...this.props.styles.H2,
              marginTop: Margin.XXLarge,
              marginBottom: Margin.XXLarge,
            }}>Hello</div>
            {this.getEmailSection()}
            {this.getPhoneSection()}
            {this.getTermsAndConditionsSection()}

            <div>
              {!this.isLoading &&
                <FlatButton
                  style={this.state.termsAndConditionsAccepted ? this.props.styles.PrimaryButtonTall : this.props.styles.PrimaryButtonTallDisabled}
                  label={isFree ? 'Unlock Door' : 'Pay Now'}
                  labelPosition="before"
                  labelStyle={this.props.styles.PrimaryButtonTallLabel}
                  primary={true}
                  disabled={!this.state.termsAndConditionsAccepted}
                  onClick={() => this.handlePayNow()}
                />}
              {this.isLoading &&
                <img style={{ width: 60, height: 60 }} alt="Loading" src={`${process.env.PUBLIC_URL}/img/spinner.svg`} />
              }
            </div>
          </div> }
        </div>
      )
    }
  }
}

ReservationConfirmationScreen.propTypes = {
  styles: PropTypes.object,

}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    pageLoaded: state.pageLoaded,
    person: state.SignUp.registeredPerson,
    config: state.configState.conf,
    payment: state.payment,
    email: state.SignUp.email,
    mobile: state.SignUp.mobile,
    country: state.SignUp.country,
    partner: state.partnerPricingPlan.selectedPartner,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ReservationConfirmationScreen)
