import React, { Component } from 'react'
import Button from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 60,
  },
}
export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = { email: '', password: '' }
  }
  login() {
    this.props.login(this.state.email, this.state.password)
  }
  setPassword(event, password) {
    this.setState({ password })
  }
  setEmail(event, email) {
    this.setState({ email })
  }

  render() {
    return (
      <form action="/" method="POST" style={styles.container} onSubmit={event => {
        this.login()
        event.preventDefault()
      }}>
        <TextField hintText="Email Address" onChange={this.setEmail.bind(this)}/>
        <TextField hintText="Password" type="password" onChange={this.setPassword.bind(this)}/>
        <Button type={'submit'}>Log In</Button>
      </form>
    )
  }
}
