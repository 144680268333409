import React, { Component } from 'react'
import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FlatButton from 'material-ui/FlatButton'
import Divider from 'material-ui/Divider'
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right'
import LogoDisplay from './shared/LogoDisplay'
import { ContainerStyle, Padding } from '../styles/style.format'


class InternalWelcomeScreen extends Component {

  constructor(props) {
    super(props)

    this.getStarted = this.getStarted.bind(this)
  }

  getStarted() {
    this.props.setSelectedMenuItemByName('Home')
    this.props.navigate('InternalHome')
  }

  render() {
    return (
      <div style={{
        display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'stretch',
        alignContent: 'stretch', width: '100%', height: '100%',
        backgroundColor: this.props.styles.Color.NeutralDark,
      }}
      onClick={this.getStarted}>
        <div style={{
          display: 'flex', flex: 1, flexDirection: 'column', ...ContainerStyle,
          justifyContent: 'center', alignItems: 'stretch', alignContent: 'stetch',
        }}>
          <LogoDisplay viewOptions={{ width: 475, height: 175 }} options={{ width: 475, height: 105 }} />
          <Divider style={{ backgroundColor: this.props.styles.Color.Neutral, width: 475 }} />
          <div style={{ ...this.props.styles.InternalWelcomeText, marginTop: Padding.XLarge }}>
            <div>
              {`Welcome to your private office.`}
            </div>
          </div>

          <div>
            <FlatButton
              style={{ ...this.props.styles.LinkWithIconButton, marginTop: Padding.Large, marginLeft: -15 }}
              label={'GET STARTED'}
              labelPosition="before"
              primary={true}
              icon={<ChevronRight />}
              onClick={this.getStarted}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reservation: state.doorState.jabbrrboxReservation,
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalWelcomeScreen)
