import * as door from '../actions/door'
import * as signUp from '../actions/sign.up'

const INITIAL_STATE = {
  locked: true,
  code: undefined,
  doorError: false,
  attemptUnlock: false,
  reservationId: undefined,
  confirmationCode: undefined,
  isLoading: false,
}

/*eslint complexity: ["off"]*/ /*Complexity reflects number  of door.js actions and can't be easily reduced*/
export const doorState = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case door.CODE_ENTERED:
      return { ...state, code: payload }
    case door.DOOR_IS_OPENED:
      return {
        ...state,
        locked: false,
        jabbrrboxReservation: payload.jabbrrboxReservation,
        person: payload.jabbrrboxReservation.person ? payload.jabbrrboxReservation.person : state.person,
      }
    case door.DOOR_IS_LOCKED:
      return {
        ...state,
        locked: true,
      }
    case door.DOOR_REQUEST_OPEN_STARTED:
      return { ...state, attemptUnlock: true }
    case door.DOOR_REQUEST_OPEN_STOPPED:
      return { ...state, attemptUnlock: false }
    case door.DOOR_REQUEST_OPEN_ERROR:
      return { ...state, doorError: true, attemptUnlock: false }
    case door.CLEAR_DOOR_REQUEST_OPEN_ERROR:
      return { ...state, doorError: false }
    case door.RESERVATION_REQUEST_STOPPED:
      return { ...state, jabbrrboxReservation: payload, person: payload ? payload.person : undefined }
    case door.LOAD_JABBRRBOX_REQUEST_STOPPED:
      return { ...state, jabbrrbox: payload }
    case door.CANCEL_RESERVATION_STARTED:
      return { ...state, isLoading: true }
    case door.CANCEL_RESERVATION_STOPPED:
      return { ...state, isLoading: false, jabbrrboxReservation: null }
    case door.CANCEL_RESERVATION_ERROR:
      return { ...state, isLoading: false }
    case signUp.START_RESERVATION_REQUEST:
      return { ...state, reservationId: undefined, confirmationCode: undefined, isLoading: true }
    case signUp.STOP_RESERVATION_REQUEST:
      return { ...state, jabbrrboxReservation: payload, reservationId: payload.id, confirmationCode: payload.confirmationCode, isLoading: false }
    case signUp.STOP_SIGNUP_REQUEST:
      return { ...state, person: payload }
    case door.CONFIRM_RESERVATION_REQUEST_STOPPED:
      return { ...state, jabbrrboxReservation: payload, person: payload.person }
    default:
      return state
  }
}
