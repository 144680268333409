import * as remoteActions from '../actions/remote.actions'

const INITIAL_STATE = {
  isLoading: false,
  remoteActions: [],
  error: undefined,
}

export const remoteActionState = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case remoteActions.EXECUTE_REMOTE_ACTIONS_STARTED:
      return { ...state, isLoading: true }
    case remoteActions.EXECUTE_REMOTE_ACTIONS_STOPPED:
      return { ...state, isLoading: false, remoteActions: payload }
    case remoteActions.EXECUTE_REMOTE_ACTIONS_ERROR:
      return { ...state, isLoading: false, error: payload }
    default:
      return state
  }
}
