import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'
import { ContainerStyle, Padding } from '../styles/style.format'
import InputLabel from './shared/InputLabel'

const deepmerge = require('deepmerge')

const pathToOptionsMap = {
  'jabbrrbox.mode': [
    <option key="INTERNAL" value="INTERNAL">Internal</option>,
    <option key="EXTERNAL" value="EXTERNAL">External</option>,
  ],
}
const pathToinputFieldTypeMap = {
  'jabbrrbox.password': 'password',
  testing: 'checkbox',
}

class ConfigScreen extends Component {
  constructor(props) {
    super(props)
    this.keyIndex = 0
    this.state = {
      config: { ...props.config },
      canConfigure: !props.configError,
    }
  }
  saveConfig() {
    const conf = {
      ...this.state.config,
    }
    this.props.updateConfig(conf)
    this.props.navigate('Welcome')
  }

  buzzDoor() {
    this.props.unlockDoor()
  }

  stopTimer() {
    clearTimeout(this.state.timer)
  }

  assign(prop, value) {
    const obj = {}
    if (typeof prop === 'string') {
      prop = prop.split('.')
    }

    if (prop.length > 1) {
      const e = prop.shift()
      obj[e] = this.assign(prop, value)
    } else {
      obj[prop[0]] = value
    }

    return obj
  }

  generateSectionHeader(name) {
    return <div key={`${name}_${this.keyIndex++}`} style={{
      ...this.props.styles.H3,
      textTransform: 'uppercase',
    }}>{name}</div>
  }

  generateCheckboxOption(name, path, value) {
    return (
      <div style={{ paddingBottom: Padding.XLarge }}>
        <InputLabel title={name.replace(/([a-z](?=[A-Z]))/g, '$1 ').toUpperCase()} styles={this.props.styles} />
        <input
          type="checkbox"
          name={name}
          onChange={event => {
            this.setState({
              config: deepmerge(this.state.config, this.assign(`${path}`, event.target.checked)),
            })
          }}
          checked={value}
          value={value}
        />
      </div>
    )
  }

  generateSelectOption(name, path, value, options) {
    return (
      <div style={{ paddingBottom: Padding.XLarge }}>
        <InputLabel title={name.replace(/([a-z](?=[A-Z]))/g, '$1 ').toUpperCase()} styles={this.props.styles} />
        <select
          value={value}
          onChange={event => {
            this.setState({
              config: deepmerge(this.state.config, this.assign(`${path}`, event.target.value)),
            })
          }}
        >
          {options}
        </select>
      </div>
    )
  }

  generateOption(name, path, value) {
    if (pathToOptionsMap[path]) {
      return this.generateSelectOption(name, `${path}`, value, pathToOptionsMap[path])
    } else if (pathToinputFieldTypeMap[path] === 'checkbox') {
      return this.generateCheckboxOption(name, `${path}`, value)
    }
    return (
      <div style={{ paddingBottom: Padding.XLarge }}>
        <InputLabel title={name.replace(/([a-z](?=[A-Z]))/g, '$1 ').toUpperCase()} styles={this.props.styles} />
        <TextField
          name={name}
          type={pathToinputFieldTypeMap[path] || 'text'}
          onChange={event => {
            this.setState({
              config: deepmerge(this.state.config, this.assign(`${path}`, event.target.value)),
            })
          }}
          value={value}
        />
      </div>
    )
  }

  generateOptions(object, name, prefix) {
    const options = []
    if (object === null || object === undefined) {
      return options
    }
    if (typeof object === 'object') {
      options.push(this.generateSectionHeader(name))
      Object.keys(object).map(child => {
        const childObject = object[child]
        const childPath = prefix ? `${prefix}.${child}` : child
        const opt = this.generateOptions(childObject, child, childPath)
        options.push(opt)
        return opt
      })
    } else {
      options.push(this.generateOption(name, prefix, object))
    }
    return options
  }

  render() {
    return (
      <div style={{ flexDirection: 'column', flex: 1, backgroundColor: this.props.styles.Color.White }} >
        <div style={{ ...ContainerStyle }} >
          <div style={{ ...this.props.styles.H2, paddingBottom: Padding.Large, textAlign: 'center' }}>{`Config`}</div>

          {this.state.canConfigure &&
            <div>
              <div style={{ paddingBottom: Padding.XLarge }}>
                <FlatButton
                  style={this.props.styles.PrimaryButton}
                  label={'Unlock Door'}
                  labelPosition="before"
                  primary={true}
                  onClick={this.buzzDoor.bind(this)}
                />
              </div>

              {this.generateOptions(this.state.config)}

              <div style={{ paddingTop: 50 }}>
                <FlatButton
                  disabled={!this.state.canConfigure || this.props.saving || !this.props.isLoaded}
                  style={this.props.styles.PrimaryButton}
                  label={'Submit'}
                  labelPosition="before"
                  primary={true}
                  onClick={this.saveConfig.bind(this)}
                />
              </div>
            </div>
          }
          {!this.state.canConfigure &&
            <div>CONFIGURATION DISABLED</div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {

    config: state.configState.conf,
    configError: state.configState.error,
    styles: state.Style.theme,
    saving: state.configState.saving,
    isLoaded: state.configState.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfigScreen)
