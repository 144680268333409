import React, { Component } from 'react'
import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import RemainingComponent from './RemainingComponent'
import CurrentTimeComponent from './CurrentTimeComponent'

class CountdownComponent extends Component {

  render() {
    if (this.props.reservation) {
      return (

        <div style={this.props.styles.CountdownContainer}>
          <div style={this.props.styles.CountdownInnerContainer}>
            <CurrentTimeComponent styles={this.props.styles} />
            <div style={this.props.styles.CountdownVerticalDivider} />
            <RemainingComponent navigate={this.props.navigate} styles={this.props.styles} reservation={this.props.reservation} />
          </div>
        </div>
      )
    } else {
      this.props.navigate('ThankYou')
      return (
        <div />
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    reservation: state.doorState.jabbrrboxReservation,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CountdownComponent)
