import * as marketingCardActions from '../actions/marketing.card'

const INITIAL_STATE = {
  isLoading: false,
  cards: undefined,
  error: undefined,
}

export const marketingCard = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case marketingCardActions.LOAD_MARKETING_CARD_STARTED:
      return { ...state, isLoading: true }
    case marketingCardActions.LOAD_MARKETING_CARD_STOPPED:
      return { ...state, isLoading: false, cards: payload }
    case marketingCardActions.LOAD_MARKETING_CARD_ERROR:
      return { ...state, isLoading: false, error: payload }
    default:
      return state
  }
}
