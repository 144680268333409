import * as config from '../actions/config'
import * as door from '../actions/door'

const deepmerge = require('deepmerge')

const INITIAL_STATE = {
  loaded: false,
  localConfigLoaded: false,
  loggedIn: false,
  saving: false,
  conf: {},
}

export const configState = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case door.LOAD_JABBRRBOX_REQUEST_STOPPED :
      return { ...state, conf: deepmerge(state.conf, payload.jabbrrboxConfig ? payload.jabbrrboxConfig.config : {}), loaded: true }
    case config.LOAD_CONFIG_STARTED :
      return { ...state, localConfigLoaded: false }
    case config.LOAD_CONFIG_ERROR:
      return { ...state, localConfigLoaded: false, error: payload }
    case config.LOAD_CONFIG_STOPPED :
      return { ...state, conf: { ...payload }, localConfigLoaded: true }
    case config.UPDATE_CONFIG_STARTED :
      return { ...state, saving: true }
    case config.UPDATE_CONFIG_ERROR :
      return { ...state, saving: false, error: payload.error }
    case config.UPDATE_CONFIG_STOPPED :
      return { ...state, conf: { ...payload }, saving: false }
    case config.LOGGED_IN:
      return { ...state, ...{ loggedIn: true, loggedInKey: payload }}
    default:
      return state
  }
}
