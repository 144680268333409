import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FlatButton from 'material-ui/FlatButton'
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right'
import React, { Component } from 'react'
import TopComponent from './welcomeScreen/TopComponent'
import MidComponent from './welcomeScreen/MidComponent'
import BottomComponent from './welcomeScreen/BottomComponent'
import { ContainerStyle } from '../styles/style.format'

class WelcomeScreen extends Component {
  componentDidMount() {
    this.props.clearSignUp()
    this.props.clearPartnerPricing()
    this.props.clearPaymentInformation()
    this.props.clearClientId()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reservation) {
      this.props.navigate('RoomReserved')
    }
  }

  render() {
    return (
      <div
        tabIndex="0"
        style={{
          flexDirection: 'column',
          display: 'flex', flex: 1,
          textAlign: 'center',
          height: '100%',
          backgroundColor: this.props.styles.Color.White,
        }}>
        <TopComponent jabbrrbox={this.props.jabbrrbox} styles={this.props.styles} />
        <div style={{ ...ContainerStyle, display: 'flex', flexDirection: 'column', flexGrow: 2 }}>
          <MidComponent />
          {this.props && this.props.config && this.props.config.testing &&
            <div>
              <div style={{ marginTop: 20 }}>
                <FlatButton
                  style={this.props.styles.PrimaryButton}
                  label={'Start Internal App'}
                  labelPosition="before"
                  primary={true}
                  icon={<ChevronRight />}
                  onClick={() => {
                    this.props.navigate('InternalWelcome')
                  }}
                />
              </div>
              <div>
                <FlatButton
                  style={this.props.styles.PrimaryButton}
                  label={'Enter Access Code'}
                  labelPosition="before"
                  primary={true}
                  icon={<ChevronRight />}
                  onClick={() => {
                    this.props.navigate('EnterPin')
                  }}
                />
              </div>
              <div>
                <FlatButton
                  style={this.props.styles.PrimaryButton}
                  label={'Config Mode'}
                  labelPosition="before"
                  primary={true}
                  icon={<ChevronRight />}
                  onClick={() => {
                    this.props.navigate('Config')
                  }}
                />
              </div>
              <div>
                <FlatButton
                  style={this.props.styles.PrimaryButton}
                  label={'Room Reserved'}
                  labelPosition="before"
                  primary={true}
                  icon={<ChevronRight />}
                  onClick={() => {
                    this.props.navigate('RoomReserved')
                  }}
                />
              </div>
              <div>
                <FlatButton
                  style={this.props.styles.PrimaryButton}
                  label={'Pricing Options'}
                  labelPosition="before"
                  primary={true}
                  icon={<ChevronRight />}
                  onClick={() => {
                    this.props.navigate('PricingOptions')
                  }}
                />
              </div>
            </div>
          }
          <BottomComponent navigation={this.props} styles={this.props.styles} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    reservation: state.doorState.jabbrrboxReservation,
    config: state.configState.conf,
    jabbrrbox: state.doorState.jabbrrbox,
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen)
