import React from 'react'
import PropTypes from 'prop-types'
import { Margin } from '../styles/style.format'

const InternalSupportScreen = ({ styles }) => {
  return (
    <div style={{
      marginLeft: Margin.XLarge,
      marginBottom: Margin.XLarge,
      marginRight: Margin.XLarge,
      marginTop: Margin.XXLarge,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div>
        <div style={{ ...styles.InternalHeader }}>Need Help? Contact our Support Team.</div>
        <div style={{ ...styles.InternalSubHeader, color: styles.Color.Neutral, marginTop: Margin.Medium }}>Support@Jabbrrbox.com</div>
      </div>
      <div style={{ marginTop: Margin.XXXLarge }}>
        <div style={{ ...styles.InternalHeader }}>Visit our Website!</div>
        <div style={{ ...styles.InternalSubHeader, color: styles.Color.Neutral, marginTop: Margin.Medium }}>www.jabbrrbox.com</div>
      </div>
    </div>
  )
}
InternalSupportScreen.propTypes = {
  styles: PropTypes.object,
}
export default InternalSupportScreen
