import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import InputLabel from '../shared/InputLabel'
import TextIn from '../shared/TextIn'
import { getTextInputBorder, Padding, Margin } from '../../styles/style.format'
import Divider from 'material-ui/Divider'
import FlatButton from 'material-ui/FlatButton'


const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()


//TODO: keyboardType doesn't seem to have any that are _just_ numbers. Make our own component?
//const textOne = StyleSheet.flatten([styles.bodyText, extStyles.smallFont])
class EnterAccesssCodeComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      accessCode: '',
      accessDoorCalled: false,
    }
  }
  componentDidUpdate() {
    if (this.state.accessCode.length === 6 && !this.props.attemptUnlock && !this.state.accessDoorCalled) {
      this.props.logEvent('Payment Workflow', 'Enter Access Code Success')
      this.openDoor()
    } else if (this.state.accessCode.length > 0 && this.props.doorError) {
      this.props.clearDoorRequestOpenError()
    }
  }
  getBorder() {
    const valid = this.state.accessCode.length === 6 && !this.props.doorError
    return {
      ...getTextInputBorder({ focused: true, valid, focusedOnce: this.props.doorError, useColors: true, styles: this.props.styles }),
      ...{ width: '100%', height: 50, display: 'flex', alignItems: 'center' },
    }
  }
  openDoor() {
    this.setState({
      accessDoorCalled: true,
    })
    this.props.openDoor(this.state.accessCode, this.props.config.jabbrrbox.id)
  }
  componentWillUpdate(nextProps) {
    if (this.props.attemptUnlock && !nextProps.attemptUnlock) {
      this.setState({
        accessCode: '', accessDoorCalled: false,
      })
      this.clearText()
    }
  }
  clearText() {
    document.getElementById('accessCode_id').value = ''
  }
  setAccessCode(code) {
    if (this.props.onChange) {
      this.props.onChange(code)
    }
    this.setState({ accessCode: code })
  }

  getDiscretePhone() {
    let formattedPhone = this.props.person ? this.props.person.mobile : ''
    try {
      formattedPhone = phoneUtil
        .format(phoneUtil.parse(formattedPhone, this.props.countryCode, PNF.INTERNATIONAL), PNF.INTERNATIONAL).replace(/-/g, ' ')
    } catch (e) {
      //nothing
    }

    const splitNumber = formattedPhone.split(' ')

    const discretePhone = splitNumber.map((part, index) => {
      if (index === 0) {
        return <div key={index} style={{ marginRight: 2, marginLeft: 2 }}>{part}</div>
      } else if (index + 1 === splitNumber.length) {
        return <div key={index}>{part}</div>
      } else {
        return <div key={index}>{this.getDiscretePart(part)}</div>
      }
    })
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {discretePhone}
      </span>
    )
  }

  getDiscretePart(part) {
    const splitPart = part.split('')
    const circles = splitPart.map(index => {
      return <div key={index} style={this.props.styles.DiscretePhoneCircle} />
    })
    return (
      <div style={{ display: 'flex', flex: 1, alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
        {circles}&nbsp;-
      </div>
    )
  }

  handleResendCode() {
    if (this.props.reservation) {
      this.props.logEvent('Payment Workflow', 'Resend Access Code')
      this.props.resendCode(this.props.reservation.id)
    }
  }

  render() {
    const error = this.props.doorError
    return (
      <div>
        <Divider style={{ ...this.props.styles.ExternalDivider }} />
        <div style={{ paddingTop: Padding.Large }}>
          <InputLabel title={this.props.labelText} subText={''} extraSubText={''} styles={this.props.styles} />
          <div style={{
            ...this.props.styles.DetailText,
            marginTop: Margin.XXSmall,
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
          }}>This Jabbrrbox is reserved for {this.getDiscretePhone()}</div>
          <div style={{ paddingTop: Padding.Small }} />
          <TextIn type={'numeric'} displayType={'text'} id={'accessCode'}
            selected={true} styles={this.props.styles}
            onChange={this.setAccessCode.bind(this)} value={this.state.accessCode}
            placeholder={'Enter Your Access Code'}
            isSubmitEnabled={() => {
              // eslint-disable-next-line
              this.state.accessCode.length === 6 && !this.state.isLoading
            }}
            getBorder={this.getBorder.bind(this)}
            maxLength={6}
          />
          {error && <div style={{ ...this.props.styles.ErrorText, paddingTop: Padding.XSmall }}>{'Access Code Denied'} </div>}
          {!error && <div style={{ paddingTop: Padding.XXSmall }}>&nbsp;</div>}
        </div>
        <div>
          <FlatButton
            style={this.props.styles.LinkButton}
            label={'Re-send Access Code'}
            labelPosition="before"
            labelStyle={this.props.styles.LinkButtonLabel}
            onClick={() => {
              this.handleResendCode()
            }}
          />
        </div>
      </div>

    )
  }
}

const mapStateToProps = state => {
  return {
    config: state.configState.conf,
    styles: state.Style.theme,
    doorError: state.doorState.doorError,
    person: state.doorState.person,
    attemptUnlock: state.doorState.attemptUnlock,
    reservation: state.doorState.jabbrrboxReservation,
    countryCode: state.SignUp.country.code,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EnterAccesssCodeComponent)
