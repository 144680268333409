import React, { Component } from 'react'
import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FlatButton from 'material-ui/FlatButton'
import { Padding, Margin } from '../../styles/style.format'

const internalStyle = {
  expandedContainer: {
    flexDirection: 'column',
    flexGrow: 2,
    alignItems: 'center',
    paddingBottom: Padding.XLarge,
  },
  container: {
    flexDirection: 'column',
    flexGrow: 2,
    alignItems: 'center',
    paddingBottom: Padding.Small,
  },
}

class Motto extends Component {

  constructor(props) {
    super(props)

    this.handleBookNowClick = this.handleBookNowClick.bind(this)
  }

  handleBookNowClick() {
    if (this.props.jabbrrboxPricingPlan.plans.length > 0 || this.props.locationPricingPlan.plans.length > 0) {
      this.props.navigation.navigate('PricingOptions')
    } else {
      this.props.navigation.navigate('PageOne')
    }
  }

  render() {
    const jabbrrbox = this.props.jabbrrbox

    let bottomTextStyle = {
      ...this.props.styles.LinkButton,
      marginTop: null,
      marginBottom: null,
    }

    let outerContainerStyle = internalStyle.container
    if (jabbrrbox && !jabbrrbox.sponsorId) {
      outerContainerStyle = internalStyle.expandedContainer
      bottomTextStyle.marginTop = Margin.Medium
      bottomTextStyle.marginBottom = Margin.Medium
    }

    return (
      <div style={outerContainerStyle}>

        <div style={{
          ...this.props.styles.MottoText,
          textAlign: 'center',
        }}>
            Your private office,<br />whenever you need it.
        </div>
        <div>
          <FlatButton
            style={{ ...this.props.styles.PrimaryButtonTall, marginTop: Margin.Large }}
            label={'Book Now'}
            labelPosition="before"
            labelStyle={this.props.styles.PrimaryButtonTallLabel}
            primary={true}
            onClick={() => this.handleBookNowClick()}
          />
        </div>
        { jabbrrbox && jabbrrbox.partners.length > 0 && <div>
          <FlatButton
            style={{
              ...this.props.styles.LinkButton,
              marginTop: Margin.Small,
              marginBottom: Margin.Small,
            }}
            label={'I have a promotional code '}
            labelPosition="before"
            labelStyle={this.props.styles.LinkButtonLabel}
            primary={true}
            onClick={() => {
              this.props.navigation.navigate('PartnerOptions')
            }}
          />
        </div> }
        <div>
          <FlatButton
            style={bottomTextStyle}
            label={'Learn More'}
            labelPosition="before"
            labelStyle={this.props.styles.LinkButtonLabel}
            primary={true}
            onClick={() => {
              this.props.navigation.navigate('TellMeMore')
            }}
          />
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    locationPricingPlan: state.locationPricingPlan,
    jabbrrboxPricingPlan: state.jabbrrboxPricingPlan,
    jabbrrbox: state.doorState.jabbrrbox,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Motto)
