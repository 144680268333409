import * as Navigation from './nav'
import * as paymentService from '../services/payment.service'
import * as signupActions from './sign.up'
import * as Door from './door'

export const CLEAR_PAYMENT_INFO = 'CLEAR_PAYMENT_INFO'
export const CLEAR_PAYMENT_TRANSACTION_INFO = 'CLEAR_PAYMENT_TRANSACTION_INFO'
export const SETUP_PAYMENT_STARTED = 'SETUP_PAYMENT_STARTED'
export const SETUP_PAYMENT_STOPPED = 'SETUP_PAYMENT_STOPPED'
export const SETUP_PAYMENT_ERROR = 'SETUP_PAYMENT_ERROR'

export const SET_SELECTED_PRICING_PLAN = 'SET_SELECTED_PRICING_PLAN'

export const setSelectedPricingOption = pricingOption => ({ type: SET_SELECTED_PRICING_PLAN, payload: pricingOption })

export const clearPaymentInformation = () => ({ type: CLEAR_PAYMENT_INFO })
export const clearPaymentTransactionInfo = () => ({ type: CLEAR_PAYMENT_TRANSACTION_INFO })
export const setupPaymentStarted = () => ({ type: SETUP_PAYMENT_STARTED })
export const setupPaymentStopped = data => ({ type: SETUP_PAYMENT_STOPPED, payload: data })
export const setupPaymentError = error => ({ type: SETUP_PAYMENT_ERROR, payload: error })
export const setupPayment = (jabbrrboxId, personId, pricingPlan, jabbrrboxReservationId) => {
  return (dispatch, getState) => {
    dispatch(setupPaymentStarted())
    const amount = parseFloat(pricingPlan.cost || 0).toFixed(2)
    paymentService.setupPayment(getState().configState.conf.payment, amount, jabbrrboxReservationId).then(data => {
      dispatch(setupPaymentStopped(data))
    }).catch(error => {
      dispatch(setupPaymentError(error))
    })
  }
}

export const setupReservationAndPayment = (jabbrrboxId, personId, selectedPricingOption, partnerId) => {
  const pricingPlan = selectedPricingOption.pricingPlan
  return (dispatch, getState) => {
    return dispatch(signupActions.makeReservation({
      jabbrrboxId,
      pricingPlanId: pricingPlan.id,
      personId,
      partnerId,
    })).then(() => {
      if (getState().doorState.jabbrrboxReservation.confirmationCode) {
        dispatch(Navigation.navigate('PayNow', selectedPricingOption))
        dispatch(setupPayment(jabbrrboxId, personId, pricingPlan, getState().doorState.jabbrrboxReservation.id))
      }
    })
  }
}
