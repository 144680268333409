import * as lightActions from '../actions/lights'

const INITIAL_STATE = {
  updating: false,
  lights: {},
  colorPickerLight: {},
  lightTimerMap: {},
}

const lightArrayToMap = lights => {
  return lights.reduce((map, light) => {
    map[light.id] = light
    return map
  }, {})
}

const updateLightInMap = (lights, light) => {
  lights[light.id] = light
  return lights
}

const updateLightTimerInMap = (lightTimerMap, lightTimer) => {
  lightTimerMap[lightTimer.lightId] = lightTimer.lightRotationId
  return lightTimerMap
}

const clearLightTimerInMap = (lightTimerMap, lightId) => {
  if (lightId) {
    clearInterval(lightTimerMap[lightId])
    lightTimerMap[lightId] = null
  }
  return lightTimerMap
}

export const lightState = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case lightActions.UPDATE_COLOR_PICKER_COLOR_STARTED:
      return { ...state, updating: true }
    case lightActions.UPDATE_COLOR_PICKER_COLOR_STOPPED:
      return { ...state, updating: false, colorPickerLight: payload, lights: updateLightInMap({ ...state.lights }, payload) }
    case lightActions.UPDATE_COLOR_PICKER_COLOR_ERROR:
      return { ...state, updating: false }
    case lightActions.UPDATE_LIGHT_STARTED:
      return { ...state, updating: true }
    case lightActions.UPDATE_LIGHT_STOPPED:
      return { ...state, updating: false, lights: updateLightInMap({ ...state.lights }, payload) }
    case lightActions.UPDATE_LIGHT_ERROR:
      return { ...state, updating: false }
    case lightActions.GET_LIGHTS_STOPPED:
      return { ...state, lights: lightArrayToMap(payload) }
    case lightActions.SET_LIGHT_TIMER_STOPPED:
      return {
        ...state, lightTimerMap: updateLightTimerInMap(state.lightTimerMap, payload),
      }
    case lightActions.CLEAR_LIGHT_TIMER_STOPPED:
      return {
        ...state, lightTimerMap: clearLightTimerInMap(state.lightTimerMap, payload),
      }
    default:
      return state
  }
}
