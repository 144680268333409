import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getTextInputBorder } from '../../styles/style.format'
import TextField from 'material-ui/TextField'
import { Textfit } from 'react-textfit'
import Keyboard from '../../keyboard/Keyboard'
import { connect } from 'react-redux'

const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

const getBorder = (validator, value, focused, focusedOnce, type, styles) => {
  const valid = validator(value)
  return getTextInputBorder({ focused, valid, focusedOnce, styles })

}
class TextIn extends Component {
  constructor(props) {
    super(props)
    this.state = { inputNode: undefined, firedOnce: false }
  }

  getInputNode() {
    const inputType = this.props.displayType === 'mobile' ? 'numeric' : 'text'
    return <TextField
      id={`${this.props.id}_id`}
      maxLength={this.props.maxLength}
      inputStyle={{ cursor: 'none', color: 'transparent' }}
      style={{ position: 'absolute' }}
      type={inputType}
      key={`${this.props.id}_id`}
      spellCheck={false}
      underlineShow={false}
      ref={input => {
        this.inputRef(input)
      }} />
  }
  getTextDisplay() {
    if ((!this.props.value || this.props.value === '') && this.props.placeholder) {
      const ph = this.props.displayType === 'mobile' ? phoneUtil.getExampleNumber(this.props.country, PNF.INTERNATIONAL) : this.props.placeholder
      return <div style={{ ...this.props.styles.InputTextMask, textAlign: 'center', width: '100%' }}>{ph}</div>
    }
    const val = this.props.displayType === 'mobile' ? phoneUtil.format(this.props.value, this.props.country, PNF.INTERNATIONAL) : this.props.value
    return <Textfit
      mode="single"
      forceSingleModeWidth={true}
      max={this.props.styles.InputText.fontSize}
      style={{ ...this.props.styles.InputText, textAlign: 'center', width: '100%' }}>
      {val}
    </Textfit>
  }
  renderKeyboard() {
    if (this.state.inputNode && this.props.selected) {
      return <div style={{ display: 'block', position: 'absolute', left: 0, bottom: 0, width: '100%' }}>
        <Keyboard
          type={this.props.type}
          inputNode={this.state.inputNode}
          handleSubmitButton={() => {
            this.props.onSubmitNext(this.props.id)
          }}
          isFirstLetterUppercase={this.props.isFirstLetterUppercase}
          submitText={this.props.submitText}
          isSubmitEnabled={this.props.isSubmitEnabled}
        /></div>
    } else {
      return ''
    }
  }
  inputRef(input) {
    if (input && !this.state.firedOnce) {
      input.input.value = this.props.value
      input.input.oninput = event => {
        if (this.props.maxLength > 0 && event.target.value.length > this.props.maxLength) {
          event.target.value = event.target.value.substring(0, this.props.maxLength)
        }
        this.props.onChange(event.target.value, this.props.id)
      }
      this.setState({ inputNode: input.input, firedOnce: true })
    }
  }
  render() {
    const InputNode = this.getInputNode()
    const border = this.props.getBorder ? this.props.getBorder() : { ...getBorder(this.props.validator, this.state.text, this.props.selected,
       this.state.firedOnce, this.props.type, this.props.styles), width: '100%', height: 96, display: 'flex', alignItems: 'center' }
    return (
      <div style={{ width: '100%' }} key={this.props.id}>
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <div id={this.props.id} onClick={() => this.props.onSelect && this.props.onSelect(this.props.id)}
            style={border}>
            {InputNode}
            {this.getTextDisplay()}
          </div>
        </div>
        {this.renderKeyboard()}
      </div>

    )
  }
}

TextIn.propTypes = {
  onSelect: PropTypes.func,
  validator: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
  }
}

export default connect(mapStateToProps, null)(TextIn)
