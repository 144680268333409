import * as partnerPricingPlanActions from '../actions/partner.pricing.plan'

const INITIAL_STATE = {
  selectedPartner: null,
  isLoading: false,
  plans: [],
  error: undefined,
}

export const partnerPricingPlan = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case partnerPricingPlanActions.LOAD_PARTNER_PRICING_PLANS_STARTED:
      return { ...state, isLoading: true }
    case partnerPricingPlanActions.LOAD_PARTNER_PRICING_PLANS_STOPPED:
      return { ...state, isLoading: false, plans: payload }
    case partnerPricingPlanActions.LOAD_PARTNER_PRICING_PLANS_ERROR:
      return { ...state, isLoading: false, error: payload }
    case partnerPricingPlanActions.SET_SELECTED_PARTNER:
      return { ...state, selectedPartner: payload }
    case partnerPricingPlanActions.CLEAR_PARTNER_PRICING:
      return INITIAL_STATE
    default:
      return state
  }
}
