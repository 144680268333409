import * as googleAnalyticsActions from '../actions/google.analytics'

const INITIAL_STATE = {
  clientId: null,
}

export const googleAnalytics = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case googleAnalyticsActions.SET_CLIENT_ID:
      return { ...state, clientId: payload }
    case googleAnalyticsActions.CLEAR_CLIENT_ID:
      return { ...state, clientId: null }
    default:
      return state
  }
}
