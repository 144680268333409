import { get } from '../util/services'

export const LOAD_PARTNER_PRICING_PLANS_STARTED = 'LOAD_PARNTER_PRICING_PLANS_STARTED'
export const LOAD_PARTNER_PRICING_PLANS_STOPPED = 'LOAD_PARNTER_PRICING_PLANS_STOPPED'
export const LOAD_PARTNER_PRICING_PLANS_ERROR = 'LOAD_PARNTER_PRICING_PLANS_ERROR'
export const SET_SELECTED_PARTNER = 'SET_SELECTED_PARTNER'
export const CLEAR_PARTNER_PRICING = 'CLEAR_PARTNER_PRICING'
export const loadPartnerPricingPlansStarted = () => ({ type: LOAD_PARTNER_PRICING_PLANS_STARTED })
export const loadPartnerPricingPlansStopped = plans => ({ type: LOAD_PARTNER_PRICING_PLANS_STOPPED, payload: plans })
export const loadPartnerPricingPlansError = error => ({ type: LOAD_PARTNER_PRICING_PLANS_ERROR, payload: error })
export const clearPartnerPricing = () => ({ type: CLEAR_PARTNER_PRICING })
export const loadPartnerPricingPlans = partnerId => {
  return (dispatch, getState) => {
    dispatch(loadPartnerPricingPlansStarted())
    return get(`/jabbrrboxPartners/${partnerId}/pricingPlans`, getState())
      .then(data => {
        return data.map(plan => {
          return { pricingPlan: plan }
        })
      })
      .then(plans => {
        dispatch(loadPartnerPricingPlansStopped(plans))
      })
      .catch(error => {
        dispatch(loadPartnerPricingPlansError(error))
      })
  }
}

export const setSelectedPartner = partner => ({ type: SET_SELECTED_PARTNER, payload: partner })
