import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import ColorPicker from './colorPicker/colorPicker'

class InternalColorScreen extends Component {
  onColorSelected(color) {
    this.props.updateColorPickerLight(this.props.config.lights.internal.id, color)
    if (this.props.config.lights.photoBooth.id) {
      const photoBoothColor = { ...color }
      photoBoothColor.brightness *= 0.7
      this.props.updateLight(this.props.config.lights.photoBooth.id, photoBoothColor)
    }
  }

  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
        <ColorPicker styles={this.props.styles} onColorSelected={this.onColorSelected.bind(this)} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    config: state.configState.conf,
    styles: state.Style.theme,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(InternalColorScreen)
