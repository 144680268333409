import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Padding } from '../styles/style.format'
import FlightSearchOptions from './flightStatusScreen/flight.search.options'
import FlightSearchInput from './flightStatusScreen/flight.search.input'
import FlightSearchTicketScan from './flightStatusScreen/flight.search.ticket.scan'
import FlightSearchResults from './flightStatusScreen/flight.search.results'

class InternalFlightStatusScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cameraTimer: null,
    }
  }

  getFlightSearchComponent(flightSearchState) {
    switch (flightSearchState) {
      case 'flightSearchOptions':
        return <FlightSearchOptions />
      case 'flightSearchInput':
        return <FlightSearchInput />
      case 'flightSearchTicketScan':
        return <FlightSearchTicketScan />
      case 'flightSearchResults':
        if (this.props.flightStatusInfo.flightStatus) {
          return <FlightSearchResults flightStatus={this.props.flightStatusInfo.flightStatus} />
        } else {
          return <div />
        }
      default:
        return <div />
    }
  }

  render() {
    return (
      <div style={{
        margin: Padding.XLarge,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}>
        {this.getFlightSearchComponent(this.props.flightSearchState)}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    config: state.configState.conf,
    flightStatusInfo: state.FlightStatus,
    flightSearchState: state.FlightStatus.flightSearchState,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(InternalFlightStatusScreen)
