import React from 'react'
import A1 from './A1'
import PropTypes from 'prop-types'

const TopContainer = ({ jabbrrbox, styles }) => {
  return (
    <div>
      <A1 jabbrrbox={jabbrrbox} styles={styles}/>
    </div>)
}

TopContainer.propTypes = {
  jabbrrbox: PropTypes.object,
  styles: PropTypes.object,
}

export default TopContainer
