import fetchJsonp from 'fetch-jsonp'

export const GET_ZONE_FORECAST_STARTED = 'GET_ZONE_FORECAST_STARTED'
export const GET_ZONE_FORECAST_STOPPED = 'GET_ZONE_FORECAST_STOPPED'
export const GET_ZONE_FORECAST_ERROR = 'GET_ZONE_FORECAST_ERROR'
export const CLEAR_ZONE_FORECAST = 'CLEAR_ZONE_FORECAST'

export const GET_ALL_WEATHER_STARTED = 'GET_ALL_WEATHER_STARTED'
export const GET_ALL_WEATHER_STOPPED = 'GET_ALL_WEATHER_STOPPED'
export const GET_ALL_WEATHER_ERROR = 'GET_ALL_WEATHER_ERROR'
export const CLEAR_ALL_WEATHER_FORECAST = 'CLEAR_ALL_WEATHER_FORECAST'

const get = url => {
  return fetchJsonp(url)
    .then(response => {
      return response.json()
    }).catch(error => {
      throw Error(error)
    })
}

const getZoneForecastRequestStarted = () => ({ type: GET_ZONE_FORECAST_STARTED })
const getZoneForecastRequestError = err => ({ type: GET_ZONE_FORECAST_ERROR, payload: err })
const getZoneForecastRequestStopped = (payload = {}) => ({ type: GET_ZONE_FORECAST_STOPPED, payload })

const getAllWeatherRequestStarted = () => ({ type: GET_ALL_WEATHER_STARTED })
const getAllWeatherRequestError = err => ({ type: GET_ALL_WEATHER_ERROR, payload: err })
const getAllWeatherRequestStopped = (payload = {}) => ({ type: GET_ALL_WEATHER_STOPPED, payload })

export const getZoneForecastByAirportCode = airportCode => {
  return (dispatch, getState) => {
    dispatch(getZoneForecastRequestStarted())
    const flightstats = getState().configState.conf.flightStats
    const url = `${flightstats.weather.zoneForecast.restServiceBaseURL}/${airportCode}?appId=${flightstats.appId}&appKey=${flightstats.appKey}`
    return get(url, getState())
      .then(data => {
        dispatch(getZoneForecastRequestStopped(data))
      })
      .catch(error => {
        dispatch(getZoneForecastRequestError(error))
      })
  }
}

export const getAllWeatherByAirportCode = airportCode => {
  return (dispatch, getState) => {
    dispatch(getAllWeatherRequestStarted())
    const flightstats = getState().configState.conf.flightStats
    const url = `${flightstats.weather.all.restServiceBaseURL}/${airportCode}?appId=${flightstats.appId}&appKey=${flightstats.appKey}`
    return get(url, getState())
      .then(data => {
        dispatch(getAllWeatherRequestStopped(data))
      })
      .catch(error => {
        dispatch(getAllWeatherRequestError(error))
      })
  }
}

export const clearWeather = (payload = {}) => ({ type: CLEAR_ZONE_FORECAST, payload })
