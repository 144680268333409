import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../app/actions'
import PropTypes from 'prop-types'
import FlatButton from 'material-ui/FlatButton'
import LogoWithNavigation from './signupScreen/LogoWithNavigation'
import { ContainerStyle } from '../styles/style.format'

const declinedIconUrl = `${process.env.PUBLIC_URL}/img/icons/payment/declined.svg`

let navTimer
class PayDeniedScreen extends Component {

  constructor(props) {
    super(props)

    this.state = {
      failureMessage: '',
    }

    this.handlePaymentTryAgain = this.handlePaymentTryAgain.bind(this)
    this.handleCancelPayment = this.handleCancelPayment.bind(this)
    this.setFailureMessage = this.setFailureMessage.bind(this)
  }

  componentWillMount() {
    this.setFailureMessage()
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    clearTimeout(navTimer)
  }

  startNavigationTimer() {
    this.stopNavigationTimer()
    navTimer = setTimeout(() => {
      this.props.logEvent('Payment Workflow', 'Timeout', { evLabel: 'PayDeniedScreen', evValue: this.props.config.signUpPageTimeout || 30000 })
      this.handleCancelPayment()
    }, parseFloat(this.props.config.signUpPageTimeout || 30000))
  }

  stopNavigationTimer() {
    clearTimeout(navTimer)
  }

  handlePaymentTryAgain() {
    if (this.props.navigation.options.pricingPlan) {
      this.props.logEvent('Payment Workflow', 'Payment Failed Try Again')
      this.props.navigate('PayNow', this.props.navigation.options)
      this.props.clearPaymentTransactionInfo()
      this.props.setupPayment(
        this.props.config.jabbrrbox.id,
        this.props.person.id,
        this.props.navigation.options.pricingPlan,
        this.props.reservation.id
      )
    }
  }

  handleCancelPayment() {
    if (this.props.reservation) {
      this.props.cancelReservation(this.props.reservation)
    }
    this.props.navigate('Welcome')
  }

  setFailureMessage() {

    // These are messages from the POS device and should NOT be changed.
    const AMOUNT_NOT_ACKNOWLEDGED = 'Amount Not Acknowledged'
    const TIMEOUT_WAITING_FOR_PINPAD = 'Timeout Waiting for PinPad'
    const CARD_REMOVED = 'Card Removed'
    const CARD_READ_FAILURE = 'Card Read Failure'

    // These are the messages that will be displayed to the end-user.
    const FAILED_AMOUNT_NOT_ACCEPTED_MESSAGE = 'Payment not processed. User cancelled transaction.'
    const FAILED_PINPAD_TIMEOUT_MESSAGE = 'Payment not processed. Transaction timed out.'
    const FAILED_CARD_REMOVED_MESSAGE = 'Payment not processed. Please do not remove chip card.'
    const FAILED_CARD_READ_MESSAGE = 'Payment not processed. Please swipe again.'
    const FAILED_GENERIC_MESSAGE = 'Payment Failed.'

    const responseText = this.props.payment.paymentData.RStream.CmdResponse[0].TextResponse[0]
    if (responseText.includes(AMOUNT_NOT_ACKNOWLEDGED)) {
      this.setState({ failureMessage: FAILED_AMOUNT_NOT_ACCEPTED_MESSAGE })
    } else if (responseText.includes(TIMEOUT_WAITING_FOR_PINPAD)) {
      this.setState({ failureMessage: FAILED_PINPAD_TIMEOUT_MESSAGE })
    } else if (responseText.includes(CARD_REMOVED)) {
      this.setState({ failureMessage: FAILED_CARD_REMOVED_MESSAGE })
    } else if (responseText.includes(CARD_READ_FAILURE)) {
      this.setState({ failureMessage: FAILED_CARD_READ_MESSAGE })
    } else {
      this.setState({ failureMessage: FAILED_GENERIC_MESSAGE })
    }
  }

  render() {
    return (
      <div style={{ ...ContainerStyle, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <LogoWithNavigation styles={this.props.styles} hideBackButton={true}
          leftFunction={this.handlePaymentTryAgain}
          closeFunction={() => this.handleCancelPayment()}
        />
        <div style={this.props.styles.PayNowScreenTitle}>
          Failed
        </div>
        <div>
          <img alt="Payment Denied" src={declinedIconUrl} style={{ width: 500, height: 'auto' }} />
        </div>
        <div style={this.props.styles.PayDirections}>{this.state.failureMessage}</div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
          <FlatButton
            label="TRY AGAIN"
            style={this.props.styles.PurchaseButton}
            onClick={this.handlePaymentTryAgain}
          />
        </div>
      </div>
    )
  }
}

PayDeniedScreen.propTypes = {
  styles: PropTypes.object,
  navigate: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    navigation: state.Navigation,
    payment: state.payment,
    person: state.SignUp.registeredPerson,
    config: state.configState.conf,
    reservation: state.doorState.jabbrrboxReservation,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PayDeniedScreen)
