import * as SignUp from './sign.up'
import * as GoogleAnalytics from './google.analytics'

export const NAVIGATE = 'NAVIGATE'

const navigateAction = (route, options) => {
  return ({ type: NAVIGATE, payload: { route, options }})
}

export const navigate = (route, options) => {
  return dispatch => {
    dispatch(navigateAction(route, options))
    dispatch(SignUp.hideKeyboard(null))
    dispatch(GoogleAnalytics.logPageView(`/${route}`))
  }
}
