import { ActionCreators } from '../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import LogoWithNavigation from './signupScreen/LogoWithNavigation'
import Divider from 'material-ui/Divider'
import PartnersList from './partners/PartnersList'
import { Padding, ContainerStyle } from '../styles/style.format'

let navTimer

class PartnerOptionScreen extends Component {
  constructor(props) {
    super(props)

    this.handleSelectedPartnerOption = this.handleSelectedPartnerOption.bind(this)
  }

  componentWillMount() {
    this.startNavigationTimer()
  }

  componentWillUnmount() {
    clearTimeout(navTimer)
  }

  startNavigationTimer() {
    this.stopNavigationTimer()
    navTimer = setTimeout(() => {
      this.props.navigate('Welcome')
    }, parseFloat(this.props.config.pricingOptionTimeout || 30000))
  }

  stopNavigationTimer() {
    clearTimeout(navTimer)
  }

  handleSelectedPartnerOption(partner) {
    this.props.setSelectedPartner(partner)
    this.props.navigate('PartnerCode')
  }

  render() {
    return (
      <div style={ContainerStyle}>
        <div>
          <LogoWithNavigation styles={this.props.styles} hideBackButton={true} leftFunction={() => {
            this.props.navigate('TellMeMore')
          }}
          closeFunction={() => {
            this.props.navigate('Welcome')
          }} />
        </div>

        <div style={{ ...this.props.styles.PricingOptionTitle, marginBottom: Padding.Medium }}>
          Select a partner to continue
        </div>
        <Divider />
        <div style={this.props.styles.PricingOptionsList}>
          <PartnersList
            styles={this.props.styles}
            partners={this.props.jabbrrbox.partners}
            onSelectedPartnerOption={this.handleSelectedPartnerOption} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    config: state.configState.conf,
    jabbrrbox: state.doorState.jabbrrbox,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerOptionScreen)
