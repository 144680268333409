import { ActionCreators } from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Margin, Padding } from '../../styles/style.format'
import FlatButton from 'material-ui/FlatButton'
import Slider from 'react-slick'
import _ from 'lodash'
import { FLIGHT_SEARCH_OPTIONS } from '../../actions/flight.status'

const departArriveUrl = `${process.env.PUBLIC_URL}/img/DepartArrive.svg`
const onTimeUrl = `${process.env.PUBLIC_URL}/img/icons/flightStatus/OnTime.svg`
const lateUrl = `${process.env.PUBLIC_URL}/img/icons/flightStatus/Late.svg`
const weatherIcons = `${process.env.PUBLIC_URL}/img/icons/weather/`


class FlightSearchResults extends Component {


  getFlightStatusIndicator(flight) {
    switch (flight.status) {
      case 'S':
        return (
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <img src={onTimeUrl} role="presentation" />
            <div style={this.props.styles.FlightStatusOnTimeText}>
              {flight.statusName}
            </div>
          </div>
        )
      case 'C':
        return (
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <img src={lateUrl} role="presentation" />
            <div style={this.props.styles.FlightStatusLateText}>
              {flight.statusName}
            </div>
          </div>
        )
      default:
        return (
          <div style={{ flex: 1 }} />
        )
    }
  }

  handleImageError(event) {
    event.target.style = 'display: none;'
  }

  getTempurature(tempuratureFahrenheit) {
    if (tempuratureFahrenheit) {
      return tempuratureFahrenheit
    } else {
      return '~'
    }
  }

  getFlightStatus(flight) {
    let arrival, departure

    if (!this.props.flightStatus.error) {

      const arrivalAirportCode = flight.arrivalAirportCode
      const departureAirportCode = flight.departureAirportCode
      const arrivalZoneForecastTagValue = _.get(this.props.zoneForecast[arrivalAirportCode], 'dayForecasts[0].tags[0].value', '')
      const departureZoneForcastTagValue = _.get(this.props.zoneForecast[departureAirportCode], 'dayForecasts[0].tags[0].value', '')
      const arrivalMetarTempuratureFahrenheit = _.get(this.props.metar[arrivalAirportCode], 'tempuratureFahrenheit', '')
      const departureMetarTempuratureFahrenheit = _.get(this.props.metar[departureAirportCode], 'tempuratureFahrenheit', '')
      const arrivalCityState = flight.arrivalCity ? `${flight.arrivalCity}, ${flight.arrivalStateCode}` : ''
      const departureCityState = flight.departureCity ? `${flight.departureCity}, ${flight.departureStateCode}` : ''

      arrival = {
        title: 'Arrival',
        city: flight.arrivalCity,
        stateCode: flight.arrivalStateCode,
        airportCode: flight.arrivalAirportCode,
        dateScheduled: flight.arrivalDateScheduled,
        dateEstimated: flight.arrivalDateEstimated,
        date: flight.arrivalDate,
        gate: flight.arrivalGate,
        status: flight.status,
        cityState: arrivalCityState,
        weather: {
          tag: arrivalZoneForecastTagValue,
          tempuratureFahrenheit: arrivalMetarTempuratureFahrenheit,
          icon: `${weatherIcons}${arrivalZoneForecastTagValue.replace(/\s/g, '').toLowerCase()}.svg`,
        },
      }
      departure = {
        title: 'Departure',
        city: flight.departureCity,
        stateCode: flight.departureStateCode,
        airportCode: flight.departureAirportCode,
        dateScheduled: flight.departureDateScheduled,
        dateEstimated: flight.departureDateEstimated,
        date: flight.departureDate,
        gate: flight.departureGate,
        status: flight.status,
        cityState: departureCityState,
        weather: {
          tag: departureZoneForcastTagValue,
          tempuratureFahrenheit: departureMetarTempuratureFahrenheit,
          icon: `${weatherIcons}${departureZoneForcastTagValue.replace(/\s/g, '').toLowerCase()}.svg`,
        },
      }
    }
    return (
      <div key={flight.flightId} style={{ display: 'flex', flexDirection: 'column' }}>
        <img src={departArriveUrl} role="presentation" />
        <div style={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'center', alignItems: 'center' }} >
          <div style={{ flex: 1 }}>
            <div style={this.props.styles.FlightStatusAirportCodeText}>{departure.airportCode}</div>
            <div style={{ ...this.props.styles.FlightStatusInfoText, marginTop: -Margin.Medium }}>{departure.cityState}</div>
          </div>
          {this.getFlightStatusIndicator(flight)}
          <div style={{ flex: 1 }}>
            <div style={this.props.styles.FlightStatusAirportCodeText}>{arrival.airportCode}</div>
            <div style={{ ...this.props.styles.FlightStatusInfoText, marginTop: -Margin.Medium }}>{arrival.cityState}</div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'center', alignItems: 'center', paddingTop: Padding.Small }} >
          <div style={{ ...this.props.styles.FlightStatusGateCodeText, flex: 1 }}>
            {departure.gate || `~`}
          </div>
          <div style={{ ...this.props.styles.FlightStatusLabelText, flex: 1 }}>
            Gate
          </div>
          <div style={{ ...this.props.styles.FlightStatusGateCodeText, flex: 1 }}>
            {arrival.gate || `~`}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'center', alignItems: 'center', paddingTop: Padding.Small }} >
          <div style={{ ...this.props.styles.FlightStatusInfoText, flex: 1 }}>
            {departure.dateScheduled}
          </div>
          <div style={{ ...this.props.styles.FlightStatusLabelText, flex: 1 }}>
            Scheduled
          </div>
          <div style={{ ...this.props.styles.FlightStatusInfoText, flex: 1 }}>
            {arrival.dateScheduled}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'center', alignItems: 'center', paddingTop: Padding.Small }} >
          <div style={{ ...this.props.styles.FlightStatusInfoText, flex: 1 }}>
            {departure.dateEstimated}
          </div>
          <div style={{ ...this.props.styles.FlightStatusLabelText, flex: 1 }}>
            Estimated
          </div>
          <div style={{ ...this.props.styles.FlightStatusInfoText, flex: 1 }}>
            {arrival.dateEstimated}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'center', alignItems: 'center', paddingTop: Padding.Small }} >
          <div style={{ ...this.props.styles.FlightStatusInfoText, flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ height: '60px', marginRight: Margin.XXSmall }} src={departure.weather.icon} onError={e => {
              this.handleImageError(e)
            }} />
            <div>{departure.weather.tempuratureFahrenheit && this.getTempurature(departure.weather.tempuratureFahrenheit) || '~'}{departure.weather.tempuratureFahrenheit && <span>&deg;</span>}</div>
          </div>
          <div style={{ ...this.props.styles.FlightStatusLabelText, flex: 1 }}>
            Weather
          </div>
          <div style={{ ...this.props.styles.FlightStatusInfoText, flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ height: '60px', marginRight: Margin.XXSmall }} src={arrival.weather.icon} onError={e => {
              this.handleImageError(e)
            }} />
            <div>{arrival.weather.tempuratureFahrenheit && this.getTempurature(arrival.weather.tempuratureFahrenheit) || '~'}{arrival.weather.tempuratureFahrenheit && <span>&deg;</span>}</div>
          </div>
        </div>
      </div>
    )
  }


  render() {
    function PrevButton({ onClick, style, className }) {
      return <button onClick={onClick} className={className} style={{ ...style, width: '100px', height: '100px' }}>Prev</button>
    }

    function NextButton({ onClick, style, className }) {
      return <button onClick={onClick} className={className} style={{ ...style, width: '100px', height: '100px' }}>Next</button>
    }
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextButton />,
      prevArrow: <PrevButton />,
    }

    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }} >
        {!this.props.flightStatus.error &&
          <div style={{ width: '1230px' }}>
            <Slider {...settings}>
              {this.props.flightStatus.map((flight, index) => {
                return this.getFlightStatus(flight, index)
              })}
            </Slider>
          </div>
        }
        {this.props.flightStatus.error && <div style={{ ...this.props.styles.FindOutMoreText, display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>Flight Not Found</div>}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <FlatButton
            style={{ ...this.props.styles.PrimaryButton, paddingLeft: Padding.Small, paddingRight: Padding.Small, flexGrow: 0 }}
            label={'Check Another Flight'}
            onClick={() => {
              this.props.updateFlightSearchState(FLIGHT_SEARCH_OPTIONS.FLIGHT_SEARCH)
            }}
          />
          <div>
            <p style={{ color: 'white' }}>Please be sure to check with your airline for most current information.</p>
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => {
  return {
    styles: state.Style.theme,
    zoneForecast: state.Weather.zoneForecast,
    metar: state.Weather.metar,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(FlightSearchResults)
