import * as jabbrrboxPricingPlanActions from '../actions/jabbrrbox.pricing.plan'

const INITIAL_STATE = {
  isLoading: false,
  plans: [],
  error: undefined,
}

export const jabbrrboxPricingPlan = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case jabbrrboxPricingPlanActions.LOAD_JABBRRBOX_PRICING_PLANS_STARTED:
      return { ...state, isLoading: true }
    case jabbrrboxPricingPlanActions.LOAD_JABBRRBOX_PRICING_PLANS_STOPPED:
      return { ...state, isLoading: false, plans: payload }
    case jabbrrboxPricingPlanActions.LOAD_JABBRRBOC_PRICING_PLANS_ERROR:
      return { ...state, isLoading: false, error: payload }
    default:
      return state
  }
}
