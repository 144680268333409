import Analytics from 'electron-google-analytics'

const productionId = 'UA-108380924-1'
const devId = 'UA-108380924-2'
const analytics = process.env.NODE_ENV === 'production' ? new Analytics(productionId) : new Analytics(devId)
const JABBRRBOX_URL = 'http://jabbrrbox.us'

export const SET_CLIENT_ID = 'SET_CLIENT'
export const setClientId = clientId => ({ type: SET_CLIENT_ID, payload: clientId })

export const CLEAR_CLIENT_ID = 'CLEAR_CLIENT_ID'
export const clearClientId = () => ({ type: CLEAR_CLIENT_ID })

export const LOG_PAGE_VIEW_STARTED = 'LOG_PAGE_VIEW_STARTED'
export const LOG_PAGE_VIEW_COMPLETED = 'LOG_PAGE_VIEW_COMPLETED'
export const LOG_PAGE_VIEW_ERROR = 'LOG_PAGE_VIEW_ERROR'
export const logPageViewStarted = () => ({ type: LOG_PAGE_VIEW_STARTED })
export const logPageViewCompleted = response => ({ type: LOG_PAGE_VIEW_COMPLETED, payload: response })
export const logPageViewError = error => ({ type: LOG_PAGE_VIEW_ERROR, payload: error })
export const logPageView = route => {
  return (dispatch, getState) => {
    dispatch(logPageViewStarted)
    const state = getState()
    const locationId = state.doorState && state.doorState.jabbrrbox.location.id
    const jabbrrboxId = state.doorState && state.doorState.jabbrrbox.id
    const title = `/Location/${locationId}/Jabbrrbox/${jabbrrboxId}${route}`

    if (state.googleAnalytics) {
      if (state.googleAnalytics.clientId) {
        return analytics.pageview(JABBRRBOX_URL, route, title, state.googleAnalytics.clientId)
          .then(response => {
            dispatch(logPageViewCompleted(response))
          })
          .catch(error => {
            dispatch(clearClientId())
            dispatch(logPageViewError(error))
          })
      } else {
        return analytics.pageview(JABBRRBOX_URL, route, title)
          .then(response => {
            dispatch(setClientId(response.clientID))
            dispatch(logPageViewCompleted(response))
          })
          .catch(error => {
            dispatch(clearClientId())
            dispatch(logPageViewError(error))
          })
      }
    } else {
      return null
    }
  }
}

export const LOG_EVENT_STARTED = 'LOG_EVENT_STARTED'
export const LOG_EVENT_COMPLETED = 'LOG_EVENT_COMPLETED'
export const LOG_EVENT_ERROR = 'LOG_EVENT_ERROR'
export const logEventStarted = () => ({ type: LOG_EVENT_STARTED })
export const logEventCompleted = response => ({ type: LOG_EVENT_COMPLETED, payload: response })
export const logEventError = error => ({ type: LOG_EVENT_ERROR, payload: error })
export const logEvent = (eventCategory, eventAction, label) => {
  return (dispatch, getState) => {
    dispatch(logEventStarted())
    const state = getState()
    
    if (state.googleAnalytics) {
      if (state.googleAnalytics.clientId) {
        const eventLabelValue = {
          evLabel: (label && label.evLabel) || null,
          evValue: (label && label.evValue) || null,
          clientID: state.googleAnalytics.clientId,
        }
        return analytics
          .event(eventCategory, eventAction, eventLabelValue)
          .then(response => {
            dispatch(logEventCompleted(response))
          })
          .catch(error => {
            dispatch(logEventError(error))
          })
      } else {
        return null
      }
    } else {
      return null
    }
  }
}

export const LOG_PURCHASE_STARTED = 'LOG_PURCHASE_STARTED'
export const LOG_PURCHASE_COMPLETED = 'LOG_PURCHASE_COMPLETED'
export const LOG_PURCHASE_ERROR = 'LOG_PURCHASE_ERROR'
export const logPurchaseStarted = () => ({ type: LOG_PURCHASE_STARTED })
export const logPurchaseCompleted = response => ({ type: LOG_PURCHASE_COMPLETED, payload: response })
export const logPurchaseError = error => ({ type: LOG_PURCHASE_ERROR, payload: error })
export const logPurchase = (transactionID, trnRev, trnTax, trnShip) => {
  return (dispatch, getState) => {
    dispatch(logPurchaseStarted)
    const state = getState()
    const url = `/${state.Navigation.route}`
    const title = `${state.Navigation.route} Page`

    const selectedPlan = state.payment.selectedPriceOption
    const prdID = selectedPlan.pricingPlanId
    const prdName = selectedPlan.pricingPlan.name
    const prdCtg = state.doorState.jabbrrbox.name
    const prdBrand = state.doorState.jabbrrbox.location.name
    const trnAffil = state.doorState.jabbrrbox.location.name
    const purchaseDetails = Object.assign({}, { trnAffil, trnRev, trnTax, trnShip, prdID, prdName, prdCtg, prdBrand })

    if (state.googleAnalytics) {
      if (state.googleAnalytics.clientId) {
        return analytics.purchase(JABBRRBOX_URL, url, title, transactionID, purchaseDetails, state.googleAnalytics.clientId)
          .then(response => {
            dispatch(logPurchaseCompleted(response))
          }).catch(error => {
            dispatch(logPurchaseError(error))
          })
      } else {
        return null
      }
    } else {
      return null
    }
  }
}

export const LOG_EXCEPTION_STARTED = 'LOG_EXCEPTION_STARTED'
export const LOG_EXCEPTION_COMPLETED = 'LOG_EXCEPTION_COMPLETED'
export const LOG_EXCEPTION_ERROR = 'LOG_EXCEPTION_ERROR'
export const logExceptionStarted = () => ({ type: LOG_EXCEPTION_STARTED })
export const logExceptionCompleted = response => ({ type: LOG_EXCEPTION_COMPLETED, payload: response })
export const logExceptionError = error => ({ type: LOG_EXCEPTION_ERROR, payload: error })
export const logException = exception => {
  return (dispatch, getState) => {
    dispatch(logExceptionStarted)
    const state = getState()
    if (state.googleAnalytics) {
      return analytics.exception(exception)
        .then(response => {
          dispatch(logExceptionCompleted(response))
        })
        .catch(error => {
          dispatch(logExceptionError(error))
        })
    } else {
      return null
    }
  }
}
