import gifshot from 'gifshot'

const gifOptionDefaults = {
  numFrames: 1,
  gifWidth: 692.5,
  gifHeight: 472.25,
  interval: 1,
  frameDuration: 10,
  sampleInterval: 1,
  numWorkers: 5,
  fontWeight: 'normal',
  fontSize: '30px',
  fontFamily: 'Poppins-Light',
  fontColor: '#000000',
  textAlign: 'center',
  textBaseline: 'bottom',
}

export const createGIF = (gifPhotos, gifOptions) => {
  return new Promise((resolve, reject) => {
    gifshot.createGIF({ ...gifOptionDefaults, ...gifOptions, images: gifPhotos }, gifObj => {
      if (gifObj.error) {
        reject(gifObj.error)
      } else {
        resolve(gifObj.image)
      }
    })
  })
}
