import { sendFiles } from '../util/services'
import { createGIF } from './gif'

export const START_SENDING_PHOTOS = 'START_SENDING_PHOTOS'
export const SENDING_PHOTOS_ERROR = 'SENDING_PHOTOS_ERROR'
export const STOP_SENDING_PHOTOS = 'STOP_SENDING_PHOTOS'
export const startSendingPhotos = () => ({ type: START_SENDING_PHOTOS })
export const stopSendingPhotos = () => ({ type: STOP_SENDING_PHOTOS })
const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}
export const sendPhotos = (reservation, photos) => {
  return (dispatch, getState) => {
    if (!reservation) {
      dispatch({ type: SENDING_PHOTOS_ERROR, payload: 'Reservation does not exist' })
      return
    }
    const id = reservation.id

    dispatch(startSendingPhotos())
    const formData = new FormData()
    photos.map(photo => {
      const strPhoto = dataURItoBlob(photo)
      formData.append('file', strPhoto)
      return strPhoto
    })
    sendFiles(`/photos/upload?jabbrrboxReservationId=${id}`, getState(), formData).then(data => {
      dispatch(stopSendingPhotos(data))
    }).catch(err => {
      dispatch(stopSendingPhotos(err))
    })
  }
}

export const sendPhotoStripAndGif = (reservation, photoStrip, gifPhotos, gifTag) => {
  return (dispatch, getState) => {
    if (!reservation) {
      dispatch({ type: SENDING_PHOTOS_ERROR, payload: 'Reservation does not exist' })
      return
    }
    const id = reservation.id

    createGIF(gifPhotos, { text: gifTag }).then(gif => {
      dispatch(startSendingPhotos())
      const formData = new FormData()
      formData.append('file', dataURItoBlob(photoStrip))
      formData.append('file', dataURItoBlob(gif))
      sendFiles(`/photos/upload?jabbrrboxReservationId=${id}`, getState(), formData).then(data => {
        dispatch(stopSendingPhotos(data))
      }).catch(err => {
        dispatch(stopSendingPhotos(err))
      })
    })

  }
}
