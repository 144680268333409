import * as menu from '../actions/menu'

const generateIcons = iconName => {
  return {
    icon: `${process.env.PUBLIC_URL}/img/icons/neutral/${iconName}`,
    iconHighlighted: `${process.env.PUBLIC_URL}/img/icons/active/${iconName}`,
  }
}

const menuItems = [
  {
    name: 'Home',
    header: 'Welcome',
    ...generateIcons('Home.svg'),
  },
  {
    name: 'Lighting',
    subheader: 'Adjust your Jabbrrbox lighting with the color wheel or select a preset.',
    ...generateIcons('Lighting.svg'),
  },
  {
    name: 'Location',
    ...generateIcons('Location.svg'),
  },
  {
    name: 'Flight Status',
    subheader: 'Enter your flight info below to keep tabs on your flight!',
    ...generateIcons('FlightStatus.svg'),
  },
  {
    name: 'Support',
    ...generateIcons('Support.svg'),
  },
  {
    name: 'WiFi',
    subheader: 'Connecting is easy and rewarding.',
    ...generateIcons('Wifi.svg'),
  },
  {
    name: 'Photobooth',
    subheader: 'Take 5 photos in true photobooth style.',
    ...generateIcons('Photobooth.svg'),
  },
  {
    name: 'Survey',
    ...generateIcons('Survey.svg'),
  },
]

const INITIAL_STATE = {
  selectedMenuItem: null,
  menuItems,
}

export const Menu = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case menu.SET_SELECTED_MENU_ITEM :
      return { ...state, selectedMenuItem: menuItems.find(item => {
        return item.name === payload
      }) || menuItems[0] }
    default:
      return state
  }
}
