import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../app/actions'
import LogoDisplay from './shared/LogoDisplay'
import Divider from 'material-ui/Divider'
import { Padding, ContainerStyle } from '../styles/style.format'
import CallToActionComponent from './tellMeMoreScreen/CallToActionComponent'
import ValuePropositionComponent from './tellMeMoreScreen/ValuePropositionComponent'
import WhenToUseJabbrrBoxComponent from './tellMeMoreScreen/WhenToUseJabbrrBoxComponent'
import WhatIsInJabbrrBoxComponent from './tellMeMoreScreen/WhatIsInJabbrrBoxComponent'
import SupportFooterComponent from './tellMeMoreScreen/SupportFooterComponent'

let navTimer
export class TellMeMoreComponent extends Component {

  constructor(props) {
    super(props)

    this.getSpacerSize = this.getSpacerSize.bind(this)
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    this.props.loadMarketingCards()
    this.props.loadJabbrrboxPricingPlans()
    this.props.loadLocationPricingPlans()
    this.startNavigationTimer()
  }

  startNavigationTimer() {
    this.stopNavigationTimer()
    navTimer = setTimeout(() => {
      this.props.logEvent('Payment Workflow', 'Timeout', { evLabel: 'TellMeMoreScreen', evValue: this.props.config.tellMeMoreTimeout || 30000 })
      if (this.props.reservation) {
        this.props.navigate('RoomReserved')
      } else {
        this.props.navigate('Welcome')
      }
    }, parseFloat(this.props.config.tellMeMoreTimeout) || 300000)
  }

  stopNavigationTimer() {
    clearTimeout(navTimer)
  }

  getSpacerSize() {

    const NOT_RESERVED = 380
    const RESERVED_WITH_SPONSOR = 205
    const RESERVED_WITHOUT_SPONSOR = 100

    let size = 0
    if (!this.props.reservation) {
      size = NOT_RESERVED
    } else if (this.props.reservation && this.props.sponsor) {
      size = RESERVED_WITH_SPONSOR
    } else {
      size = RESERVED_WITHOUT_SPONSOR
    }

    return size
  }

  render() {
    const marginTopSpacer = this.getSpacerSize()
    return (
      <div>
        <CallToActionComponent navigate={this.props.navigate} styles={this.props.styles}
          reservation={this.props.reservation} sponsor={this.props.sponsor}
          locationPricingPlan={this.props.locationPricingPlan} jabbrrboxPricingPlan={this.props.jabbrrboxPricingPlan} />
        <div style={{ ...this.props.styles.TellMeMoreContainer, ...ContainerStyle,
          marginTop: marginTopSpacer, marginLeft: Padding.XLarge, marginRight: Padding.XLarge }}>
          <LogoDisplay viewOptions={{ width: 200, height: 'auto' }}
            options={{ width: 200, height: 'auto' }}
          />
          <Divider style={{ backgroundColor: this.props.styles.Color.NeutralLight, width: '100%', marginTop: Padding.Small,
            marginBottom: Padding.Small }} />
          <ValuePropositionComponent styles={this.props.styles} />
          <Divider style={{ backgroundColor: this.props.styles.Color.NeutralLight, width: '100%', marginTop: Padding.Small,
            marginBottom: Padding.Small }} />
          <WhenToUseJabbrrBoxComponent styles={this.props.styles} marketingCard={this.props.marketingCard} />
          <WhatIsInJabbrrBoxComponent styles={this.props.styles} />
        </div>
        <SupportFooterComponent styles={this.props.styles} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    marketingCard: state.marketingCard,
    reservation: state.doorState.jabbrrboxReservation,
    config: state.configState.conf,
    styles: state.Style.theme,
    sponsor: state.doorState.jabbrrbox ? state.doorState.jabbrrbox.sponsor : undefined,
    locationPricingPlan: state.locationPricingPlan,
    jabbrrboxPricingPlan: state.jabbrrboxPricingPlan,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TellMeMoreComponent)
